import axios from "@axios";

export default {
    fetchMarketExposure({ commit }) {
        // let url = `${process.env.VUE_APP_API_URL}metric/MarketExposure/data`
        // return new Promise((resolve, reject) => {
        //     return axios.get(url).then(response => {
        //       commit("SET_VALUE", {key: "market_exposure", value: response.data})
        //       commit("SET_VALUE", {key: "is_market_exposure_fetched", value: true})
        //       resolve(response)
        //     }).catch(error => {
        //       reject(error)
        //     })
        //   }
        // )

        //? mockData
        axios
            .get("/MarketExposure/data")
            .then((response) => {
                commit("SET_VALUE", { key: "market_exposure", value: response.data });
                commit("SET_VALUE", { key: "is_market_exposure_fetched", value: true });
                // resolve(response);
            })
            .catch((error) => {
                console.log(error);
            });
    },

    fetchCurrentUserStatistics({ commit, reject }) {
        // let url = `${process.env.VUE_APP_API_URL}metric/CurrentUserStatistics/data`;
        //? mockData
        let url = "/CurrentUserStatistics/data";

        return new Promise((resolve, reject) => {
            return axios
                .get(url)
                .then((response) => {
                    commit("SET_VALUE", { key: "current_user_statistics", value: response.data });
                    commit("SET_VALUE", { key: "is_current_user_statistics_fetched", value: true });
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });

        // axios
        //     .get("/CurrentUserStatistics/data")
        //     .then((response) => {
        //         commit("SET_VALUE", { key: "current_user_statistics", value: response.data });
        //         commit("SET_VALUE", { key: "is_current_user_statistics_fetched", value: true });
        //         resolve(response);
        //     })
        //     .catch((error) => {
        //         reject(error);
        //     });
    },

    fetchAccountAnalysis({ commit, reject }) {
        // let url = `${process.env.VUE_APP_API_URL}metric/AccountAnalysis/data`;
        //? mockData
        let url = "/AccountAnalysis/data";

        return new Promise((resolve, reject) => {
            return axios
                .get(url)
                .then((response) => {
                    commit("SET_VALUE", { key: "account_analysis", value: response.data });
                    commit("SET_VALUE", { key: "is_account_analysis_fetched", value: true });
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    fetchRiskThreshold({ commit }) {
        return new Promise((resolve, reject) => {
            return (
                axios
                    // .get(`${process.env.VUE_APP_API_URL}site_settings/risk_management/risk_threshold`)
                    // mockData
                    .get("/RiskThreshold/data")
                    .then((response) => {
                        commit("SET_VALUE", { key: "risk_threshold", value: response.data.settings });
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            );
        });
    },

    fetchGroupLevels({ commit }) {
        return new Promise((resolve, reject) => {
            return (
                axios
                    // .get(`${process.env.VUE_APP_API_URL}site_settings/risk_management/group_levels`)
                    // mockData
                    .get("/GroupLevel/data")
                    .then((response) => {
                        commit("SET_VALUE", { key: "group_levels", value: response.data.settings });
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            );
        });
    },

    updateMetricDatum({ commit, state }, datum) {
        let key = "";
        switch (datum.metric_definition_name) {
            case "MarketExposure":
            case "MarketExposure_Region":
                key = "market_exposure";
                break;
            case "CurrentUserStatistics":
                key = "current_user_statistics";
                break;
            case "AccountAnalysis":
                key = "account_analysis";
                break;
            default:
                return;
        }
        if (state[key].find((item) => item.id == datum.id)) {
            commit("SET_DATUM_VALUE", { metric: key, datum, value: datum.value });
        } else {
            commit("PUSH_DATUM", { metric: key, datum });
        }
    },

    removeMetricDatum({ commit, state }, datum) {
        let key = "";
        switch (datum.metric_definition_name) {
            case "MarketExposure":
            case "MarketExposure_Region":
                key = "market_exposure";
                break;
            case "CurrentUserStatistics":
                key = "current_user_statistics";
                break;
            case "AccountAnalysis":
                key = "account_analysis";
                break;
            default:
                return;
        }
        commit("REMOVE_DATUM", { metric: key, datum });
    },
};
