export default [
    // {
    //   path: '/var-analysis',
    //   name: 'var-analysis',
    //   component: () => import('@/views/var-analysis/VarAnalysis.vue'),
    // },

    // bbook
   
  
    {
        path: '/b-book/market-exposure',
        name: 'market-exposure',
        component: () => import('@/views/b-book/Index.vue'),
    },
    {
        path: '/b-book/user-stats',
        name: 'user-stats',
        component: () => import('@/views/b-book/Index.vue'),
    },
    {
        path: '/b-book/company-p-l',
        name: 'company-p-l',
        component: () => import('@/views/b-book/Index.vue'),
    },
    {
        path: '/b-book/deep-dive',
        name: 'deep-dive',
        component: () => import('@/views/b-book/Index.vue'),
    },
    
    // Stress Test
    {
        path: '/stress-test',
        name: 'stress-test',
        component: () => import('@/views/stress-test/StressTest.vue'),
    },

    // Credit Risk
    {
        path: '/credit-risk',
        name: 'credit-risk',
        component: () => import('@/views/credit-risk/CreditRisk.vue'),
    },

    // Reports
    {
        path: '/reports',
        name: 'reports',
        component: () => import('@/views/reports/Reports.vue'),
    },

    // Alerts
    {
        path: '/alerts',
        name: 'alerts',
        component: () => import('@/views/alerts/Alerts.vue'),
    },

    // Settings
    {
        path: '/settings/group-management',
        name: 'settings-group-management',
        component: () => import('@/views/settings/Index.vue'),
    },
    {
        path: '/settings/risk-parameters',
        name: 'settings-risk-parameters',
        component: () => import('@/views/settings/Index.vue'),
    },
    {
        path: '/settings/company-settings',
        name: 'settings-company-settings',
        component: () => import('@/views/settings/Index.vue'),
    },
  ]
  