export const defaultState = () => {
  return {
    market_exposure: [],
    is_market_exposure_fetched: false,
    risk_threshold: {},
    group_levels: [],
    current_user_statistics: [],
    is_current_user_statistics_fetched: false,
    account_analysis: [],
    is_account_analysis_fetched: false,
  }
}

export default defaultState()