import Vue from 'vue'
import vSelect from 'vue-select'

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: h => h('font-awesome-icon', { props: { icon: ['fal', 'times'], class: 'font-small-4'} }),
  },
  OpenIndicator: {
    render: h => h('font-awesome-icon', { props: { icon: ['fas', 'caret-down'] }, class: 'font-small-4 open-indicator' }),
  },
})

Vue.component(vSelect)
