import Vue from 'vue'

export default {
  SET_VALUE(state, {key, value}) {
    Vue.set(state, key, value)  
  },

  PUSH_DATUM(state, {metric, datum}) {
    state[metric].push(datum)
  },
  
  SET_DATUM_VALUE(state, {metric, datum, value}) {
    Vue.set(state[metric].find(item => item.id == datum.id), "value", value)
  },

  REMOVE_DATUM(state, {metric, datum}) {
    let index = state[metric].findIndex(item => item.id == datum.id)
    if (index != -1)
      state[metric].splice(index, 1)
  }
}