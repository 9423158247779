import mock from "@/@fake-db/mock";

const TradingPLatforms = {
    short_name: "trading_platforms",
    schema: { type: "object", properties: {} },
    settings: {},
    created_at: "2021-06-03T09:25:32.640Z",
    ref_id: "3289172f-e125-45f2-94e3-6a9701c59c7a",
    ref_type: "SiteSetting",
    children: {
        default_platform: {
            short_name: "default_platform",
            schema: { type: "object", properties: { name: { type: "string" }, type: { type: "string" } } },
            settings: { name: "", type: "" },
            created_at: "2021-06-03T09:25:32.739Z",
            ref_id: "c0b7b9bb-b1bc-4721-a12c-6ac5d6e6a10b",
            ref_type: "SiteSetting",
            children: {
                groups: {
                    short_name: "groups",
                    schema: {
                        type: "array",
                        items: {
                            type: "object",
                            required: [
                                "group_name",
                                "server_name",
                                "platform_group",
                                "created_at",
                                "currency",
                                "state",
                            ],
                            properties: {
                                state: { type: "string" },
                                currency: { type: "string" },
                                created_at: { type: "string" },
                                group_name: { type: "string" },
                                server_name: { type: "string" },
                                account_type: { type: ["null", "string"] },
                                platform_group: { type: "string" },
                            },
                            additionalProperties: false,
                        },
                    },
                    settings: [],
                    created_at: "2021-06-03T09:25:32.806Z",
                    ref_id: "7c414a4b-22b4-4e6d-a919-ba4427879378",
                    ref_type: "SiteSetting",
                },
                symbols: {
                    short_name: "symbols",
                    schema: {
                        type: "object",
                        patternProperties: {
                            "[A-Za-z]+": {
                                type: "object",
                                properties: {
                                    long: { type: "number" },
                                    name: { type: "string" },
                                    group: { type: "string" },
                                    short: { type: "number" },
                                    state: { type: "string" },
                                    trade: { type: "number" },
                                    category: {
                                        enum: [
                                            "FX",
                                            "CFD",
                                            "Stock",
                                            "Metal",
                                            "Crypto",
                                            "Indices",
                                            "Energy",
                                            "future",
                                            "Other",
                                        ],
                                        type: "string",
                                    },
                                    create_at: { type: "string" },
                                    execution: { type: "number" },
                                },
                            },
                            additionalProperties: false,
                        },
                    },
                    settings: {},
                    created_at: "2021-06-03T09:25:32.772Z",
                    ref_id: "c7c16e7a-80c9-4860-ad25-d7c426a5cc57",
                    ref_type: "SiteSetting",
                },
            },
        },
        live1: {
            name: "live1",
            platform: "MT5",
            login: 10000,
            gateways: [],
            main_server: "65.21.122.207:443",
            backup_servers: [{ server_name: "DE", server_address: "65.21.122.207:443", server_enabled: true }],
            login_range_min: 200000,
            login_range_max: 299999,
            mt_name: "EAERAMontenegroTest-Demo",
            info: { group: "real\\EAERA\\*" },
            state: "running",
            is_enabled: true,
            workers: {
                trade_api: "running",
                trade_updater: "offline",
                maintenance_api: "running",
                realtime_updater: "offline",
            },
            type: "Live",
            password: null,
        },
        demo: {
            name: "demo",
            platform: "MT5",
            login: 10001,
            gateways: [],
            main_server: "65.21.122.207:443",
            backup_servers: [{ server_name: "DE", server_address: "65.21.122.207:443", server_enabled: true }],
            login_range_min: 300000,
            login_range_max: 399999,
            mt_name: "EAERAMontenegroTest-Demo",
            info: { group: "demo\\EAERA\\*" },
            state: "running",
            is_enabled: true,
            workers: {
                trade_api: "running",
                trade_updater: "offline",
                maintenance_api: "running",
                realtime_updater: "offline",
            },
            type: "Demo",
            password: null,
        },
    },
};

mock.onGet("/TradingPLatforms/data").reply(() => [200, TradingPLatforms]);
