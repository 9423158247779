import mock from "@/@fake-db/mock";

const AccountSetup = {
    short_name: "account_setup",
    schema: {
        type: "object",
        patternProperties: {
            "[A-Za-z0-9!@#$%^\u0026*()_., ]+": {
                type: "object",
                properties: {
                    name: { type: "string" },
                    promotions: { type: "array", items: { type: "object" } },
                    account_rules: {
                        type: "array",
                        items: {
                            type: "object",
                            required: [
                                "server",
                                "currency",
                                "group",
                                "platform",
                                "countries",
                                "countries_op",
                                "account_type",
                            ],
                            properties: {
                                group: { type: "string" },
                                server: { type: "string" },
                                enabled: { type: "boolean", default: true },
                                currency: { type: "string" },
                                platform: { type: "string" },
                                countries: { type: "array" },
                                account_type: { type: "string" },
                                countries_op: { enum: ["all", "include", "exclude"], type: "string" },
                            },
                            additionalProperties: false,
                        },
                    },
                    account_types: {
                        type: "array",
                        items: {
                            type: "object",
                            required: ["shortname", "label", "countries_op"],
                            properties: {
                                label: { type: "string", maxLength: 255, minLength: 1 },
                                enabled: { type: "boolean", default: true },
                                countries: { type: "array" },
                                promotion: { type: ["integer", "null"] },
                                shortname: { type: "string", maxLength: 255, minLength: 1 },
                                countries_op: { enum: ["all", "include", "exclude"], type: "string" },
                            },
                            additionalProperties: false,
                        },
                    },
                    currency_based: { type: "array", items: { type: "object" } },
                    leverage_types: { type: "array", items: { type: "object" } },
                },
            },
        },
    },
    settings: {
        demo: {
            promotions: [
                {
                    id: 1,
                    name: "Bonus 50% for Demo",
                    bonus: "50",
                    enabled: true,
                    removal: "50",
                    currency: "USD",
                    max_bonus: "500",
                    description: "For test",
                    fixed_bonus: "50",
                    min_deposit: "100",
                    removal_type: "fixed",
                },
            ],
            account_rules: [
                {
                    group: "demo-demo\\EAERA\\A_USD",
                    server: "demo",
                    enabled: true,
                    currency: "USD",
                    platform: "MT5",
                    countries: [],
                    account_type: "Demo 50% Bonus",
                    countries_op: "all",
                },
                {
                    group: "demo-demo\\EAERA\\A_P_USD",
                    server: "demo",
                    enabled: true,
                    currency: "USD",
                    platform: "MT5",
                    countries: [],
                    account_type: "Demo 50% Bonus",
                    countries_op: "all",
                },
                {
                    group: "demo-demo\\EAERA\\A_P_EUR",
                    server: "demo",
                    enabled: true,
                    currency: "EUR",
                    platform: "MT5",
                    countries: [],
                    account_type: "Demo 50% Bonus",
                    countries_op: "all",
                },
            ],
            account_types: [
                {
                    label: "Demo 50% Bonus",
                    enabled: true,
                    countries: [],
                    promotion: 1,
                    shortname: "Demo 50% Bonus",
                    countries_op: "all",
                },
            ],
            currency_based: [
                { enabled: true, currency: "GBP" },
                { enabled: true, currency: "EUR" },
                { enabled: true, currency: "CHF" },
                { enabled: true, currency: "JPY" },
                { enabled: true, currency: "USD" },
            ],
            leverage_types: [
                { value: 10, enabled: true, countries: [], countries_op: "all" },
                { value: 100, enabled: true, countries: [], countries_op: "all" },
            ],
        },
        live1: {
            promotions: [
                {
                    id: 2,
                    name: "Promotion10%",
                    bonus: "10",
                    enabled: true,
                    removal: "100",
                    currency: "USD",
                    max_bonus: "1000",
                    description: "",
                    fixed_bonus: "10",
                    min_deposit: "200",
                    removal_type: "percentage_credit",
                },
                {
                    id: 3,
                    name: "Promotion20%",
                    bonus: "20",
                    enabled: true,
                    removal: "100",
                    currency: "USD",
                    max_bonus: "1000",
                    description: "",
                    fixed_bonus: "0",
                    min_deposit: "200",
                    removal_type: "percentage_amount",
                },
            ],
            account_rules: [
                {
                    group: "live1-real\\EAERA\\ASIA\\A_P_USD",
                    server: "live1",
                    enabled: true,
                    currency: "USD",
                    platform: "MT5",
                    countries: [],
                    account_type: "Trading with Bonus",
                    countries_op: "all",
                },
                {
                    group: "live1-real\\EAERA\\EU\\A_USD",
                    server: "live1",
                    enabled: true,
                    currency: "USD",
                    platform: "MT5",
                    countries: [],
                    account_type: "Trading without Bonus",
                    countries_op: "all",
                },
                {
                    group: "live1-real\\EAERA\\EU\\A_P_JPY",
                    server: "live1",
                    enabled: true,
                    currency: "JPY",
                    platform: "MT5",
                    countries: [],
                    account_type: "Trading with Bonus",
                    countries_op: "all",
                },
                {
                    group: "live1-real\\EAERA\\EU\\A_P_EUR",
                    server: "live1",
                    enabled: true,
                    currency: "EUR",
                    platform: "MT5",
                    countries: [],
                    account_type: "Trading with Bonus",
                    countries_op: "all",
                },
                {
                    group: "live1-real\\EAERA\\EU\\A_P_USD",
                    server: "live1",
                    enabled: true,
                    currency: "USD",
                    platform: "MT5",
                    countries: ["AD"],
                    account_type: "Trading with Bonus",
                    countries_op: "include",
                },
                {
                    group: "live1-real\\EAERA\\ASIA\\MilicaTestUSD",
                    server: "live1",
                    enabled: true,
                    currency: "USD",
                    platform: "MT5",
                    countries: [],
                    account_type: "Trading with Bonus",
                    countries_op: "all",
                },
                {
                    group: "live1-real\\EAERA\\ASIA\\A_CHF",
                    server: "live1",
                    enabled: false,
                    currency: "CHF",
                    platform: "MT5",
                    countries: [],
                    account_type: "Trading without Bonus",
                    countries_op: "all",
                },
                {
                    group: "live1-real\\EAERA\\EU\\A_EUR",
                    server: "live1",
                    enabled: true,
                    currency: "EUR",
                    platform: "MT5",
                    countries: [],
                    account_type: "Trading without Bonus",
                    countries_op: "all",
                },
                {
                    group: "live1-real\\EAERA\\EU\\A_JPY",
                    server: "live1",
                    enabled: true,
                    currency: "JPY",
                    platform: "MT5",
                    countries: [],
                    account_type: "Trading without Bonus",
                    countries_op: "all",
                },
            ],
            account_types: [
                {
                    label: "Trading without Bonus",
                    enabled: true,
                    countries: [],
                    promotion: null,
                    shortname: "Test1234",
                    countries_op: "all",
                },
                {
                    label: "Trading with Bonus",
                    enabled: true,
                    countries: [],
                    promotion: 2,
                    shortname: "Trading with bonus",
                    countries_op: "all",
                },
                {
                    label: "Trading with Extra Bonus",
                    enabled: true,
                    countries: ["AD"],
                    promotion: 3,
                    shortname: "Trading with Extra Bonus",
                    countries_op: "include",
                },
            ],
            currency_based: [
                { enabled: true, currency: "USD" },
                { enabled: true, currency: "EUR" },
                { enabled: true, currency: "GBP" },
                { enabled: true, currency: "JPY" },
                { enabled: true, currency: "CHF" },
            ],
            leverage_types: [
                { value: 2, enabled: true, countries: [], countries_op: "all" },
                { value: 5, enabled: true, countries: [], countries_op: "all" },
                { value: 10, enabled: true, countries: ["AD"], countries_op: "exclude" },
                { value: 50, enabled: true, countries: ["CN", "DE", "JP", "ME", "VN"], countries_op: "include" },
                { value: 100, enabled: true, countries: ["AD"], countries_op: "all" },
                { value: 200, enabled: true, countries: ["AD"], countries_op: "all" },
                { value: 500, enabled: true, countries: [], countries_op: "all" },
            ],
        },
    },
    created_at: "2021-06-03T09:25:32.936Z",
    ref_id: "d5a2f202-d3ad-4c36-b862-2f63c4b75544",
    ref_type: "SiteSetting",
};

mock.onGet("/AccountSetup/data").reply(() => [200, AccountSetup]);
