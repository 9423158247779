import mock from "@/@fake-db/mock";
import { getRandomInt } from "@/@fake-db/utils";

const CurrentUserStatistics = {
    data: [
        {
            id: 65403,
            name: "ftd_risk_7024",
            properties: {
                path: ["natro", "ib", "natro-ib-ib001-gold-pro-1-100"],
                group: "Natro\\IB\\IB001\\Gold Pro\\1:100",
                login: 7024,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 161,
                    city: "",
                    lock: false,
                    name: "name_1",
                    email: "email_1",
                    group: "Natro\\IB\\IB001\\Gold Pro\\1:100",
                    login: 7024,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 70871.73,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T14:09:23.129Z",
                    identifier: null,
                    last_login: "2021-10-06T14:09:21.000Z",
                    lead_source: "",
                    prev_equity: 57653.2,
                    prev_balance: 69853.33,
                    agent_account: 7019,
                    register_time: "2020-06-12T11:16:25.000Z",
                    prevmonth_equity: 87575.96,
                    prevmonth_balance: 94738.15,
                },
                register_time: "2020-06-12T11:16:25.000+00:00",
            },
            value: {
                ActualPL: 88740.04,
                OpenLots: 7.78,
                PLOverall: 261939.94,
                FloatingPL: -15063.02,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 794172.32,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 1036.74,
                PLLastMonth: 2.87000000001,
                PLThisMonth: 1164.07999999998,
                PLYesterday: -84092.48,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 2364,
                Withdrawable: 88740.04,
                "%ChangeEquity": 100,
                ClosedPLToday: 2177.7799999999997,
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 88179.95,
                MonthlyVolume: 1662415.3599999999,
                "#LotsThisMonth": 21.3,
                ClosedLotsToday: 3.48,
                ClosedSwapToday: 159.63,
                TotalFloatingPL: -12933.18,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: 12484.899999999996,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: 565033.9199999999,
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 11,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: 1796.62,
                WdableAccCurrency: 88532.04,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -173199.90000000002,
                TotalClosedPLToday: 2337.41,
                ActualPLAccCurrency: 88532.04,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: 10000,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 61,
                InitialMontlhyVolume: 2581836.2000000016,
                PLOverallAccCurrency: 261731.94,
                "%ProfitableTotalTrade": 61.85263157894737,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 87575.96,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 62.1404399323181,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 1469,
                PLThisMonthAccCurrency: 956.07999999998,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 88740.04,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 84092.48,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -1175.7436363636364,
                ClosedPLOverallBeforeToday: 150311.77000000002,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 77573.09,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 1097381.44,
                ClosedPLThisMonthBeforeToday: 4717.74,
                DayBeforeYesterdayFloatingPL: -15363.410000000002,
                FloatingPLEndOfPreviousMonth: -7162.19,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -173199.9,
                ClosedLotsThisMonthBeforeToday: 17.82,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: 5319.84,
                ClosedPLThisYearBeforeThisMonth: 131769.05,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: 115.65819672131147,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 87575.96,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },

        {
            id: 65546,
            name: "ftd_risk_7075",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7038-pro-1-100"],
                group: "Natro\\IB\\IB-7038\\Pro\\1:100",
                login: 7075,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 293,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7038\\Pro\\1:100",
                    login: 7075,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:42.308Z",
                    identifier: null,
                    last_login: "2021-05-26T19:48:43.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: 7038,
                    register_time: "2020-12-10T10:28:05.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2020-12-10T10:28:05.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: 95343.52999999998,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 668.66,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 1823,
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -95343.52999999998,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 95343.53,
                "%ProfitableTotalTrade": 50.575973669775095,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 50.575973669775095,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 922,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -184763.80000000028,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -95343.53,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -190508.04000000033,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 65454,
            name: "ftd_risk_7040",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7042-pro-1-100"],
                group: "Natro\\IB\\IB-7042\\Pro\\1:100",
                login: 7040,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 276,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7042\\Pro\\1:100",
                    login: 7040,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 0.98,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:41.609Z",
                    identifier: null,
                    last_login: "2021-08-25T08:41:39.000Z",
                    lead_source: "",
                    prev_equity: 0.98,
                    prev_balance: 0.98,
                    agent_account: 7042,
                    register_time: "2020-08-25T07:40:54.000Z",
                    prevmonth_equity: 0.98,
                    prevmonth_balance: 0.98,
                },
                register_time: "2020-08-25T07:40:54.000+00:00",
            },
            value: {
                ActualPL: 0.98,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -26029.51,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 600.16,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -0.98,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 442,
                Withdrawable: 0.98,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 0.98,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 0.98,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 26030.49,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 0.98,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -26029.51,
                "%ProfitableTotalTrade": 63.34841628959276,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 0.98,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 63.34841628959276,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 280,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 0.98,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 0.98,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -26029.510000000002,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 0.98,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 26030.49,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -1647.51,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 0.98,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 65456,
            name: "ftd_risk_7046",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7042-pro-1-100"],
                group: "Natro\\IB\\IB-7042\\Pro\\1:100",
                login: 7046,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 277,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7042\\Pro\\1:100",
                    login: 7046,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 50,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:41.646Z",
                    identifier: null,
                    last_login: "2021-08-25T08:34:11.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: 7042,
                    register_time: "2020-09-04T11:29:02.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2020-09-04T11:29:02.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: -1280.98,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 39.35,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 120,
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 1280.98,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -1280.98,
                "%ProfitableTotalTrade": 60,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 60,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 72,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -1280.98,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 1280.98,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 65458,
            name: "ftd_risk_7112",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7042-pro-1-100"],
                group: "Natro\\IB\\IB-7042\\Pro\\1:100",
                login: 7112,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 278,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7042\\Pro\\1:100",
                    login: 7112,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T12:16:38.067Z",
                    identifier: null,
                    last_login: "2021-10-06T12:16:12.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-10-05T05:59:41.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2021-10-05T05:59:41.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 65496,
            name: "ftd_risk_7039",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7038-silver-1-50"],
                group: "Natro\\IB\\IB-7038\\Silver\\1:50",
                login: 7039,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 279,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7038\\Silver\\1:50",
                    login: 7039,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 4.52,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 50,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:41.728Z",
                    identifier: null,
                    last_login: "2021-10-04T14:00:57.000Z",
                    lead_source: "",
                    prev_equity: 4.52,
                    prev_balance: 4.52,
                    agent_account: 7042,
                    register_time: "2020-08-21T15:08:15.000Z",
                    prevmonth_equity: 4.52,
                    prevmonth_balance: 4.52,
                },
                register_time: "2020-08-21T15:08:15.000+00:00",
            },
            value: {
                ActualPL: 4.52,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -52025.48,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 743.82,
                PLLastMonth: -9119.53,
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -4.52,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 1206,
                Withdrawable: 4.52,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 4.52,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -9441.23,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 4.52,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 52030,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 4.52,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: 3280,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -52025.48,
                "%ProfitableTotalTrade": 76.53399668325041,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 4.52,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 76.53399668325041,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 923,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 4.52,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 4.52,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -52925.48,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 5844.05,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 52030,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: -321.7,
                ClosedPLThisYearBeforeThisMonth: -28251.74,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 4.52,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 65498,
            name: "ftd_risk_7059",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7038-silver-1-50"],
                group: "Natro\\IB\\IB-7038\\Silver\\1:50",
                login: 7059,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 280,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7038\\Silver\\1:50",
                    login: 7059,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 50,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:41.766Z",
                    identifier: null,
                    last_login: "2021-08-25T08:38:16.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: 7038,
                    register_time: "2020-10-22T14:16:32.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2020-10-22T14:16:32.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: -5786.179999999999,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 81.15,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 210,
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 5786.179999999999,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -5786.18,
                "%ProfitableTotalTrade": 83.80952380952381,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 83.80952380952381,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 176,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -5786.18,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 5786.18,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -996.4000000000001,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 65501,
            name: "ftd_risk_7098",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7038-silver-1-50"],
                group: "Natro\\IB\\IB-7038\\Silver\\1:50",
                login: 7098,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 281,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7038\\Silver\\1:50",
                    login: 7098,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:41.804Z",
                    identifier: null,
                    last_login: "2021-09-06T09:32:50.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-07-08T08:13:48.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2021-07-08T08:13:48.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 65502,
            name: "ftd_risk_7108",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7038-silver-1-50"],
                group: "Natro\\IB\\IB-7038\\Silver\\1:50",
                login: 7108,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 282,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7038\\Silver\\1:50",
                    login: 7108,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 14.2,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 50,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:41.843Z",
                    identifier: null,
                    last_login: "2021-09-27T16:56:48.000Z",
                    lead_source: "",
                    prev_equity: 14.2,
                    prev_balance: 14.2,
                    agent_account: 7042,
                    register_time: "2021-09-13T09:21:11.000Z",
                    prevmonth_equity: 14.2,
                    prevmonth_balance: 14.2,
                },
                register_time: "2021-09-13T09:21:11.000+00:00",
            },
            value: {
                ActualPL: 14.2,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 4614.2,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 113,
                PLLastMonth: 4614.2,
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -14.2,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 29,
                Withdrawable: 14.2,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 14.2,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: 4614.2,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 14.2,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -4600,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 14.2,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: -4600,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 4614.2,
                "%ProfitableTotalTrade": 100,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 14.2,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 100,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 29,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 14.2,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 14.2,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: 4614.2,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -4600,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: 4614.2,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 14.2,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 65650,
            name: "ftd_risk_7055",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7038-gold-1-100"],
                group: "Natro\\IB\\IB-7038\\Gold\\1:100",
                login: 7055,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 284,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7038\\Gold\\1:100",
                    login: 7055,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:41.926Z",
                    identifier: null,
                    last_login: "2021-09-20T10:32:37.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-10-05T12:27:16.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2020-10-05T12:27:16.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: 3079.6,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 42.6,
                PLLastMonth: 1890,
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 45,
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -3079.6,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: -1890,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 3079.6,
                "%ProfitableTotalTrade": 77.77777777777777,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 77.77777777777777,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 35,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: 1189.6,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -3079.6,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 65652,
            name: "ftd_risk_7056",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7038-gold-1-100"],
                group: "Natro\\IB\\IB-7038\\Gold\\1:100",
                login: 7056,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 285,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7038\\Gold\\1:100",
                    login: 7056,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:41.966Z",
                    identifier: null,
                    last_login: "2021-08-25T08:38:54.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: 7038,
                    register_time: "2020-10-07T11:07:46.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2020-10-07T11:07:46.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: -622.4100000000001,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 17.57,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 376,
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 622.4100000000001,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -622.41,
                "%ProfitableTotalTrade": 78.45744680851064,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 78.45744680851064,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 295,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -622.4100000000001,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 622.41,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },

        {
            id: 67706,
            name: "ftd_risk_7094",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7016-standard-1-50"],
                group: "Natro\\IB\\IB-7016\\Standard\\1:50",
                login: 7094,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 273,
                    city: "",
                    lock: true,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7016\\Standard\\1:50",
                    login: 7094,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: true,
                    timestamp: "2021-10-06T09:55:41.490Z",
                    identifier: null,
                    last_login: "2021-06-23T18:33:09.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: 7016,
                    register_time: "2021-06-22T13:45:56.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2021-06-22T13:45:56.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: 25.19999999999982,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 0.1,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 1,
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -25.199999999999818,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 25.19999999999982,
                "%ProfitableTotalTrade": 100,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 100,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 1,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: 25.2,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -25.199999999999818,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: 25.2,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 67709,
            name: "ftd_risk_7095",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7016-standard-1-50"],
                group: "Natro\\IB\\IB-7016\\Standard\\1:50",
                login: 7095,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 274,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7016\\Standard\\1:50",
                    login: 7095,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 781.39,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 50,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:41.532Z",
                    identifier: null,
                    last_login: "2021-10-06T04:56:06.000Z",
                    lead_source: "",
                    prev_equity: 441.24,
                    prev_balance: 781.39,
                    agent_account: 7016,
                    register_time: "2021-06-23T18:30:40.000Z",
                    prevmonth_equity: 427.11,
                    prevmonth_balance: 781.39,
                },
                register_time: "2021-06-23T18:30:40.000+00:00",
            },
            value: {
                ActualPL: 437.8,
                OpenLots: 0.05,
                PLOverall: -2347.4,
                FloatingPL: -346.2,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 8854.25,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 14,
                PLLastMonth: -329.5,
                PLThisMonth: 10.69,
                PLYesterday: -425.91,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 49,
                Withdrawable: 437.8,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 406.05,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -352.94,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: 24.78,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 1,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: -6.74,
                WdableAccCurrency: 437.8,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 2785.2,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 437.8,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -2347.4,
                "%ProfitableTotalTrade": 94,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 427.11,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 95.91836734693878,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 47,
                PLThisMonthAccCurrency: 10.69,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 437.8,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 425.91,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -352.73,
                ClosedPLOverallBeforeToday: -2003.809999999998,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 756.61,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: -355.47999999999996,
                FloatingPLEndOfPreviousMonth: -354.28000000000003,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 2785.2,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -2003.809999999998,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 427.11,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 67712,
            name: "ftd_risk_7101",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7016-standard-1-50"],
                group: "Natro\\IB\\IB-7016\\Standard\\1:50",
                login: 7101,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 275,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7016\\Standard\\1:50",
                    login: 7101,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 0.01,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 50,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:41.572Z",
                    identifier: null,
                    last_login: "2021-10-04T21:12:05.000Z",
                    lead_source: "",
                    prev_equity: 0.01,
                    prev_balance: 0.01,
                    agent_account: 7016,
                    register_time: "2021-08-08T19:49:41.000Z",
                    prevmonth_equity: 0.01,
                    prevmonth_balance: 0.01,
                },
                register_time: "2021-08-08T19:49:41.000+00:00",
            },
            value: {
                ActualPL: 0.01,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 56.01,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 3.62,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -0.01,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 37,
                Withdrawable: 0.01,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 0.01,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 0.01,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -56,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 0.01,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 56.01,
                "%ProfitableTotalTrade": 59.45945945945946,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 0.01,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 59.45945945945946,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 22,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 0.01,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 0.01,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: 56.010000000000005,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 0.01,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -56,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: 56.010000000000005,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 0.01,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 67716,
            name: "ftd_risk_7078",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7016-standard-1-50"],
                group: "Natro\\IB\\IB-7016\\Standard\\1:50",
                login: 7078,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 272,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7016\\Standard\\1:50",
                    login: 7078,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T12:57:33.061Z",
                    identifier: null,
                    last_login: "2021-10-06T12:57:30.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: 7016,
                    register_time: "2020-12-29T20:27:26.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2020-12-29T20:27:26.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 67920,
            name: "ftd_risk_7109",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7014-pro-1-100"],
                group: "Natro\\IB\\IB-7014\\Pro\\1:100",
                login: 7109,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 162,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7014\\Pro\\1:100",
                    login: 7109,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 945.76,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T14:24:26.585Z",
                    identifier: null,
                    last_login: "2021-10-06T13:49:36.000Z",
                    lead_source: "",
                    prev_equity: 985.76,
                    prev_balance: 985.76,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-09-14T17:55:31.000Z",
                    prevmonth_equity: 657.56,
                    prevmonth_balance: 719.51,
                },
                register_time: "2021-09-14T17:55:31.000+00:00",
            },
            value: {
                ActualPL: 180.99,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 8280.99,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 29631.2,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 16.76,
                PLLastMonth: 4157.5599999999995,
                PLThisMonth: 4123.43,
                PLYesterday: -60.98999999999978,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 42,
                Withdrawable: 180.99,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 180.99,
                MonthlyVolume: 133538.5,
                "#LotsThisMonth": 10.78,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -917.99,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: -4600,
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 60.99,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -8100,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 90.99,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: -3500,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 11,
                InitialMontlhyVolume: 366964.63000000006,
                PLOverallAccCurrency: 5590.99,
                "%ProfitableTotalTrade": 76.19047619047619,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 657.56,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 76.19047619047619,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 32,
                PLThisMonthAccCurrency: 4033.4300000000003,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 180.99,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 60.98999999999978,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -880.4099999999999,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 133538.5,
                ClosedPLThisMonthBeforeToday: 37.58000000000004,
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: -61.95,
                MonthlyNetDepositAccCurrency: -4600,
                NetDepositsOverallAccCurrency: -5500,
                ClosedLotsThisMonthBeforeToday: 10.78,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -917.99,
                NetDepositsThisMonthBeforeToday: -4600,
                AVGProfitPerClosedTradeThisMonth: 3.41636363636364,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 657.56,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: -4600,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },

        {
            id: 67936,
            name: "ftd_risk_7049",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7014-pro-1-100"],
                group: "Natro\\IB\\IB-7014\\Pro\\1:100",
                login: 7049,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 288,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7014\\Pro\\1:100",
                    login: 7049,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 0.3,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:42.097Z",
                    identifier: null,
                    last_login: "2020-09-29T11:18:07.000Z",
                    lead_source: "",
                    prev_equity: 0.3,
                    prev_balance: 0.3,
                    agent_account: 7014,
                    register_time: "2020-09-18T07:45:27.000Z",
                    prevmonth_equity: 0.3,
                    prevmonth_balance: 0.3,
                },
                register_time: "2020-09-18T07:45:27.000+00:00",
            },
            value: {
                ActualPL: 0.3,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -740.7,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 1.2,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -0.3,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 8,
                Withdrawable: 0.3,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 0.3,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 0.3,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 741,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 0.3,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -740.7,
                "%ProfitableTotalTrade": 62.5,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 0.3,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 62.5,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 5,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 0.3,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 0.3,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -740.7,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 0.3,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 741,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 0.3,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },

        {
            id: 67947,
            name: "ftd_risk_7067",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7014-pro-1-100"],
                group: "Natro\\IB\\IB-7014\\Pro\\1:100",
                login: 7067,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 290,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7014\\Pro\\1:100",
                    login: 7067,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 5.49,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:42.174Z",
                    identifier: null,
                    last_login: "2021-10-05T17:17:10.000Z",
                    lead_source: "",
                    prev_equity: 5.49,
                    prev_balance: 5.49,
                    agent_account: 7014,
                    register_time: "2020-11-26T10:00:02.000Z",
                    prevmonth_equity: 5.49,
                    prevmonth_balance: 5.49,
                },
                register_time: "2020-11-26T10:00:02.000+00:00",
            },
            value: {
                ActualPL: 5.49,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -3229.51,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 62.22,
                PLLastMonth: -76.77000000000001,
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -5.49,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 354,
                Withdrawable: 5.49,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 5.49,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -165.53000000000003,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 5.49,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 3235,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 5.49,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -3229.51,
                "%ProfitableTotalTrade": 67.2316384180791,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 5.49,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 67.2316384180791,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 238,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 5.49,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 5.49,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -3229.5099999999998,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 82.26,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 3235,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: -88.76,
                ClosedPLThisYearBeforeThisMonth: -3101.4400000000005,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 5.49,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 67953,
            name: "ftd_risk_7083",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7014-pro-1-100"],
                group: "Natro\\IB\\IB-7014\\Pro\\1:100",
                login: 7083,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 291,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7014\\Pro\\1:100",
                    login: 7083,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 5298.89,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T14:12:25.164Z",
                    identifier: null,
                    last_login: "2021-10-06T08:09:51.000Z",
                    lead_source: "",
                    prev_equity: 4262.99,
                    prev_balance: 4716.49,
                    agent_account: 7014,
                    register_time: "2021-01-18T05:01:43.000Z",
                    prevmonth_equity: 0.49,
                    prevmonth_balance: 0.49,
                },
                register_time: "2021-01-18T05:01:43.000+00:00",
            },
            value: {
                ActualPL: 4085.64,
                OpenLots: 2,
                PLOverall: -4407.36,
                FloatingPL: -10,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 199.09,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: 1785.15,
                PLYesterday: -4006.64,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 524,
                Withdrawable: 4085.64,
                "%ChangeEquity": 100,
                ClosedPLToday: 88.5,
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 4328.14,
                MonthlyVolume: 2611485.25,
                "#LotsThisMonth": 26.5,
                ClosedLotsToday: 0.5,
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: 87645.5,
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 4,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: 2300,
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 3463.64,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 8493,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 3463.64,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 48,
                InitialMontlhyVolume: 3200805.75,
                PLOverallAccCurrency: -6729.36,
                "%ProfitableTotalTrade": 79.35606060606061,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 0.49,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 79.9618320610687,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 420,
                PLThisMonthAccCurrency: 1163.15,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 4085.64,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 4006.6400000000003,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -4486.360000000002,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 0.49,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 2611485.25,
                ClosedPLThisMonthBeforeToday: 1706.15,
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: 2300,
                NetDepositsOverallAccCurrency: 10193,
                ClosedLotsThisMonthBeforeToday: 26.5,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: 1845.2600000000002,
                NetDepositsThisMonthBeforeToday: 2300,
                AVGProfitPerClosedTradeThisMonth: 35.54479166666667,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 0.49,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: 2300,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 67955,
            name: "ftd_risk_7102",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7014-pro-1-100"],
                group: "Natro\\IB\\IB-7014\\Pro\\1:100",
                login: 7102,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 292,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7014\\Pro\\1:100",
                    login: 7102,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 18001.94,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T14:13:22.645Z",
                    identifier: null,
                    last_login: "2021-10-06T13:47:50.000Z",
                    lead_source: "",
                    prev_equity: 14724.95,
                    prev_balance: 17347.28,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-08-10T16:23:53.000Z",
                    prevmonth_equity: 10448.95,
                    prevmonth_balance: 12005.84,
                },
                register_time: "2021-08-10T16:23:53.000+00:00",
            },
            value: {
                ActualPL: 20535.04,
                OpenLots: 2.72,
                PLOverall: -9769.96,
                FloatingPL: -4012.48,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 478540.48,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 301.06,
                PLLastMonth: -26718.47,
                PLThisMonth: 10086.09,
                PLYesterday: -20314.11,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 466,
                Withdrawable: 20535.04,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 20172.64,
                MonthlyVolume: 1405701.76,
                "#LotsThisMonth": 18.76,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -4018.23,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -36009.37999999999,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 5,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: -5.75,
                WdableAccCurrency: 20535.04,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 30305,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 20535.04,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: 10000,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 47,
                InitialMontlhyVolume: 2383883.4800000004,
                PLOverallAccCurrency: -9769.96,
                "%ProfitableTotalTrade": 75.1592356687898,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 10448.95,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 75.9656652360515,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 354,
                PLThisMonthAccCurrency: 10086.09,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 20535.04,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 20314.11,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -802.264,
                ClosedPLOverallBeforeToday: -5803.0899999999965,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 27167.42,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 1405701.7600000002,
                ClosedPLThisMonthBeforeToday: 12496.07,
                DayBeforeYesterdayFloatingPL: -4187.8,
                FloatingPLEndOfPreviousMonth: -1556.89,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 30305,
                ClosedLotsThisMonthBeforeToday: 18.76,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: -10847.8,
                ClosedPLThisYearBeforeThisMonth: -18299.159999999996,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: 265.87382978723406,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 10448.95,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 67959,
            name: "ftd_risk_7030",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7014-pro-1-100"],
                group: "Natro\\IB\\IB-7014\\Pro\\1:100",
                login: 7030,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 286,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7014\\Pro\\1:100",
                    login: 7030,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 3.45,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T12:01:11.472Z",
                    identifier: null,
                    last_login: "2021-10-06T12:01:08.000Z",
                    lead_source: "",
                    prev_equity: 3.45,
                    prev_balance: 3.45,
                    agent_account: 7014,
                    register_time: "2020-07-31T09:36:52.000Z",
                    prevmonth_equity: 3.45,
                    prevmonth_balance: 3.45,
                },
                register_time: "2020-07-31T09:36:52.000+00:00",
            },
            value: {
                ActualPL: 3.45,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -11346.35,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 286.91,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -3.45,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 1377,
                Withdrawable: 3.45,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 3.45,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 3.45,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 11349.8,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 3.45,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -11346.35,
                "%ProfitableTotalTrade": 68.84531590413944,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 3.45,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 68.84531590413944,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 948,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 3.45,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 3.45,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -10348.730000000003,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 3.45,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 11349.8,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -27942.890000000003,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 3.45,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 68040,
            name: "ftd_risk_7009",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7014-gold-1-100"],
                group: "Natro\\IB\\IB-7014\\Gold\\1:100",
                login: 7009,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 268,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7014\\Gold\\1:100",
                    login: 7009,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 82353.23,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:41.278Z",
                    identifier: null,
                    last_login: "2021-10-04T07:11:50.000Z",
                    lead_source: "",
                    prev_equity: 84494.49,
                    prev_balance: 82353.23,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-05-14T05:02:02.000Z",
                    prevmonth_equity: 83218.86,
                    prevmonth_balance: 82737.59,
                },
                register_time: "2020-05-14T05:02:02.000+00:00",
            },
            value: {
                ActualPL: 82402.98,
                OpenLots: 5.1,
                PLOverall: 7402.98,
                FloatingPL: 507.3,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 1948904.7,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 10.7,
                PLLastMonth: -28861.41,
                PLThisMonth: -815.88,
                PLYesterday: -82930.8,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 11,
                Withdrawable: 82402.98,
                "%ChangeEquity": 100,
                ClosedPLToday: 75,
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 80980.08,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": 0.6,
                ClosedLotsToday: 3,
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -387.95,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: 6576.97,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: 526734,
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 5,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: -532.55,
                WdableAccCurrency: 82085.28,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 75000,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 82085.28,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: 25000,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 3,
                InitialMontlhyVolume: 103599.6,
                PLOverallAccCurrency: 7085.28,
                "%ProfitableTotalTrade": 47.05882352941177,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 83218.86,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 80,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 9,
                PLThisMonthAccCurrency: -1133.58,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 82402.98,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 82930.8,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -55.42142857142857,
                ClosedPLOverallBeforeToday: 7353.2300000000005,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 87080.27,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: -384.36,
                DayBeforeYesterdayFloatingPL: 577.57,
                FloatingPLEndOfPreviousMonth: 481.27000000000004,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 75000,
                ClosedLotsThisMonthBeforeToday: 0.6,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: 35919.65,
                ClosedPLThisYearBeforeThisMonth: 7737.59,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: -192.18,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 83218.86,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 68044,
            name: "ftd_risk_7050",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7014-gold-1-100"],
                group: "Natro\\IB\\IB-7014\\Gold\\1:100",
                login: 7050,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 269,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7014\\Gold\\1:100",
                    login: 7050,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 15.17,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:41.316Z",
                    identifier: null,
                    last_login: "2021-10-06T07:36:40.000Z",
                    lead_source: "",
                    prev_equity: 15.17,
                    prev_balance: 15.17,
                    agent_account: 7014,
                    register_time: "2020-09-21T14:12:40.000Z",
                    prevmonth_equity: 15.17,
                    prevmonth_balance: 15.17,
                },
                register_time: "2020-09-21T14:12:40.000+00:00",
            },
            value: {
                ActualPL: 15.17,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -13884.83,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 332.8,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -15.17,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 549,
                Withdrawable: 15.17,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 15.17,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 15.17,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 13900,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 15.17,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -13884.83,
                "%ProfitableTotalTrade": 90.7103825136612,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 15.17,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 90.7103825136612,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 498,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 15.17,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 15.17,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -12884.829999999996,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 15.17,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 13900,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -15307.86,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 15.17,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 68048,
            name: "ftd_risk_7104",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7014-gold-1-100"],
                group: "Natro\\IB\\IB-7014\\Gold\\1:100",
                login: 7104,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 270,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7014\\Gold\\1:100",
                    login: 7104,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 3735.91,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:41.358Z",
                    identifier: null,
                    last_login: "2021-09-30T09:21:00.000Z",
                    lead_source: "",
                    prev_equity: 3735.91,
                    prev_balance: 3735.91,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-08-11T16:01:00.000Z",
                    prevmonth_equity: 3735.91,
                    prevmonth_balance: 3735.91,
                },
                register_time: "2021-08-11T16:01:00.000+00:00",
            },
            value: {
                ActualPL: 3735.91,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -1264.09,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 8.4,
                PLLastMonth: -550.96,
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -3735.91,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 18,
                Withdrawable: 3735.91,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 3735.91,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -968.11,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 3735.91,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 5000,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 3735.91,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -1264.09,
                "%ProfitableTotalTrade": 55.55555555555556,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 3735.91,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 55.55555555555556,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 10,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 3735.91,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 3735.91,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -1264.0900000000001,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 4286.87,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 5000,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: -417.15,
                ClosedPLThisYearBeforeThisMonth: -1264.0900000000001,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 3735.91,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 68257,
            name: "ftd_risk_7044",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-standard-1-50"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                login: 7044,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 215,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                    login: 7044,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 6.71,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:39.055Z",
                    identifier: null,
                    last_login: "2021-03-29T19:33:18.000Z",
                    lead_source: "",
                    prev_equity: 6.71,
                    prev_balance: 6.71,
                    agent_account: 7043,
                    register_time: "2020-08-28T14:40:57.000Z",
                    prevmonth_equity: 6.71,
                    prevmonth_balance: 6.71,
                },
                register_time: "2020-08-28T14:40:57.000+00:00",
            },
            value: {
                ActualPL: 6.71,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 25168.9,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 458.21,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -6.71,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 560,
                Withdrawable: 6.71,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 6.71,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 6.71,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -25162.189999999995,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 6.71,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 25168.9,
                "%ProfitableTotalTrade": 71.42857142857143,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 6.71,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 71.42857142857143,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 400,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 6.71,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 6.71,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: 25168.899999999998,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 6.71,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -25162.19,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: 156.35000000000005,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 6.71,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 68263,
            name: "ftd_risk_7048",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-standard-1-50"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                login: 7048,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 216,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                    login: 7048,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:39.102Z",
                    identifier: null,
                    last_login: "2021-01-21T15:53:09.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-09-14T13:46:23.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2020-09-14T13:46:23.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: 587.95,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -587.95,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 587.95,
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -587.95,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 68270,
            name: "ftd_risk_7051",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-standard-1-50"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                login: 7051,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 217,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                    login: 7051,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 0.47,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:39.146Z",
                    identifier: null,
                    last_login: "2021-01-20T08:38:15.000Z",
                    lead_source: "",
                    prev_equity: 0.47,
                    prev_balance: 0.47,
                    agent_account: 7048,
                    register_time: "2020-09-22T19:25:01.000Z",
                    prevmonth_equity: 0.47,
                    prevmonth_balance: 0.47,
                },
                register_time: "2020-09-22T19:25:01.000+00:00",
            },
            value: {
                ActualPL: 0.47,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -104301.53,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 101.81,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -0.47,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 14,
                Withdrawable: 0.47,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 0.47,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 0.47,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 104302,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 0.47,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -104301.53,
                "%ProfitableTotalTrade": 21.428571428571427,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 0.47,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 21.428571428571427,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 3,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 0.47,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 0.47,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -104301.53,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 0.47,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 104302,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: 42915.84,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 0.47,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 68278,
            name: "ftd_risk_7053",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-standard-1-50"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                login: 7053,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 218,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                    login: 7053,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:39.194Z",
                    identifier: null,
                    last_login: "2020-10-27T11:38:54.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-09-27T19:16:25.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2020-09-27T19:16:25.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 68288,
            name: "ftd_risk_7060",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-standard-1-50"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                login: 7060,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 219,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                    login: 7060,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 8.61,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:39.236Z",
                    identifier: null,
                    last_login: "2020-11-04T09:57:06.000Z",
                    lead_source: "",
                    prev_equity: 8.61,
                    prev_balance: 8.61,
                    agent_account: 7043,
                    register_time: "2020-10-26T08:33:43.000Z",
                    prevmonth_equity: 8.61,
                    prevmonth_balance: 8.61,
                },
                register_time: "2020-10-26T08:33:43.000+00:00",
            },
            value: {
                ActualPL: 8.61,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -2491.39,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 331.41,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -8.61,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 331,
                Withdrawable: 8.61,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 8.61,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 8.61,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 2500,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 8.61,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -2491.39,
                "%ProfitableTotalTrade": 37.764350453172206,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 8.61,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 37.764350453172206,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 125,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 8.61,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 8.61,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -2491.3900000000003,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 8.61,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 2500,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 8.61,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 68292,
            name: "ftd_risk_7061",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-standard-1-50"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                login: 7061,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 220,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                    login: 7061,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 6.9,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:39.282Z",
                    identifier: null,
                    last_login: "2021-03-12T16:05:56.000Z",
                    lead_source: "",
                    prev_equity: 6.9,
                    prev_balance: 6.9,
                    agent_account: 7043,
                    register_time: "2020-11-05T15:23:47.000Z",
                    prevmonth_equity: 6.9,
                    prevmonth_balance: 6.9,
                },
                register_time: "2020-11-05T15:23:47.000+00:00",
            },
            value: {
                ActualPL: 6.9,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -19435.29,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 189.84,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -6.9,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 382,
                Withdrawable: 6.9,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 6.9,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 6.9,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 19442.19,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 6.9,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -19435.29,
                "%ProfitableTotalTrade": 72.5130890052356,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 6.9,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 72.5130890052356,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 277,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 6.9,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 6.9,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -21435.290000000005,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 6.9,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 19442.19,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -24319.110000000004,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 6.9,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 68299,
            name: "ftd_risk_7062",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-standard-1-50"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                login: 7062,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 221,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                    login: 7062,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 2.34,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:39.334Z",
                    identifier: null,
                    last_login: "2021-04-02T08:15:18.000Z",
                    lead_source: "",
                    prev_equity: 2.34,
                    prev_balance: 2.34,
                    agent_account: 7043,
                    register_time: "2020-11-10T19:59:55.000Z",
                    prevmonth_equity: 2.34,
                    prevmonth_balance: 2.34,
                },
                register_time: "2020-11-10T19:59:55.000+00:00",
            },
            value: {
                ActualPL: 2.34,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -5497.66,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 85.89,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -2.34,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 791,
                Withdrawable: 2.34,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 2.34,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 2.34,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 5500,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 2.34,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -5497.66,
                "%ProfitableTotalTrade": 64.72819216182047,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 2.34,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 64.72819216182047,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 512,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 2.34,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 2.34,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -6497.660000000001,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 2.34,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 5500,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -6728.43,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 2.34,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 68302,
            name: "ftd_risk_7073",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-standard-1-50"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                login: 7073,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 224,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                    login: 7073,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:39.466Z",
                    identifier: null,
                    last_login: "2020-12-09T06:22:45.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: 7053,
                    register_time: "2020-12-09T06:22:45.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2020-12-09T06:22:45.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 68316,
            name: "ftd_risk_7081",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-standard-1-50"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                login: 7081,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 225,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                    login: 7081,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 0.12,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:39.509Z",
                    identifier: null,
                    last_login: "2021-09-19T22:06:08.000Z",
                    lead_source: "",
                    prev_equity: 0.12,
                    prev_balance: 0.12,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-01-08T13:31:12.000Z",
                    prevmonth_equity: 0.12,
                    prevmonth_balance: 0.12,
                },
                register_time: "2021-01-08T13:31:12.000+00:00",
            },
            value: {
                ActualPL: 0.12,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 96820.12,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 40.51,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -0.12,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 193,
                Withdrawable: 0.12,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 0.12,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 0.12,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -96820,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 0.12,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 96820.12,
                "%ProfitableTotalTrade": 73.57512953367876,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 0.12,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 73.57512953367876,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 142,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 0.12,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 0.12,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: 66820.12000000001,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 0.12,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -96820,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: 66820.12000000001,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 0.12,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 68322,
            name: "ftd_risk_7084",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-standard-1-50"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                login: 7084,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 226,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                    login: 7084,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 0.49,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:39.547Z",
                    identifier: null,
                    last_login: "2021-08-25T08:34:17.000Z",
                    lead_source: "",
                    prev_equity: 0.49,
                    prev_balance: 0.49,
                    agent_account: 7042,
                    register_time: "2021-02-01T07:27:16.000Z",
                    prevmonth_equity: 0.49,
                    prevmonth_balance: 0.49,
                },
                register_time: "2021-02-01T07:27:16.000+00:00",
            },
            value: {
                ActualPL: 0.49,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 575.49,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 0.3,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -0.49,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 2,
                Withdrawable: 0.49,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 0.49,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 0.49,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -575,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 0.49,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 575.49,
                "%ProfitableTotalTrade": 100,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 0.49,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 100,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 2,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 0.49,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 0.49,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: 575.49,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 0.49,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -575,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 0.49,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 68327,
            name: "ftd_risk_7085",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-standard-1-50"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                login: 7085,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 227,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                    login: 7085,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:39.586Z",
                    identifier: null,
                    last_login: "2021-10-06T09:45:58.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-02-03T13:35:25.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2021-02-03T13:35:25.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 68333,
            name: "ftd_risk_7100",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-standard-1-50"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                login: 7100,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 228,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                    login: 7100,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 0.2,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:39.629Z",
                    identifier: null,
                    last_login: "2021-10-05T19:08:41.000Z",
                    lead_source: "",
                    prev_equity: 0.2,
                    prev_balance: 0.2,
                    agent_account: 7019,
                    register_time: "2021-07-27T10:51:23.000Z",
                    prevmonth_equity: 0.2,
                    prevmonth_balance: 0.2,
                },
                register_time: "2021-07-27T10:51:23.000+00:00",
            },
            value: {
                ActualPL: 0.2,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -10876.8,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 19,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -0.2,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 21,
                Withdrawable: 0.2,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 0.2,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 0.2,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 10877,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 0.2,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -10876.8,
                "%ProfitableTotalTrade": 66.66666666666667,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 0.2,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 66.66666666666667,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 14,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 0.2,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 0.2,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -10876.8,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 0.2,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 10877,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -10876.8,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 0.2,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 68337,
            name: "ftd_risk_7071",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-standard-1-50"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                login: 7071,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 223,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                    login: 7071,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 0.64,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T12:19:18.949Z",
                    identifier: null,
                    last_login: "2021-10-06T12:19:16.000Z",
                    lead_source: "",
                    prev_equity: 0.64,
                    prev_balance: 0.64,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-12-07T07:41:42.000Z",
                    prevmonth_equity: 0.64,
                    prevmonth_balance: 0.64,
                },
                register_time: "2020-12-07T07:41:42.000+00:00",
            },
            value: {
                ActualPL: 0.64,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -20774.36,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 38.22,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -0.64,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 83,
                Withdrawable: 0.64,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 0.64,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 0.64,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 20775,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 0.64,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -20774.36,
                "%ProfitableTotalTrade": 50.602409638554214,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 0.64,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 50.602409638554214,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 42,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 0.64,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 0.64,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -20774.36,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 0.64,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 20775,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 0.64,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 68341,
            name: "ftd_risk_7069",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-standard-1-50"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                login: 7069,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 222,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Standard\\1:50",
                    login: 7069,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 3.46,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 50,
                    read_only: false,
                    timestamp: "2021-10-06T11:15:33.547Z",
                    identifier: null,
                    last_login: "2021-10-06T11:15:31.000Z",
                    lead_source: "",
                    prev_equity: 3.46,
                    prev_balance: 3.46,
                    agent_account: 7014,
                    register_time: "2020-12-02T09:25:48.000Z",
                    prevmonth_equity: 3.46,
                    prevmonth_balance: 3.46,
                },
                register_time: "2020-12-02T09:25:48.000+00:00",
            },
            value: {
                ActualPL: 3.46,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -8476.54,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 120.02,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -3.46,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 501,
                Withdrawable: 3.46,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 3.46,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 3.46,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 8480,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 3.46,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -8476.54,
                "%ProfitableTotalTrade": 83.83233532934132,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 3.46,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 83.83233532934132,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 420,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 3.46,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 3.46,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -6926.54,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 3.46,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 8480,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -2273.51,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 3.46,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 69294,
            name: "ftd_risk_7011",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Pro\\1:100",
                login: 7011,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 204,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Pro\\1:100",
                    login: 7011,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 503,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:38.536Z",
                    identifier: null,
                    last_login: "2021-03-25T12:25:43.000Z",
                    lead_source: "",
                    prev_equity: 503,
                    prev_balance: 503,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-05-14T09:50:16.000Z",
                    prevmonth_equity: 503,
                    prevmonth_balance: 503,
                },
                register_time: "2020-05-14T09:50:16.000+00:00",
            },
            value: {
                ActualPL: 503,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 667.03,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 51.65,
                PLLastMonth: 3,
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -503,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 183,
                Withdrawable: 503,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 503,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 503,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -164.02999999999997,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 503,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 667.03,
                "%ProfitableTotalTrade": 77.04918032786885,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 503,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 77.04918032786885,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 141,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 503,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 503,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -482.65,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 500,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -164.02999999999997,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 503,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 69298,
            name: "ftd_risk_7021",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Pro\\1:100",
                login: 7021,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 205,
                    city: "",
                    lock: true,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Pro\\1:100",
                    login: 7021,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: true,
                    timestamp: "2021-10-06T09:55:38.581Z",
                    identifier: null,
                    last_login: "2021-07-14T09:28:07.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-06-08T08:35:21.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2020-06-08T08:35:21.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 69302,
            name: "ftd_risk_7027",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Pro\\1:100",
                login: 7027,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 206,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Pro\\1:100",
                    login: 7027,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 29.47,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:38.629Z",
                    identifier: null,
                    last_login: "2021-10-04T21:14:08.000Z",
                    lead_source: "",
                    prev_equity: 29.47,
                    prev_balance: 29.47,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-07-06T12:29:56.000Z",
                    prevmonth_equity: 29.47,
                    prevmonth_balance: 29.47,
                },
                register_time: "2020-07-06T12:29:56.000+00:00",
            },
            value: {
                ActualPL: 29.47,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -140140.58,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 20189.04,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -29.47,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 1330,
                Withdrawable: 29.47,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 29.47,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 29.47,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 140170.05,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 29.47,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -140140.58,
                "%ProfitableTotalTrade": 74.58646616541354,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 29.47,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 74.58646616541354,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 992,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 29.47,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 29.47,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -140140.58000000005,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 29.47,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 140170.05,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -50.95000000000002,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 29.47,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 69307,
            name: "ftd_risk_7031",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Pro\\1:100",
                login: 7031,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 208,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Pro\\1:100",
                    login: 7031,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 607795.51,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:38.720Z",
                    identifier: null,
                    last_login: "2021-10-05T09:44:01.000Z",
                    lead_source: "",
                    prev_equity: 340283.65,
                    prev_balance: 607795.51,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-08-04T14:25:02.000Z",
                    prevmonth_equity: 345623.77,
                    prevmonth_balance: 624706.98,
                },
                register_time: "2020-08-04T14:25:02.000+00:00",
            },
            value: {
                ActualPL: 347293.95,
                OpenLots: 81.57,
                PLOverall: -1552706.05,
                FloatingPL: 225738.01,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 8953986.46,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 3941.9,
                PLLastMonth: 32923.8,
                PLThisMonth: 1670.18,
                PLYesterday: -346081.48,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 1339,
                Withdrawable: 347293.95,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 337161.89,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": 7.5,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -270659.61,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -11375.29,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 85,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: -496397.62,
                WdableAccCurrency: 347293.95,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 1900000,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 347293.95,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: -450000,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 7,
                InitialMontlhyVolume: 890115,
                PLOverallAccCurrency: -1552706.05,
                "%ProfitableTotalTrade": 45.50561797752809,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 345623.77,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 48.39432412247946,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 648,
                PLThisMonthAccCurrency: 1670.18,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 347293.95,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 346081.48,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -3159.5914117647058,
                ClosedPLOverallBeforeToday: -1292204.4899999998,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 762699.97,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: -16911.47,
                DayBeforeYesterdayFloatingPL: -261714.03000000003,
                FloatingPLEndOfPreviousMonth: -279083.21,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 1900000,
                ClosedLotsThisMonthBeforeToday: 7.5,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: -323382.3,
                ClosedPLThisYearBeforeThisMonth: -1355463.3699999999,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: -2415.924285714286,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 345623.77,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 69311,
            name: "ftd_risk_7041",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Pro\\1:100",
                login: 7041,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 211,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Pro\\1:100",
                    login: 7041,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:38.865Z",
                    identifier: null,
                    last_login: "2021-05-27T17:00:00.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-08-25T09:01:36.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2020-08-25T09:01:36.000+00:00",
            },
            value: {
                ActualPL: 5310.61,
                OpenLots: 0.05,
                PLOverall: 6310.61,
                FloatingPL: -24.35,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 8774.05,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 11.35,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: 6310.61,
                PLYesterday: -250.43,
                DepositToday: 5000,
                OverallDeals: 33,
                Withdrawable: 5310.61,
                "%ChangeEquity": 100,
                ClosedPLToday: 69.49000000000001,
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 5300.06,
                MonthlyVolume: 1687076.15,
                "#LotsThisMonth": 10.95,
                ClosedLotsToday: 0.6,
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -26.07,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: 75102.8,
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 1,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: -1000,
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: -0.21,
                WdableAccCurrency: 5266.26,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -1000,
                TotalClosedPLToday: 26.6,
                ActualPLAccCurrency: 5266.26,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 33,
                InitialMontlhyVolume: 600268.0000000001,
                PLOverallAccCurrency: 6266.26,
                "%ProfitableTotalTrade": 79.41176470588235,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 84.375,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 28,
                PLThisMonthAccCurrency: 6266.26,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: 5000,
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 5310.61,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 250.43,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -26.07,
                ClosedPLOverallBeforeToday: 1265.6799999999998,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 1651933.1500000004,
                ClosedPLThisMonthBeforeToday: 1265.6799999999998,
                DayBeforeYesterdayFloatingPL: -15.25,
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: -1000,
                NetDepositsOverallAccCurrency: -1000,
                ClosedLotsThisMonthBeforeToday: 10.75,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: -1000,
                AVGProfitPerClosedTradeThisMonth: 40.38375,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: -1000,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 69316,
            name: "ftd_risk_7047",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Pro\\1:100",
                login: 7047,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 212,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Pro\\1:100",
                    login: 7047,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 66.03,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:38.916Z",
                    identifier: null,
                    last_login: "2021-10-01T18:55:48.000Z",
                    lead_source: "",
                    prev_equity: 66.03,
                    prev_balance: 66.03,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-09-07T07:37:44.000Z",
                    prevmonth_equity: 66.03,
                    prevmonth_balance: 66.03,
                },
                register_time: "2020-09-07T07:37:44.000+00:00",
            },
            value: {
                ActualPL: 66.03,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -39933.97,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 205.25,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -66.03,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 174,
                Withdrawable: 66.03,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 66.03,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 66.03,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 40000,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 66.03,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -39933.97,
                "%ProfitableTotalTrade": 48.275862068965516,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 66.03,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 48.275862068965516,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 84,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 66.03,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 66.03,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -39933.97000000001,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 66.03,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 40000,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -9914.720000000001,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 66.03,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 69322,
            name: "ftd_risk_7080",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Pro\\1:100",
                login: 7080,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 213,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Pro\\1:100",
                    login: 7080,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 370114.13,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:38.963Z",
                    identifier: null,
                    last_login: "2021-10-06T06:31:03.000Z",
                    lead_source: "",
                    prev_equity: 640269.13,
                    prev_balance: 370114.13,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-01-07T09:10:56.000Z",
                    prevmonth_equity: 475066.13,
                    prevmonth_balance: 370114.13,
                },
                register_time: "2021-01-07T09:10:56.000+00:00",
            },
            value: {
                ActualPL: 663283.13,
                OpenLots: 410,
                PLOverall: 205421.13,
                FloatingPL: 325860,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 4468780,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 1275.3,
                PLLastMonth: 267340,
                PLThisMonth: 188217,
                PLYesterday: -625699.13,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 57,
                Withdrawable: 663283.13,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 689083.13,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: 280989,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 21,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: -44871,
                WdableAccCurrency: 663283.13,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 457862,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 663283.13,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: -210000,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 205421.13,
                "%ProfitableTotalTrade": 30.76923076923077,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 475066.13,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 42.10526315789474,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 24,
                PLThisMonthAccCurrency: 188217,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 663283.13,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 625699.13,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: 13418.333333333334,
                ClosedPLOverallBeforeToday: -87747.87000000002,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 417726.13,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: 255585,
                FloatingPLEndOfPreviousMonth: 104952,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 457862,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: -162388,
                ClosedPLThisYearBeforeThisMonth: -25578.270000000048,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 475066.13,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 69325,
            name: "ftd_risk_7103",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Pro\\1:100",
                login: 7103,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 214,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Pro\\1:100",
                    login: 7103,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:39.012Z",
                    identifier: null,
                    last_login: "2021-09-02T12:23:07.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-08-10T20:30:42.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2021-08-10T20:30:42.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },

        {
            id: 69788,
            name: "ftd_risk_7016",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-kc-pro-100-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\KC Pro 100\\1:100",
                login: 7016,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 244,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\KC Pro 100\\1:100",
                    login: 7016,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 2023.64,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:40.278Z",
                    identifier: null,
                    last_login: "2021-10-05T22:22:36.000Z",
                    lead_source: "",
                    prev_equity: 2023.64,
                    prev_balance: 2023.64,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-05-22T08:10:09.000Z",
                    prevmonth_equity: 2023.64,
                    prevmonth_balance: 2023.64,
                },
                register_time: "2020-05-22T08:10:09.000+00:00",
            },
            value: {
                ActualPL: 2023.64,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 1858.64,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 11.48,
                PLLastMonth: -999.25,
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -2023.64,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 136,
                Withdrawable: 2023.64,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 2023.64,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 2023.64,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 165,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 2023.64,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: 1000,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 1858.64,
                "%ProfitableTotalTrade": 81.61764705882354,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 2023.64,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 81.61764705882354,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 111,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 2023.64,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 2023.64,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: 1863.4900000000005,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 2022.89,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 165,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: 1343.8400000000004,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 2023.64,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 69796,
            name: "ftd_risk_7020",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-kc-pro-100-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\KC Pro 100\\1:100",
                login: 7020,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 245,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\KC Pro 100\\1:100",
                    login: 7020,
                    phone: "",
                    state: "",
                    credit: 200000,
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 773.52,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:40.317Z",
                    identifier: null,
                    last_login: "2021-08-13T12:45:42.000Z",
                    lead_source: "",
                    prev_equity: 200773.52,
                    prev_balance: 773.52,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-06-03T10:50:55.000Z",
                    prevmonth_equity: 200773.52,
                    prevmonth_balance: 773.52,
                },
                register_time: "2020-06-03T10:50:55.000+00:00",
            },
            value: {
                ActualPL: 773.52,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -307257.3600000001,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 1017.24,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -773.52,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 300,
                Withdrawable: 773.52,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: 200000,
                CurrentEquity: 200773.52,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: 200000,
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 773.52,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 308030.8800000001,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 773.52,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: -200000,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -307257.36,
                "%ProfitableTotalTrade": 27,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 200773.52,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 27,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 81,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: 200000,
                CurrentCreditAccCurrency: 200000,
                CurrentEquityAccCurrency: 200773.52,
                DayBeforeYesterdayCredit: 200000,
                DayBeforeYesterdayEquity: 200773.52,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -507257.36,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 200773.52,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 308030.88,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: 6575.52,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 200773.52,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: 200000,
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 69805,
            name: "ftd_risk_7023",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-kc-pro-100-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\KC Pro 100\\1:100",
                login: 7023,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 246,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\KC Pro 100\\1:100",
                    login: 7023,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:40.355Z",
                    identifier: null,
                    last_login: "2020-06-12T09:06:16.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-06-12T09:06:16.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2020-06-12T09:06:16.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 69813,
            name: "ftd_risk_7025",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-kc-pro-100-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\KC Pro 100\\1:100",
                login: 7025,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 247,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\KC Pro 100\\1:100",
                    login: 7025,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 0.77,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:40.397Z",
                    identifier: null,
                    last_login: "2021-08-03T07:30:53.000Z",
                    lead_source: "",
                    prev_equity: 0.77,
                    prev_balance: 0.77,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-06-18T08:00:38.000Z",
                    prevmonth_equity: 0.77,
                    prevmonth_balance: 0.77,
                },
                register_time: "2020-06-18T08:00:38.000+00:00",
            },
            value: {
                ActualPL: 0.77,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 221156.63,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 1355,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -0.77,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 1917,
                Withdrawable: 0.77,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 0.77,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 0.77,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -221155.86,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 0.77,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 221156.63,
                "%ProfitableTotalTrade": 64.89306207616067,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 0.77,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 64.89306207616067,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 1244,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 0.77,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 0.77,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: 81156.62999999995,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 0.77,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -221155.86,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -80977.13000000006,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 0.77,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 69819,
            name: "ftd_risk_7034",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-kc-pro-100-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\KC Pro 100\\1:100",
                login: 7034,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 248,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\KC Pro 100\\1:100",
                    login: 7034,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:40.437Z",
                    identifier: null,
                    last_login: "2021-09-09T15:01:06.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-08-13T14:17:32.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2020-08-13T14:17:32.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 69824,
            name: "ftd_risk_7079",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-kc-pro-100-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\KC Pro 100\\1:100",
                login: 7079,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 251,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\KC Pro 100\\1:100",
                    login: 7079,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 0.39,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:40.568Z",
                    identifier: null,
                    last_login: "2021-02-03T19:13:36.000Z",
                    lead_source: "",
                    prev_equity: 0.39,
                    prev_balance: 0.39,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-01-06T06:00:54.000Z",
                    prevmonth_equity: 0.39,
                    prevmonth_balance: 0.39,
                },
                register_time: "2021-01-06T06:00:54.000+00:00",
            },
            value: {
                ActualPL: 0.39,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 97.39,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 2.5,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -0.39,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 4,
                Withdrawable: 0.39,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 0.39,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 0.39,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -97,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 0.39,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 97.39,
                "%ProfitableTotalTrade": 75,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 0.39,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 75,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 3,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 0.39,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 0.39,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: 97.38999999999999,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 0.39,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -97,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: 97.38999999999999,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 0.39,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 69830,
            name: "ftd_risk_7096",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-kc-pro-100-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\KC Pro 100\\1:100",
                login: 7096,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 252,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\KC Pro 100\\1:100",
                    login: 7096,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:40.606Z",
                    identifier: null,
                    last_login: "2021-07-16T23:13:37.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-06-24T12:51:03.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2021-06-24T12:51:03.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 69840,
            name: "ftd_risk_7099",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-kc-pro-100-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\KC Pro 100\\1:100",
                login: 7099,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 253,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\KC Pro 100\\1:100",
                    login: 7099,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T11:26:37.672Z",
                    identifier: null,
                    last_login: "2021-10-06T11:26:16.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-07-15T11:20:30.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2021-07-15T11:20:30.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 69843,
            name: "ftd_risk_7072",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-kc-pro-100-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\KC Pro 100\\1:100",
                login: 7072,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 249,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\KC Pro 100\\1:100",
                    login: 7072,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 10203.3,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T14:30:10.931Z",
                    identifier: null,
                    last_login: "2021-10-06T14:30:10.000Z",
                    lead_source: "",
                    prev_equity: 10203.3,
                    prev_balance: 10203.3,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-12-07T10:57:52.000Z",
                    prevmonth_equity: 10297.8,
                    prevmonth_balance: 10297.8,
                },
                register_time: "2020-12-07T10:57:52.000+00:00",
            },
            value: {
                ActualPL: 9157,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 1962,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 303.43,
                PLLastMonth: 2297.04,
                PLThisMonth: 359.2,
                PLYesterday: -9157,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 431,
                Withdrawable: 9157,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 9157,
                MonthlyVolume: 759212,
                "#LotsThisMonth": 7,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: 2297.04,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: -1500,
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 9157,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 7195,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 9157,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: -2050,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 9,
                InitialMontlhyVolume: 702422,
                PLOverallAccCurrency: 1962,
                "%ProfitableTotalTrade": 85.1508120649652,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 10297.8,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 85.1508120649652,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 367,
                PLThisMonthAccCurrency: 359.2,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 9157,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 9157,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: 1962.0000000000025,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 10050.76,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 759212,
                ClosedPLThisMonthBeforeToday: 359.2,
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: -1500,
                NetDepositsOverallAccCurrency: 7195,
                ClosedLotsThisMonthBeforeToday: 7,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: 773.280000000004,
                NetDepositsThisMonthBeforeToday: -1500,
                AVGProfitPerClosedTradeThisMonth: 39.91111111111111,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 10297.8,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: -1500,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 69848,
            name: "ftd_risk_7074",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-kc-pro-100-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\KC Pro 100\\1:100",
                login: 7074,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 250,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\KC Pro 100\\1:100",
                    login: 7074,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 13338,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T11:35:19.250Z",
                    identifier: null,
                    last_login: "2021-10-06T11:01:54.000Z",
                    lead_source: "",
                    prev_equity: 9511.54,
                    prev_balance: 13303,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-12-09T08:03:38.000Z",
                    prevmonth_equity: 9146.77,
                    prevmonth_balance: 13212.6,
                },
                register_time: "2020-12-09T08:03:38.000+00:00",
            },
            value: {
                ActualPL: 8785.16,
                OpenLots: 2.35,
                PLOverall: 10785.16,
                FloatingPL: -4530.860000000001,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 274862.69999999995,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 394.1,
                PLLastMonth: -4057,
                PLThisMonth: -361.61,
                PLYesterday: -8778.71,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 186,
                Withdrawable: 8785.16,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 8483.04,
                MonthlyVolume: 57982.99999999999,
                "#LotsThisMonth": 0.7,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -4460.590000000001,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: 416.99,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 5,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: 70.27,
                WdableAccCurrency: 8785.16,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -2000,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 8785.16,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: -1500,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 3,
                InitialMontlhyVolume: 93016.9,
                PLOverallAccCurrency: 10785.16,
                "%ProfitableTotalTrade": 82.19895287958116,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 9146.77,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 84.40860215053763,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 157,
                PLThisMonthAccCurrency: -361.61,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 8785.16,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 8778.71,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -891.0760000000002,
                ClosedPLOverallBeforeToday: 15235.379999999996,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 14703.77,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 57982.99999999999,
                ClosedPLThisMonthBeforeToday: 125.4,
                DayBeforeYesterdayFloatingPL: -4559.290000000001,
                FloatingPLEndOfPreviousMonth: -4065.83,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -2000,
                ClosedLotsThisMonthBeforeToday: 0.7,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: 408.15999999999997,
                ClosedPLThisYearBeforeThisMonth: 15109.979999999996,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: 41.8,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 9146.77,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 71381,
            name: "ftd_risk_7097",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-kc-pro-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\KC Pro 100",
                login: 7097,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 255,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\KC Pro 100",
                    login: 7097,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:40.736Z",
                    identifier: null,
                    last_login: "2021-07-02T14:43:59.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-07-02T14:43:59.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2021-07-02T14:43:59.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 72513,
            name: "ftd_risk_7022",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-gold-pro-hedge-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro Hedge\\1:100",
                login: 7022,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 254,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro Hedge\\1:100",
                    login: 7022,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 208713.4,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:40.693Z",
                    identifier: null,
                    last_login: "2021-10-06T08:13:06.000Z",
                    lead_source: "",
                    prev_equity: 170012.13,
                    prev_balance: 208713.4,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-06-12T07:56:08.000Z",
                    prevmonth_equity: 200616.28,
                    prevmonth_balance: 250885.24,
                },
                register_time: "2020-06-12T07:56:08.000+00:00",
            },
            value: {
                ActualPL: 164706.91,
                OpenLots: 25.58,
                PLOverall: -370293.09,
                FloatingPL: -46207.020000000004,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 3328031.6799999997,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 1387.41,
                PLLastMonth: -176654.44,
                PLThisMonth: 14090.63,
                PLYesterday: -164050.28,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 982,
                Withdrawable: 164706.91,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 158234.81,
                MonthlyVolume: 393863.68,
                "#LotsThisMonth": 13.24,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -46882.73,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: 7823.6799999999985,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 4,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: -50000,
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: -675.71,
                WdableAccCurrency: 164706.91,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 535000,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 164706.91,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: 125000,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 12,
                InitialMontlhyVolume: 2031455.44,
                PLOverallAccCurrency: -370293.09,
                "%ProfitableTotalTrade": 51.11561866125761,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 200616.28,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 51.323828920570264,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 504,
                PLThisMonthAccCurrency: 14090.63,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 164706.91,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 164050.28,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -11696.55,
                ClosedPLOverallBeforeToday: -324337.63999999996,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 252270.72,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 393863.68,
                ClosedPLThisMonthBeforeToday: 9777.12,
                DayBeforeYesterdayFloatingPL: -46612.08,
                FloatingPLEndOfPreviousMonth: -50268.96,
                MonthlyNetDepositAccCurrency: -50000,
                NetDepositsOverallAccCurrency: 535000,
                ClosedLotsThisMonthBeforeToday: 13.24,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: 134209.16,
                ClosedPLThisYearBeforeThisMonth: -276585.6200000001,
                NetDepositsThisMonthBeforeToday: -50000,
                AVGProfitPerClosedTradeThisMonth: 814.7600000000001,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 200616.28,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: -50000,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 72708,
            name: "ftd_risk_7017",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-gold-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                login: 7017,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 124,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                    login: 7017,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 378.06,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T11:46:08.588Z",
                    identifier: null,
                    last_login: "2021-10-06T11:01:25.000Z",
                    lead_source: "",
                    prev_equity: 651.01,
                    prev_balance: 889.21,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-05-29T10:20:59.000Z",
                    prevmonth_equity: 666.26,
                    prevmonth_balance: 1093.91,
                },
                register_time: "2020-05-29T10:20:59.000+00:00",
            },
            value: {
                ActualPL: -22.32,
                OpenLots: 1,
                PLOverall: -106022.32,
                FloatingPL: -33,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 175496,
                NetDeposits: 20000,
                OverallLots: 5890.29,
                PLLastMonth: -15752.89,
                PLThisMonth: -688.58,
                PLYesterday: -10.68,
                DepositToday: 20000,
                OverallDeals: 8120,
                Withdrawable: 19977.68,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 19784.68,
                MonthlyVolume: 767020.2199999999,
                "#LotsThisMonth": 19.16,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -33,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -29942.53999999999,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 1,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: 20000,
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 19977.68,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 126000,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: -22.32,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 121,
                InitialMontlhyVolume: 3171068.729999998,
                PLOverallAccCurrency: -106022.32,
                "%ProfitableTotalTrade": 90.22287895579362,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 666.26,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 90.23399014778325,
                NetDepositsAccCurrency: 20000,
                OverallProfitableDeals: 7327,
                PLThisMonthAccCurrency: -688.58,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: 20000,
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 19977.68,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 10.68,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -33,
                ClosedPLOverallBeforeToday: -87259.31999999999,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 16419.15,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 767020.2199999999,
                ClosedPLThisMonthBeforeToday: -1083.23,
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: -427.65,
                MonthlyNetDepositAccCurrency: 20000,
                NetDepositsOverallAccCurrency: 126000,
                ClosedLotsThisMonthBeforeToday: 19.16,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: -14617.3,
                ClosedPLThisYearBeforeThisMonth: -40157.09999999999,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: -8.952314049586777,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 666.26,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 72714,
            name: "ftd_risk_7012",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-gold-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                login: 7012,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 232,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                    login: 7012,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:39.791Z",
                    identifier: null,
                    last_login: "2021-03-30T06:16:42.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-05-15T08:12:47.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2020-05-15T08:12:47.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 72719,
            name: "ftd_risk_7015",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-gold-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                login: 7015,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 233,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                    login: 7015,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:39.842Z",
                    identifier: null,
                    last_login: "2020-10-24T12:55:37.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-05-20T10:11:57.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2020-05-20T10:11:57.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 72727,
            name: "ftd_risk_7018",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-gold-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                login: 7018,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 234,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                    login: 7018,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:39.889Z",
                    identifier: null,
                    last_login: "2020-09-27T19:33:02.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-06-01T10:24:05.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2020-06-01T10:24:05.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 72730,
            name: "ftd_risk_7038",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-gold-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                login: 7038,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 236,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                    login: 7038,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 378.66,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:39.966Z",
                    identifier: null,
                    last_login: "2021-10-06T09:00:24.000Z",
                    lead_source: "",
                    prev_equity: 378.66,
                    prev_balance: 378.66,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-08-21T15:00:49.000Z",
                    prevmonth_equity: 2578.66,
                    prevmonth_balance: 2578.66,
                },
                register_time: "2020-08-21T15:00:49.000+00:00",
            },
            value: {
                ActualPL: 522.66,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 37555.87,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 5.36,
                PLLastMonth: 21348.4,
                PLThisMonth: 1179,
                PLYesterday: -450.66,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 58,
                Withdrawable: 522.66,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 522.66,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: -3235,
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 522.66,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -37033.21,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 522.66,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: -18845,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 37555.87,
                "%ProfitableTotalTrade": 62.06896551724138,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 2578.66,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 62.06896551724138,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 36,
                PLThisMonthAccCurrency: 1179,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 522.66,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 450.66,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -437.7,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 75.26,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: -3235,
                NetDepositsOverallAccCurrency: -37033.21,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: -3235,
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 2578.66,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: -3235,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 72736,
            name: "ftd_risk_7042",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-gold-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                login: 7042,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 237,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                    login: 7042,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 183.84,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:40.007Z",
                    identifier: null,
                    last_login: "2021-10-04T18:47:24.000Z",
                    lead_source: "",
                    prev_equity: 183.84,
                    prev_balance: 183.84,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-08-25T12:33:16.000Z",
                    prevmonth_equity: 183.84,
                    prevmonth_balance: 183.84,
                },
                register_time: "2020-08-25T12:33:16.000+00:00",
            },
            value: {
                ActualPL: 183.84,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 28637.6,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 24.58,
                PLLastMonth: 745.44,
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -183.84,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 361,
                Withdrawable: 183.84,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 183.84,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -37.38000000000001,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 183.84,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -28453.760000000002,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 183.84,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: -820,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 28637.6,
                "%ProfitableTotalTrade": 70.6371191135734,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 183.84,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 70.6371191135734,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 255,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 183.84,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 183.84,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -325.10999999999996,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 258.4,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -28453.76,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: 236.93,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 183.84,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 72741,
            name: "ftd_risk_7043",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-gold-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                login: 7043,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 238,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                    login: 7043,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 6.74,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:40.049Z",
                    identifier: null,
                    last_login: "2021-04-01T11:37:18.000Z",
                    lead_source: "",
                    prev_equity: 6.74,
                    prev_balance: 6.74,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-08-25T18:17:33.000Z",
                    prevmonth_equity: 6.74,
                    prevmonth_balance: 6.74,
                },
                register_time: "2020-08-25T18:17:33.000+00:00",
            },
            value: {
                ActualPL: 6.74,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 3572.74,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -6.74,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: 6.74,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 6.74,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 6.74,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -3566,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 6.74,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 3572.74,
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 6.74,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 6.74,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 6.74,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 6.74,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -3566,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 6.74,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 72744,
            name: "ftd_risk_7052",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-gold-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                login: 7052,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 239,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                    login: 7052,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:40.087Z",
                    identifier: null,
                    last_login: "2021-10-06T04:50:43.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-09-24T08:40:09.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2020-09-24T08:40:09.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 72747,
            name: "ftd_risk_7057",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-gold-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                login: 7057,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 240,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                    login: 7057,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 0.73,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:40.126Z",
                    identifier: null,
                    last_login: "2021-09-16T07:21:43.000Z",
                    lead_source: "",
                    prev_equity: 0.73,
                    prev_balance: 0.73,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-10-22T07:15:51.000Z",
                    prevmonth_equity: 0.73,
                    prevmonth_balance: 0.73,
                },
                register_time: "2020-10-22T07:15:51.000+00:00",
            },
            value: {
                ActualPL: 0.73,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 910.73,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 354.74,
                PLLastMonth: -61,
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -0.73,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 298,
                Withdrawable: 0.73,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 0.73,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -61,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 0.73,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -910,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 0.73,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: -9710,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 910.73,
                "%ProfitableTotalTrade": 74.49664429530202,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 0.73,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 74.49664429530202,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 222,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 0.73,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 0.73,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: 1910.7300000000005,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 9771.73,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -910,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: 1088.3500000000001,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 0.73,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 72750,
            name: "ftd_risk_7019",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-gold-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                login: 7019,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 235,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                    login: 7019,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 2456.36,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T14:08:03.767Z",
                    identifier: null,
                    last_login: "2021-10-05T13:16:52.000Z",
                    lead_source: "",
                    prev_equity: 2408.36,
                    prev_balance: 2408.36,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-06-01T14:26:39.000Z",
                    prevmonth_equity: 2224.56,
                    prevmonth_balance: 2224.56,
                },
                register_time: "2020-06-01T14:26:39.000+00:00",
            },
            value: {
                ActualPL: 2684.56,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 38737.56,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 0.32,
                PLLastMonth: 2007.2,
                PLThisMonth: 460,
                PLYesterday: -2641.36,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 1,
                Withdrawable: 2684.56,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 2684.56,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 2643.76,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -36053,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 2682.16,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 38735.16,
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 2224.56,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: 457.6,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 2684.56,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 2641.36,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -72.64,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 217.36,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -36053,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 2224.56,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 72759,
            name: "ftd_risk_7014",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-gold-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                login: 7014,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 130,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                    login: 7014,
                    phone: "",
                    state: "",
                    credit: 4000,
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 42742.58,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T14:32:50.349Z",
                    identifier: null,
                    last_login: "2021-10-06T14:31:48.000Z",
                    lead_source: "",
                    prev_equity: 16311.68,
                    prev_balance: 42962.15,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-05-20T08:53:09.000Z",
                    prevmonth_equity: 19673.39,
                    prevmonth_balance: 42538.04,
                },
                register_time: "2020-05-20T08:53:09.000+00:00",
            },
            value: {
                ActualPL: 15410.09,
                OpenLots: 15.45,
                PLOverall: 106203.09,
                FloatingPL: -24435.31,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 695321.8499999999,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 314,
                PLLastMonth: -14527.2,
                PLThisMonth: -263.3,
                PLYesterday: -18973.52,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 1603,
                Withdrawable: 15410.09,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: 4000,
                CurrentEquity: 19637.76,
                MonthlyVolume: 350936.9,
                "#LotsThisMonth": 18.75,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -23967.86,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: 7943.8,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 56,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: 4000,
                TotalFloatingSwap: 467.45,
                WdableAccCurrency: 17673.01,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -90793,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 15410.09,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: -65,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 32,
                InitialMontlhyVolume: 387821.5,
                PLOverallAccCurrency: 106203.09,
                "%ProfitableTotalTrade": 88.18565400843882,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 19673.39,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 91.26637554585153,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 1463,
                PLThisMonthAccCurrency: -263.3,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: 4000,
                CurrentCreditAccCurrency: 4000,
                CurrentEquityAccCurrency: 19410.09,
                DayBeforeYesterdayCredit: 4000,
                DayBeforeYesterdayEquity: 22973.52,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -427.82875,
                ClosedPLOverallBeforeToday: 36943.13999999999,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 30265.59,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 350936.9,
                ClosedPLThisMonthBeforeToday: 211.22000000000003,
                DayBeforeYesterdayFloatingPL: -24183.739999999998,
                FloatingPLEndOfPreviousMonth: -26864.65,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -90793,
                ClosedLotsThisMonthBeforeToday: 18.75,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: -3414.91,
                ClosedPLThisYearBeforeThisMonth: 35557.78999999999,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: 6.600625,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 19673.39,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: 4000,
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 72763,
            name: "ftd_risk_7008",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-gold-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                login: 7008,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 231,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                    login: 7008,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 338602.4,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T12:57:46.987Z",
                    identifier: null,
                    last_login: "2021-10-06T10:04:09.000Z",
                    lead_source: "",
                    prev_equity: 335552.72,
                    prev_balance: 331695.88,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-05-14T04:45:45.000Z",
                    prevmonth_equity: 317155.15,
                    prevmonth_balance: 329087.84,
                },
                register_time: "2020-05-14T04:45:45.000+00:00",
            },
            value: {
                ActualPL: 341869.22,
                OpenLots: 32.05,
                PLOverall: -258249.78,
                FloatingPL: -15488.519999999999,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 1739927.02,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 450.57,
                PLLastMonth: 20039.41,
                PLThisMonth: 24714.07,
                PLYesterday: -337917.62,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 519,
                Withdrawable: 341869.22,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 332909.72,
                MonthlyVolume: 1408908.8,
                "#LotsThisMonth": 16.48,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -15915.32,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: 24550.730000000003,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 9,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: -426.8,
                WdableAccCurrency: 341869.22,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 600119,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 341869.22,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 25,
                InitialMontlhyVolume: 2795053.28,
                PLOverallAccCurrency: -258249.78,
                "%ProfitableTotalTrade": 52.083333333333336,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 317155.15,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 52.98651252408478,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 275,
                PLThisMonthAccCurrency: 24714.07,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 341869.22,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 337917.62,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -1766.0422222222223,
                ClosedPLOverallBeforeToday: -242947.82000000007,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 297115.74,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 1408908.8000000003,
                ClosedPLThisMonthBeforeToday: 28083.34,
                DayBeforeYesterdayFloatingPL: -19253.56,
                FloatingPLEndOfPreviousMonth: -11932.69,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 600119,
                ClosedLotsThisMonthBeforeToday: 16.48,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: -7421.37,
                ClosedPLThisYearBeforeThisMonth: 29087.82,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: 1123.3336,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 317155.15,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 72769,
            name: "ftd_risk_7076",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-gold-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                login: 7076,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 241,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\Gold Pro\\1:100",
                    login: 7076,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 59055.55,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T13:07:58.815Z",
                    identifier: null,
                    last_login: "2021-10-06T13:07:56.000Z",
                    lead_source: "",
                    prev_equity: 40422.63,
                    prev_balance: 58728.97,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-12-14T08:10:09.000Z",
                    prevmonth_equity: 36470.22,
                    prevmonth_balance: 58360.05,
                },
                register_time: "2020-12-14T08:10:09.000+00:00",
            },
            value: {
                ActualPL: 35652.64,
                OpenLots: 20,
                PLOverall: 43252.64,
                FloatingPL: -24524.77,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 2041170,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 3582.1,
                PLLastMonth: 26250.44,
                PLThisMonth: -817.58,
                PLYesterday: -31661.41,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 1428,
                Withdrawable: 35652.64,
                "%ChangeEquity": 100,
                ClosedPLToday: 60.07,
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 32012.05,
                MonthlyVolume: 1043494,
                "#LotsThisMonth": 32,
                ClosedLotsToday: 2,
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -23522.4,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: 46829.06,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: 232155.99999999997,
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 6,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: 1002.37,
                WdableAccCurrency: 35652.64,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -7600,
                TotalClosedPLToday: 60.07,
                ActualPLAccCurrency: 35652.64,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: -26500,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 11,
                InitialMontlhyVolume: 3617090.497496,
                PLOverallAccCurrency: 43252.64,
                "%ProfitableTotalTrade": 95.95536959553696,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 36470.22,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 96.35854341736695,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 1376,
                PLThisMonthAccCurrency: -817.58,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 35652.64,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 31661.41,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -3920.4,
                ClosedPLOverallBeforeToday: 46632.33999999999,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 36719.78,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 811338,
                ClosedPLThisMonthBeforeToday: 717.29,
                DayBeforeYesterdayFloatingPL: -27415.93,
                FloatingPLEndOfPreviousMonth: -21889.829999999998,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -7600,
                ClosedLotsThisMonthBeforeToday: 30,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: -1311.21,
                ClosedPLThisYearBeforeThisMonth: 59913.579999999994,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: 70.66909090909091,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 36470.22,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 73758,
            name: "ftd_risk_7000",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-ecn-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\ECN\\1:100",
                login: 7000,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 200,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\ECN\\1:100",
                    login: 7000,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 32.96,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:38.358Z",
                    identifier: null,
                    last_login: "2021-04-02T10:13:05.000Z",
                    lead_source: "",
                    prev_equity: 32.96,
                    prev_balance: 32.96,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-04-30T15:36:12.000Z",
                    prevmonth_equity: 32.96,
                    prevmonth_balance: 32.96,
                },
                register_time: "2020-04-30T15:36:12.000+00:00",
            },
            value: {
                ActualPL: 32.96,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -146290.04,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 39656.18,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -32.96,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 3570,
                Withdrawable: 32.96,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 32.96,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: -30160.6,
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 32.96,
                CommissionThisYear: -6200.310000000001,
                NetDepositsOverall: 146323,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 32.96,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -146290.04,
                "%ProfitableTotalTrade": 76.38655462184875,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 32.96,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 76.38655462184875,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 2727,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 32.96,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 32.96,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -101779.37999999996,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 32.96,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 146323,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -33436.320000000014,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: -30160.600000000002,
                EquityBeforeThisMonthAccCurrency: 32.96,
                CommissionThisYearBeforeThisMonth: -6200.310000000001,
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 73760,
            name: "ftd_risk_7026",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-ecn-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\ECN\\1:100",
                login: 7026,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 201,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\ECN\\1:100",
                    login: 7026,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 2.33,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:38.401Z",
                    identifier: null,
                    last_login: "2021-04-01T20:24:36.000Z",
                    lead_source: "",
                    prev_equity: 2.33,
                    prev_balance: 2.33,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-06-23T09:12:27.000Z",
                    prevmonth_equity: 2.33,
                    prevmonth_balance: 2.33,
                },
                register_time: "2020-06-23T09:12:27.000+00:00",
            },
            value: {
                ActualPL: 2.33,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 152.33,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 140.99,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -2.33,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 461,
                Withdrawable: 2.33,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 2.33,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: -532.91,
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 2.33,
                CommissionThisYear: -66.40999999999998,
                NetDepositsOverall: -150,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 2.33,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 152.33,
                "%ProfitableTotalTrade": 72.45119305856834,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 2.33,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 72.45119305856834,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 334,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 2.33,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 2.33,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: 1951.9399999999998,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 2.33,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -150,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -1804.3799999999994,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: -532.91,
                EquityBeforeThisMonthAccCurrency: 2.33,
                CommissionThisYearBeforeThisMonth: -66.40999999999998,
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 73765,
            name: "ftd_risk_7068",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-ecn-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\ECN\\1:100",
                login: 7068,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 202,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\ECN\\1:100",
                    login: 7068,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: -33.06,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:38.447Z",
                    identifier: null,
                    last_login: "2021-08-12T08:55:58.000Z",
                    lead_source: "",
                    prev_equity: -33.06,
                    prev_balance: -33.06,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-12-02T08:27:50.000Z",
                    prevmonth_equity: -33.06,
                    prevmonth_balance: -33.06,
                },
                register_time: "2020-12-02T08:27:50.000+00:00",
            },
            value: {
                ActualPL: -33.06,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -7033.06,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 140.09,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: 33.06,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 396,
                Withdrawable: -33.06,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: -33.06,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: -553.7100000000002,
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: -33.06,
                CommissionThisYear: -477.4300000000001,
                NetDepositsOverall: 7000,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: -33.06,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -7033.06,
                "%ProfitableTotalTrade": 74.74747474747475,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: -33.06,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 74.74747474747475,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 296,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: -33.06,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: -33.06,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -8921.47,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: -33.06,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 7000,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -10479.99,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: -553.7100000000002,
                EquityBeforeThisMonthAccCurrency: -33.06,
                CommissionThisYearBeforeThisMonth: -477.43000000000006,
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 73767,
            name: "ftd_risk_7070",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-kc-private-ecn-1-100"],
                group: "Natro\\Europe\\Turkey\\KC Private\\ECN\\1:100",
                login: 7070,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 203,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\KC Private\\ECN\\1:100",
                    login: 7070,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 216099.11,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:38.492Z",
                    identifier: null,
                    last_login: "2021-04-02T07:02:31.000Z",
                    lead_source: "",
                    prev_equity: 425157.11,
                    prev_balance: 216099.11,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-12-06T21:03:39.000Z",
                    prevmonth_equity: 552971.11,
                    prevmonth_balance: 216099.11,
                },
                register_time: "2020-12-06T21:03:39.000+00:00",
            },
            value: {
                ActualPL: 417731.07,
                OpenLots: 95,
                PLOverall: 60909.07,
                FloatingPL: 174255,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 12549610,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 581.77,
                PLLastMonth: 244638.37,
                PLThisMonth: -135240.04,
                PLYesterday: -435188.07,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 101,
                Withdrawable: 417731.07,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 433431.07,
                MonthlyVolume: 452350,
                "#LotsThisMonth": 20,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: 168679,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -138271.5,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: -2853.469999999999,
                CountOfOpenTrades: 13,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: -5576,
                WdableAccCurrency: 417731.07,
                CommissionThisYear: -2654.63,
                NetDepositsOverall: 356822,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 417731.07,
                CommissionThisMonth: -9.04,
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: 100000,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 2,
                InitialMontlhyVolume: 452350,
                PLOverallAccCurrency: 60909.07,
                "%ProfitableTotalTrade": 52.63157894736842,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 552971.11,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 59.40594059405941,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 60,
                PLThisMonthAccCurrency: -135240.04,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 417731.07,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 435188.07,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: 12992,
                ClosedPLOverallBeforeToday: -103754.75,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 208332.74,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 452350,
                ClosedPLThisMonthBeforeToday: 33702,
                DayBeforeYesterdayFloatingPL: 185396,
                FloatingPLEndOfPreviousMonth: 336872,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 356822,
                ClosedLotsThisMonthBeforeToday: 20,
                CommissionThisMonthBeforeToday: -9.04,
                FloatingPLEndOfMonthBeforeLast: -46715.5,
                ClosedPLThisYearBeforeThisMonth: -161363.05,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: 16851,
                CommissionOverallBeforeThisMonth: -2844.429999999999,
                EquityBeforeThisMonthAccCurrency: 552971.11,
                CommissionThisYearBeforeThisMonth: -2645.5899999999997,
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 75900,
            name: "ftd_risk_7064",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-general-standard-1-50"],
                group: "Natro\\Europe\\Turkey\\General\\Standard\\1:50",
                login: 7064,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 199,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\General\\Standard\\1:50",
                    login: 7064,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 241.32,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 50,
                    read_only: false,
                    timestamp: "2021-10-06T11:43:18.756Z",
                    identifier: null,
                    last_login: "2021-10-06T11:42:49.000Z",
                    lead_source: "",
                    prev_equity: 241.32,
                    prev_balance: 241.32,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-11-12T10:37:54.000Z",
                    prevmonth_equity: 241.32,
                    prevmonth_balance: 241.32,
                },
                register_time: "2020-11-12T10:37:54.000+00:00",
            },
            value: {
                ActualPL: 241.32,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -24758.68,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 92.85,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -241.32,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 54,
                Withdrawable: 241.32,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 241.32,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 241.32,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 25000,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 241.32,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -24758.68,
                "%ProfitableTotalTrade": 59.25925925925926,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 241.32,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 59.25925925925926,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 32,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 241.32,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 241.32,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -24758.68,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 241.32,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 25000,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 241.32,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 77436,
            name: "ftd_risk_7001",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-general-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                login: 7001,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 181,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                    login: 7001,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 2.24,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:37.487Z",
                    identifier: null,
                    last_login: "2021-02-09T11:10:22.000Z",
                    lead_source: "",
                    prev_equity: 2.24,
                    prev_balance: 2.24,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-05-01T13:17:00.000Z",
                    prevmonth_equity: 2.24,
                    prevmonth_balance: 2.24,
                },
                register_time: "2020-05-01T13:17:00.000+00:00",
            },
            value: {
                ActualPL: 2.24,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -1000097.76,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 609.77,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -2.24,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 140,
                Withdrawable: 2.24,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 2.24,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 2.24,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 1000100,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 2.24,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -1000097.76,
                "%ProfitableTotalTrade": 67.85714285714286,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 2.24,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 67.85714285714286,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 95,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 2.24,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 2.24,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -51133.70999999999,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 2.24,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 1000100,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 2.24,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 77441,
            name: "ftd_risk_7003",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-general-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                login: 7003,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 183,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                    login: 7003,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 120.82,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:37.573Z",
                    identifier: null,
                    last_login: "2021-04-17T13:19:23.000Z",
                    lead_source: "",
                    prev_equity: 120.82,
                    prev_balance: 120.82,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-05-01T13:44:03.000Z",
                    prevmonth_equity: 120.82,
                    prevmonth_balance: 120.82,
                },
                register_time: "2020-05-01T13:44:03.000+00:00",
            },
            value: {
                ActualPL: 120.82,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 20.82,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 4.25,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -120.82,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 133,
                Withdrawable: 120.82,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 120.82,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 120.82,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 100,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 120.82,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 20.82,
                "%ProfitableTotalTrade": 79.69924812030075,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 120.82,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 79.69924812030075,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 106,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 120.82,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 120.82,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: 20.819999999999986,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 120.82,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 100,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 120.82,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 77449,
            name: "ftd_risk_7004",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-general-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                login: 7004,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 184,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                    login: 7004,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 2.32,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:37.616Z",
                    identifier: null,
                    last_login: "2021-09-15T08:17:48.000Z",
                    lead_source: "",
                    prev_equity: 2.32,
                    prev_balance: 2.32,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-05-01T13:47:52.000Z",
                    prevmonth_equity: 2.32,
                    prevmonth_balance: 2.32,
                },
                register_time: "2020-05-01T13:47:52.000+00:00",
            },
            value: {
                ActualPL: 2.32,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -2097.68,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 4.75,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -2.32,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 281,
                Withdrawable: 2.32,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 2.32,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 2.32,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 2100,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 2.32,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -2097.68,
                "%ProfitableTotalTrade": 74.73309608540926,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 2.32,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 74.73309608540926,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 210,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 2.32,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 2.32,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -108.68000000000002,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 2.32,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 2100,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 2.32,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 77451,
            name: "ftd_risk_7006",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-general-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                login: 7006,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 185,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                    login: 7006,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 0.37,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:37.664Z",
                    identifier: null,
                    last_login: "2021-10-06T08:18:46.000Z",
                    lead_source: "",
                    prev_equity: 0.37,
                    prev_balance: 0.37,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-05-06T11:35:35.000Z",
                    prevmonth_equity: 0.37,
                    prevmonth_balance: 0.37,
                },
                register_time: "2020-05-06T11:35:35.000+00:00",
            },
            value: {
                ActualPL: 0.37,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -56055.63,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 166.17,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -0.37,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 782,
                Withdrawable: 0.37,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 0.37,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 0.37,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 56056,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 0.37,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -56055.63,
                "%ProfitableTotalTrade": 85.80562659846548,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 0.37,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 85.80562659846548,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 671,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 0.37,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 0.37,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -56055.62999999999,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 0.37,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 56056,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -57408.64999999998,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 0.37,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 77458,
            name: "ftd_risk_7007",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-general-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                login: 7007,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 186,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                    login: 7007,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 7.41,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:37.714Z",
                    identifier: null,
                    last_login: "2021-10-04T05:57:05.000Z",
                    lead_source: "",
                    prev_equity: 7.41,
                    prev_balance: 7.41,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-05-08T18:57:28.000Z",
                    prevmonth_equity: 7.41,
                    prevmonth_balance: 7.41,
                },
                register_time: "2020-05-08T18:57:28.000+00:00",
            },
            value: {
                ActualPL: 7.41,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -71.09,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 6.11,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -7.41,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 73,
                Withdrawable: 7.41,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 7.41,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 7.41,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 78.5,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 7.41,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -71.09,
                "%ProfitableTotalTrade": 67.12328767123287,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 7.41,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 67.12328767123287,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 49,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 7.41,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 7.41,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -71.09,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 7.41,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 78.5,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 7.41,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 77464,
            name: "ftd_risk_7065",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-general-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                login: 7065,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 188,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                    login: 7065,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 733069.83,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:37.813Z",
                    identifier: null,
                    last_login: "2021-03-29T07:02:27.000Z",
                    lead_source: "",
                    prev_equity: 328633.33,
                    prev_balance: 733159.83,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-11-16T11:48:26.000Z",
                    prevmonth_equity: 304050.59,
                    prevmonth_balance: 609713.92,
                },
                register_time: "2020-11-16T11:48:26.000+00:00",
            },
            value: {
                ActualPL: 290214.1,
                OpenLots: 251.64,
                PLOverall: -819785.9,
                FloatingPL: -288860.52,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 5718530.4,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 4306.3,
                PLLastMonth: -305753,
                PLThisMonth: -163836.49,
                PLYesterday: -317597.44,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 517,
                Withdrawable: 290214.1,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 289860.58,
                MonthlyVolume: 6896047.8,
                "#LotsThisMonth": 58.2,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -348805.9,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -48485.07000000001,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 30,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: 150000,
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: -59945.38,
                WdableAccCurrency: 290214.1,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 1110000,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 290214.1,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: 200000,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 20,
                InitialMontlhyVolume: 7459391.8,
                PLOverallAccCurrency: -819785.9,
                "%ProfitableTotalTrade": 55.575868372943326,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 304050.59,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 58.80077369439071,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 304,
                PLThisMonthAccCurrency: -163836.49,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 290214.1,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 317597.44,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -11592.724,
                ClosedPLOverallBeforeToday: -664659.32,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 409803.59,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 6896047.8,
                ClosedPLThisMonthBeforeToday: -114373.23999999999,
                DayBeforeYesterdayFloatingPL: -327743.24,
                FloatingPLEndOfPreviousMonth: -305663.33,
                MonthlyNetDepositAccCurrency: 150000,
                NetDepositsOverallAccCurrency: 1110000,
                ClosedLotsThisMonthBeforeToday: 58.2,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: -48395.4,
                ClosedPLThisYearBeforeThisMonth: -539181.0499999999,
                NetDepositsThisMonthBeforeToday: 150000,
                AVGProfitPerClosedTradeThisMonth: -5718.662,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 304050.59,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: 150000,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 77469,
            name: "ftd_risk_7066",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-general-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                login: 7066,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 189,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                    login: 7066,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 0.03,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:37.862Z",
                    identifier: null,
                    last_login: "2021-04-16T18:33:45.000Z",
                    lead_source: "",
                    prev_equity: 0.03,
                    prev_balance: 0.03,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-11-17T09:34:31.000Z",
                    prevmonth_equity: 0.03,
                    prevmonth_balance: 0.03,
                },
                register_time: "2020-11-17T09:34:31.000+00:00",
            },
            value: {
                ActualPL: 0.03,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 100000.03,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 235.95,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -0.03,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 51,
                Withdrawable: 0.03,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 0.03,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 0.03,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -100000,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 0.03,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 100000.03,
                "%ProfitableTotalTrade": 82.3529411764706,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 0.03,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 82.3529411764706,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 42,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 0.03,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 0.03,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: 100000.03,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 0.03,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -100000,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 0.03,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 77474,
            name: "ftd_risk_7082",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-general-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                login: 7082,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 190,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                    login: 7082,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 0.83,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:37.906Z",
                    identifier: null,
                    last_login: "2021-01-13T08:14:49.000Z",
                    lead_source: "",
                    prev_equity: 0.83,
                    prev_balance: 0.83,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-01-10T10:36:38.000Z",
                    prevmonth_equity: 0.83,
                    prevmonth_balance: 0.83,
                },
                register_time: "2021-01-10T10:36:38.000+00:00",
            },
            value: {
                ActualPL: 0.83,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 405.83,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -0.83,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: 0.83,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 0.83,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 0.83,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -405,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 0.83,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 405.83,
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 0.83,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 0.83,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 0.83,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 0.83,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -405,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 0.83,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 77477,
            name: "ftd_risk_7087",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-general-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                login: 7087,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 192,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                    login: 7087,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 355283.59,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:37.996Z",
                    identifier: null,
                    last_login: "2021-04-20T09:55:22.000Z",
                    lead_source: "",
                    prev_equity: 304633.61,
                    prev_balance: 355283.59,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-03-10T20:45:37.000Z",
                    prevmonth_equity: 300679.31,
                    prevmonth_balance: 355283.59,
                },
                register_time: "2021-03-10T20:45:37.000+00:00",
            },
            value: {
                ActualPL: 303670.41,
                OpenLots: 14,
                PLOverall: -154887.59,
                FloatingPL: -46533,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 2478588,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 226,
                PLLastMonth: -81216.8,
                PLThisMonth: 2991.1,
                PLYesterday: -300341.21,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 21,
                Withdrawable: 303670.41,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 294780.41,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -52159.18,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 4,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: -5626.18,
                WdableAccCurrency: 303670.41,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 458558,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 303670.41,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -154887.59,
                "%ProfitableTotalTrade": 36,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 300679.31,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 42.857142857142854,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 9,
                PLThisMonthAccCurrency: 2991.1,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 303670.41,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 300341.21,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -13025.095,
                ClosedPLOverallBeforeToday: -103274.41,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 381896.11,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: -54942.38,
                FloatingPLEndOfPreviousMonth: -54604.28,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 458558,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: 26612.52,
                ClosedPLThisYearBeforeThisMonth: -103274.41,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 300679.31,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 77485,
            name: "ftd_risk_7088",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-general-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                login: 7088,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 193,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                    login: 7088,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 284656.95,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:38.041Z",
                    identifier: null,
                    last_login: "2021-03-23T09:06:51.000Z",
                    lead_source: "",
                    prev_equity: 438187.95,
                    prev_balance: 284656.95,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-03-23T09:06:51.000Z",
                    prevmonth_equity: 448172.95,
                    prevmonth_balance: 284656.95,
                },
                register_time: "2021-03-23T09:06:51.000+00:00",
            },
            value: {
                ActualPL: 451257.95,
                OpenLots: 50,
                PLOverall: 214442.95,
                FloatingPL: 163255,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 5803900,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 100,
                PLLastMonth: 116700,
                PLThisMonth: 3085,
                PLYesterday: -449337.95,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 16,
                Withdrawable: 451257.95,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 458257.95,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: 165451,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 7,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: 2196,
                WdableAccCurrency: 451257.95,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 236815,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 451257.95,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 214442.95,
                "%ProfitableTotalTrade": 30.434782608695652,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 448172.95,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 43.75,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 7,
                PLThisMonthAccCurrency: 3085,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 451257.95,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 449337.95,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: 23633,
                ClosedPLOverallBeforeToday: 47841.95,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 331472.95,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: 164681,
                FloatingPLEndOfPreviousMonth: 163516,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 236815,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: 46816,
                ClosedPLThisYearBeforeThisMonth: 47841.95,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 448172.95,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 77493,
            name: "ftd_risk_7089",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-general-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                login: 7089,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 194,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                    login: 7089,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 423880.48,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:38.082Z",
                    identifier: null,
                    last_login: "2021-03-23T13:13:58.000Z",
                    lead_source: "",
                    prev_equity: 515110.48,
                    prev_balance: 423880.48,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-03-23T13:13:58.000Z",
                    prevmonth_equity: 543051.18,
                    prevmonth_balance: 386612.68,
                },
                register_time: "2021-03-23T13:13:58.000+00:00",
            },
            value: {
                ActualPL: 517977.88,
                OpenLots: 66,
                PLOverall: 47027.88,
                FloatingPL: 78039,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 3937408,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 284,
                PLLastMonth: 107844.0000000001,
                PLThisMonth: -25073.3000000001,
                PLYesterday: -520891.48,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 41,
                Withdrawable: 517977.88,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 530689.88,
                MonthlyVolume: 577709.9999999999,
                "#LotsThisMonth": 19,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: 78837.3,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 7,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: 798.3,
                WdableAccCurrency: 517977.88,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 470950,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 517977.88,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 9,
                InitialMontlhyVolume: 2222962,
                PLOverallAccCurrency: 47027.88,
                "%ProfitableTotalTrade": 45.833333333333336,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 543051.18,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 53.65853658536585,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 22,
                PLThisMonthAccCurrency: -25073.3000000001,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 517977.88,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 520891.48,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: 11260.985714285713,
                ClosedPLOverallBeforeToday: -31735.420000000006,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 435207.18,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 577709.9999999999,
                ClosedPLThisMonthBeforeToday: 52601.9,
                DayBeforeYesterdayFloatingPL: 81676.9,
                FloatingPLEndOfPreviousMonth: 156438.5,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 470950,
                ClosedLotsThisMonthBeforeToday: 19,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: 48594.5,
                ClosedPLThisYearBeforeThisMonth: -84337.32,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: 5844.655555555555,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 543051.18,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 77496,
            name: "ftd_risk_7091",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-general-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                login: 7091,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 196,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                    login: 7091,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 1366711,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:38.171Z",
                    identifier: null,
                    last_login: "2021-03-26T12:32:40.000Z",
                    lead_source: "",
                    prev_equity: 1318013.1,
                    prev_balance: 1366711,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-03-26T12:32:40.000Z",
                    prevmonth_equity: 1408152,
                    prevmonth_balance: 1366711,
                },
                register_time: "2021-03-26T12:32:40.000+00:00",
            },
            value: {
                ActualPL: 1302979.26,
                OpenLots: 224.28,
                PLOverall: -207020.74,
                FloatingPL: -15302.560000000001,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 8447441.68,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 207.11,
                PLLastMonth: 197524,
                PLThisMonth: -105172.74,
                PLYesterday: -1320794.64,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 43,
                Withdrawable: 1302979.26,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 1306250.22,
                MonthlyVolume: 3328406.24,
                "#LotsThisMonth": 21.11,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -67852.32,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: 105403.5,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 21,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: -52549.76,
                WdableAccCurrency: 1302979.26,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 1510000,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 1302979.26,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 11,
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -207020.74,
                "%ProfitableTotalTrade": 37.5,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 1408152,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 55.81395348837209,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 24,
                PLThisMonthAccCurrency: -105172.74,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 1302979.26,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 1320794.64,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -3195.726666666666,
                ClosedPLOverallBeforeToday: -135600.06,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 1210628,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 3328406.24,
                ClosedPLThisMonthBeforeToday: 7688.9400000000005,
                DayBeforeYesterdayFloatingPL: -53605.299999999996,
                FloatingPLEndOfPreviousMonth: 41441,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 1510000,
                ClosedLotsThisMonthBeforeToday: 21.11,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: -50679.5,
                ClosedPLThisYearBeforeThisMonth: -143289,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: 698.9945454545456,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 1408152,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 77503,
            name: "ftd_risk_7111",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-general-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                login: 7111,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 198,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                    login: 7111,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 551436,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:38.264Z",
                    identifier: null,
                    last_login: "2021-09-20T12:22:22.000Z",
                    lead_source: "",
                    prev_equity: 544833,
                    prev_balance: 551436,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-09-20T12:22:22.000Z",
                    prevmonth_equity: 556521,
                    prevmonth_balance: 551436,
                },
                register_time: "2021-09-20T12:22:22.000+00:00",
            },
            value: {
                ActualPL: 542779.65,
                OpenLots: 49.29,
                PLOverall: -8656.35,
                FloatingPL: 7193.53,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 5441874.51,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 15.38,
                PLLastMonth: 5085,
                PLThisMonth: -13741.35,
                PLYesterday: -541746.65,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 4,
                Withdrawable: 542779.65,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 550493.48,
                MonthlyVolume: 1170058.04,
                "#LotsThisMonth": 15.38,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: 7202.19,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 15,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: 8.66,
                WdableAccCurrency: 537679.4,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 551436,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 537679.4,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: 551436,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 4,
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -13756.6,
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 556521,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: -18841.6,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 542779.65,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 541746.65,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: 480.146,
                ClosedPLOverallBeforeToday: -15858.54,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 1170058.04,
                ClosedPLThisMonthBeforeToday: -15858.54,
                DayBeforeYesterdayFloatingPL: 6169.19,
                FloatingPLEndOfPreviousMonth: 5085,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 551436,
                ClosedLotsThisMonthBeforeToday: 15.38,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: -3964.635,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 556521,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 77508,
            name: "ftd_risk_7092",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-general-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                login: 7092,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 197,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                    login: 7092,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 7312.13,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T14:35:07.346Z",
                    identifier: null,
                    last_login: "2021-10-06T14:35:07.000Z",
                    lead_source: "",
                    prev_equity: 3739.13,
                    prev_balance: 23745.48,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-04-26T12:06:20.000Z",
                    prevmonth_equity: 4739.13,
                    prevmonth_balance: 48540.53,
                },
                register_time: "2021-04-26T12:06:20.000+00:00",
            },
            value: {
                ActualPL: 1429.63,
                OpenLots: 5,
                PLOverall: -140708.37,
                FloatingPL: -5150,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 41360,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 95,
                PLLastMonth: -25319.36,
                PLThisMonth: -3309.5,
                PLYesterday: -1922.13,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 15,
                Withdrawable: 1429.63,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 1124.63,
                MonthlyVolume: 579830,
                "#LotsThisMonth": 10,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -5762.5,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: 644.17,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 1,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: -612.5,
                WdableAccCurrency: 1429.63,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 142138,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 1429.63,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: 10000,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 2,
                InitialMontlhyVolume: 1159660,
                PLOverallAccCurrency: -140708.37,
                "%ProfitableTotalTrade": 50,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 4739.13,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 53.333333333333336,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 8,
                PLThisMonthAccCurrency: -3309.5,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 1429.63,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 1922.13,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -5740,
                ClosedPLOverallBeforeToday: -134825.87,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 20058.49,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 579830,
                ClosedPLThisMonthBeforeToday: -41228.399999999994,
                DayBeforeYesterdayFloatingPL: -5390,
                FloatingPLEndOfPreviousMonth: -43801.4,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 142138,
                ClosedLotsThisMonthBeforeToday: 10,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: -17837.87,
                ClosedPLThisYearBeforeThisMonth: -93597.47,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: -20614.199999999993,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 4739.13,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 77513,
            name: "ftd_risk_7002",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-general-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                login: 7002,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 182,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                    login: 7002,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 1.3900000000000001,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T14:27:11.457Z",
                    identifier: null,
                    last_login: "2021-10-06T14:26:24.000Z",
                    lead_source: "",
                    prev_equity: 1.3900000000000001,
                    prev_balance: 1.3900000000000001,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-05-01T13:36:03.000Z",
                    prevmonth_equity: 1.3900000000000001,
                    prevmonth_balance: 1.3900000000000001,
                },
                register_time: "2020-05-01T13:36:03.000+00:00",
            },
            value: {
                ActualPL: 1.39,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -98.61,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 3.06,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -1.39,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 178,
                Withdrawable: 1.39,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 1.3900000000000001,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 1.39,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 100,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 1.39,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -98.61,
                "%ProfitableTotalTrade": 67.97752808988764,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 1.3900000000000001,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 67.97752808988764,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 121,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 1.3900000000000001,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 1.3900000000000001,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -98.61000000000003,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 1.3900000000000001,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 100,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 1.3900000000000001,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 77523,
            name: "ftd_risk_7063",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-general-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                login: 7063,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 187,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                    login: 7063,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 30553.45,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T13:46:27.717Z",
                    identifier: null,
                    last_login: "2021-10-06T06:28:08.000Z",
                    lead_source: "",
                    prev_equity: 30623.99,
                    prev_balance: 30553.45,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-11-11T05:26:12.000Z",
                    prevmonth_equity: 30752.25,
                    prevmonth_balance: 30719.07,
                },
                register_time: "2020-11-11T05:26:12.000+00:00",
            },
            value: {
                ActualPL: 31286.64,
                OpenLots: 5.41,
                PLOverall: 401286.64,
                FloatingPL: 396.51,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 395825.98,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 237.47,
                PLLastMonth: 52537.4,
                PLThisMonth: 534.39,
                PLYesterday: -31021.48,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 221,
                Withdrawable: 31286.64,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 31523.82,
                MonthlyVolume: 118108.5,
                "#LotsThisMonth": 2.57,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: 547.37,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: 55582.020000000004,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 11,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: 6.37,
                WdableAccCurrency: 31152.42,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -370000,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 31152.42,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: -40000,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 20,
                InitialMontlhyVolume: 253536.69999999998,
                PLOverallAccCurrency: 401152.42,
                "%ProfitableTotalTrade": 79.56521739130434,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 30752.25,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 82.8054298642534,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 183,
                PLThisMonthAccCurrency: 400.17,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 31286.64,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 31021.48,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: 60.818888888888885,
                ClosedPLOverallBeforeToday: 310891.4900000001,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 18214.85,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 118108.5,
                ClosedPLThisMonthBeforeToday: 172.42000000000002,
                DayBeforeYesterdayFloatingPL: 129.99,
                FloatingPLEndOfPreviousMonth: 33.18,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -370000,
                ClosedLotsThisMonthBeforeToday: 2.57,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: 3077.8,
                ClosedPLThisYearBeforeThisMonth: 317486.03,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: 8.621,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 30752.25,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 77526,
            name: "ftd_risk_7086",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-general-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                login: 7086,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 191,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                    login: 7086,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 533824.19,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T13:57:54.763Z",
                    identifier: null,
                    last_login: "2021-04-13T19:06:07.000Z",
                    lead_source: "",
                    prev_equity: 531762.62,
                    prev_balance: 537245.28,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-03-04T13:05:39.000Z",
                    prevmonth_equity: 534992.89,
                    prevmonth_balance: 536214.12,
                },
                register_time: "2021-03-04T13:05:39.000+00:00",
            },
            value: {
                ActualPL: 518851.46,
                OpenLots: 5.97,
                PLOverall: -281148.54,
                FloatingPL: -3236.55,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 597000,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 522.59,
                PLLastMonth: -140960.7999999999,
                PLThisMonth: -16141.43,
                PLYesterday: -517443.12,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 82,
                Withdrawable: 518851.46,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 519824.22,
                MonthlyVolume: 1850000,
                "#LotsThisMonth": 19.7,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -2711.91,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -101619.57999999999,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 2,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: 524.64,
                WdableAccCurrency: 518851.46,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 800000,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 518851.46,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 12,
                InitialMontlhyVolume: 970000,
                PLOverallAccCurrency: -281148.54,
                "%ProfitableTotalTrade": 41.666666666666664,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 534992.89,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 42.68292682926829,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 35,
                PLThisMonthAccCurrency: -16141.43,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 518851.46,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 517443.12,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -1415.735,
                ClosedPLOverallBeforeToday: -275714.77999999997,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 675953.69,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 1850000,
                ClosedPLThisMonthBeforeToday: -11928.9,
                DayBeforeYesterdayFloatingPL: -6842.1,
                FloatingPLEndOfPreviousMonth: -1221.23,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 800000,
                ClosedLotsThisMonthBeforeToday: 19.7,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: 38119.990000000005,
                ClosedPLThisYearBeforeThisMonth: -263785.87999999995,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: -994.075,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 534992.89,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 77540,
            name: "ftd_risk_7090",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-general-pro-1-100"],
                group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                login: 7090,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 195,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\General\\Pro\\1:100",
                    login: 7090,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 0.12,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T14:36:50.946Z",
                    identifier: null,
                    last_login: "2021-10-06T14:36:50.000Z",
                    lead_source: "",
                    prev_equity: 5448.12,
                    prev_balance: 5448.12,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-03-24T11:04:36.000Z",
                    prevmonth_equity: 5448.12,
                    prevmonth_balance: 5448.12,
                },
                register_time: "2021-03-24T11:04:36.000+00:00",
            },
            value: {
                ActualPL: 0.12,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 448.12,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 12.3,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -0.12,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 75,
                Withdrawable: 0.12,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 0.12,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: -5448,
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 0.12,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -448,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 0.12,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 448.12,
                "%ProfitableTotalTrade": 81.33333333333333,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 5448.12,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 81.33333333333333,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 61,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 0.12,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 0.12,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: 448.12000000000006,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 5448.12,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: -5448,
                NetDepositsOverallAccCurrency: -448,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: 448.12000000000006,
                NetDepositsThisMonthBeforeToday: -5448,
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 5448.12,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: -5448,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 77725,
            name: "ftd_risk_7077",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-general-ecn-1-100"],
                group: "Natro\\Europe\\Turkey\\General\\ECN\\1:100",
                login: 7077,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 180,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\General\\ECN\\1:100",
                    login: 7077,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 241352.7,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:37.443Z",
                    identifier: null,
                    last_login: "2021-03-24T09:32:50.000Z",
                    lead_source: "",
                    prev_equity: 261570.7,
                    prev_balance: 241352.7,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-12-18T11:07:32.000Z",
                    prevmonth_equity: 276766,
                    prevmonth_balance: 201935,
                },
                register_time: "2020-12-18T11:07:32.000+00:00",
            },
            value: {
                ActualPL: 259840.7,
                OpenLots: 10,
                PLOverall: 82449.7,
                FloatingPL: 19370,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 1363740,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 120.49,
                PLLastMonth: 51040.55,
                PLThisMonth: -16925.3,
                PLYesterday: -262270.7,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 38,
                Withdrawable: 259840.7,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 261570.7,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": 7,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: 18598,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -14650,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: -700.3899999999999,
                CountOfOpenTrades: 1,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: -772,
                WdableAccCurrency: 259840.7,
                CommissionThisYear: -692.5999999999999,
                NetDepositsOverall: 177391,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 259840.7,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 1,
                InitialMontlhyVolume: 1248800,
                PLOverallAccCurrency: 82449.7,
                "%ProfitableTotalTrade": 71.7948717948718,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 276766,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 73.6842105263158,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 28,
                PLThisMonthAccCurrency: -16925.3,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 259840.7,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 262270.7,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: 18628,
                ClosedPLOverallBeforeToday: 64696.049999999996,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 225725.45,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: 39417.7,
                DayBeforeYesterdayFloatingPL: 20918,
                FloatingPLEndOfPreviousMonth: 74831,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 177391,
                ClosedLotsThisMonthBeforeToday: 7,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: 8980,
                ClosedPLThisYearBeforeThisMonth: 22971.25,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: 39417.7,
                CommissionOverallBeforeThisMonth: -700.3899999999999,
                EquityBeforeThisMonthAccCurrency: 276766,
                CommissionThisYearBeforeThisMonth: -692.5999999999999,
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 77983,
            name: "ftd_risk_7045",
            properties: {
                path: ["natro", "europe-1", "natro-europe-turkey-general-ecn2-1-100"],
                group: "Natro\\Europe\\Turkey\\General\\ECN2\\1:100",
                login: 7045,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 283,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\Europe\\Turkey\\General\\ECN2\\1:100",
                    login: 7045,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:41.881Z",
                    identifier: null,
                    last_login: "2021-04-01T17:03:59.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-08-31T11:18:20.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2020-08-31T11:18:20.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: 150311.91,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 20102.04,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 2572,
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: -38174.96000000004,
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: -4525.429999999999,
                NetDepositsOverall: -150311.90999999997,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 150311.91,
                "%ProfitableTotalTrade": 88.25816485225505,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 88.25816485225505,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 2270,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: 188914.96000000005,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -150311.90999999997,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: 4217.500000000007,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: -38174.96000000004,
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: -4525.429999999999,
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 89124,
            name: "ftd_risk_10000",
            properties: {
                path: ["ftd", "europe", "ftd-europe-turkey-private-ecn-1-100"],
                group: "FTD\\Europe\\Turkey\\Private\\ECN\\1:100",
                login: 10000,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 172,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "FTD\\Europe\\Turkey\\Private\\ECN\\1:100",
                    login: 10000,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 551848.61,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:37.104Z",
                    identifier: null,
                    last_login: "2021-09-24T07:19:35.000Z",
                    lead_source: "",
                    prev_equity: 559405.11,
                    prev_balance: 551848.61,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-04-22T16:44:41.000Z",
                    prevmonth_equity: 512068.58,
                    prevmonth_balance: 551205.38,
                },
                register_time: "2020-04-22T16:44:41.000+00:00",
            },
            value: {
                ActualPL: 547289.66,
                OpenLots: 131,
                PLOverall: 461839.66,
                FloatingPL: 2682,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 11685438,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 2485.5,
                PLLastMonth: -39635.57,
                PLThisMonth: 35221.08,
                PLYesterday: -544133.96,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 3296,
                Withdrawable: 547289.66,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 525530.66,
                MonthlyVolume: 577710,
                "#LotsThisMonth": 9,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -2688.8,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -97.63,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: -9848.65,
                CountOfOpenTrades: 14,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: -5370.8,
                WdableAccCurrency: 547289.66,
                CommissionThisYear: -3191.94,
                NetDepositsOverall: 85450,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 547289.66,
                CommissionThisMonth: -43.91999999999999,
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 2,
                InitialMontlhyVolume: 1041574,
                PLOverallAccCurrency: 461839.66,
                "%ProfitableTotalTrade": 50.96676737160121,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 512068.58,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 51.18325242718446,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 1687,
                PLThisMonthAccCurrency: 35221.08,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 547289.66,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 544133.96,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -158.46428571428572,
                ClosedPLOverallBeforeToday: 52785.21999999962,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 551704.15,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 577710,
                ClosedPLThisMonthBeforeToday: 2014,
                DayBeforeYesterdayFloatingPL: -9041.5,
                FloatingPLEndOfPreviousMonth: -39136.8,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 85450,
                ClosedLotsThisMonthBeforeToday: 9,
                CommissionThisMonthBeforeToday: -43.919999999999995,
                FloatingPLEndOfMonthBeforeLast: 170.46,
                ClosedPLThisYearBeforeThisMonth: 527986.91,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: 1007,
                CommissionOverallBeforeThisMonth: -9804.73,
                EquityBeforeThisMonthAccCurrency: 512068.58,
                CommissionThisYearBeforeThisMonth: -3148.02,
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 89128,
            name: "ftd_risk_10001",
            properties: {
                path: ["ftd", "europe", "ftd-europe-turkey-private-ecn-1-100"],
                group: "FTD\\Europe\\Turkey\\Private\\ECN\\1:100",
                login: 10001,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 173,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "FTD\\Europe\\Turkey\\Private\\ECN\\1:100",
                    login: 10001,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 6470.82,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:37.140Z",
                    identifier: null,
                    last_login: "2020-06-09T12:22:09.000Z",
                    lead_source: "",
                    prev_equity: 6470.82,
                    prev_balance: 6470.82,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-04-23T10:19:57.000Z",
                    prevmonth_equity: 6470.82,
                    prevmonth_balance: 6470.82,
                },
                register_time: "2020-04-23T10:19:57.000+00:00",
            },
            value: {
                ActualPL: 6470.82,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -230344.18,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 150,
                PLLastMonth: -101002.41,
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -6470.82,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 22,
                Withdrawable: 6470.82,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 6470.82,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -165330.8,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: -744.9799999999999,
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 6470.82,
                CommissionThisYear: -744.9799999999999,
                NetDepositsOverall: 236815,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 6470.82,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -230344.18,
                "%ProfitableTotalTrade": 40.90909090909091,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 6470.82,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 40.90909090909091,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 9,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 6470.82,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 6470.82,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -229599.2,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 107473.23,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 236815,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: -64444.6,
                ClosedPLThisYearBeforeThisMonth: -229599.2,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: -744.9799999999999,
                EquityBeforeThisMonthAccCurrency: 6470.82,
                CommissionThisYearBeforeThisMonth: -744.9799999999999,
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 89134,
            name: "ftd_risk_10002",
            properties: {
                path: ["ftd", "europe", "ftd-europe-turkey-private-ecn-1-100"],
                group: "FTD\\Europe\\Turkey\\Private\\ECN\\1:100",
                login: 10002,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 174,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "FTD\\Europe\\Turkey\\Private\\ECN\\1:100",
                    login: 10002,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 150647.45,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:37.183Z",
                    identifier: null,
                    last_login: "2020-08-05T13:07:54.000Z",
                    lead_source: "",
                    prev_equity: 38674.8,
                    prev_balance: 150647.45,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-08-05T13:07:54.000Z",
                    prevmonth_equity: 29603.35,
                    prevmonth_balance: 150647.45,
                },
                register_time: "2020-08-05T13:07:54.000+00:00",
            },
            value: {
                ActualPL: 29487.05,
                OpenLots: 20,
                PLOverall: -131462.95,
                FloatingPL: -63090,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 1390625,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 290,
                PLLastMonth: -130139.09,
                PLThisMonth: -116.3,
                PLYesterday: -28894.65,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 38,
                Withdrawable: 29487.05,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 25897.05,
                MonthlyVolume: 1737385,
                "#LotsThisMonth": 15,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -67242.7,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -65340.4,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: -1268,
                CountOfOpenTrades: 2,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: -4152.7,
                WdableAccCurrency: 29487.05,
                CommissionThisYear: -1268,
                NetDepositsOverall: 160950,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 29487.05,
                CommissionThisMonth: -34.65,
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 3,
                InitialMontlhyVolume: 1159660,
                PLOverallAccCurrency: -131462.95,
                "%ProfitableTotalTrade": 47.5,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 29603.35,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 50,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 19,
                PLThisMonthAccCurrency: -116.3,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 29487.05,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 28894.65,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -33592.55,
                ClosedPLOverallBeforeToday: -62512.249999999985,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 159742.44,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 1737385,
                ClosedPLThisMonthBeforeToday: -53443.049999999996,
                DayBeforeYesterdayFloatingPL: -68275.1,
                FloatingPLEndOfPreviousMonth: -121044.1,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 160950,
                ClosedLotsThisMonthBeforeToday: 15,
                CommissionThisMonthBeforeToday: -34.65,
                FloatingPLEndOfMonthBeforeLast: -56291.7,
                ClosedPLThisYearBeforeThisMonth: -9069.19999999999,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: -17814.35,
                CommissionOverallBeforeThisMonth: -1233.35,
                EquityBeforeThisMonthAccCurrency: 29603.35,
                CommissionThisYearBeforeThisMonth: -1233.35,
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 89139,
            name: "ftd_risk_10004",
            properties: {
                path: ["ftd", "europe", "ftd-europe-turkey-private-ecn-1-100"],
                group: "FTD\\Europe\\Turkey\\Private\\ECN\\1:100",
                login: 10004,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 175,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "FTD\\Europe\\Turkey\\Private\\ECN\\1:100",
                    login: 10004,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 103223.24,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:37.229Z",
                    identifier: null,
                    last_login: "2021-03-24T17:06:04.000Z",
                    lead_source: "",
                    prev_equity: 92565.24,
                    prev_balance: 103223.24,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-12-01T10:35:34.000Z",
                    prevmonth_equity: 73211.39,
                    prevmonth_balance: 139333.14,
                },
                register_time: "2020-12-01T10:35:34.000+00:00",
            },
            value: {
                ActualPL: 94461.24,
                OpenLots: 20,
                PLOverall: -82929.76,
                FloatingPL: -8160,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 1446440,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 235.75,
                PLLastMonth: -26496.74,
                PLThisMonth: 21249.85000000001,
                PLYesterday: -91150.24,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 86,
                Withdrawable: 94461.24,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 93371.24,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": 7,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -9422,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: 26441.2,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: -1054.23,
                CountOfOpenTrades: 2,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: -1262,
                WdableAccCurrency: 94461.24,
                CommissionThisYear: -1048.44,
                NetDepositsOverall: 177391,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 94461.24,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 2,
                InitialMontlhyVolume: 1243557,
                PLOverallAccCurrency: -82929.76,
                "%ProfitableTotalTrade": 76.13636363636364,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 73211.39,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 77.90697674418605,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 67,
                PLThisMonthAccCurrency: 21249.85000000001,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 94461.24,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 91150.24,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -4681.5,
                ClosedPLOverallBeforeToday: -73041.85,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 99708.13,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: -36109.9,
                DayBeforeYesterdayFloatingPL: -12073,
                FloatingPLEndOfPreviousMonth: -66121.75,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 177391,
                ClosedLotsThisMonthBeforeToday: 7,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: -13333.8,
                ClosedPLThisYearBeforeThisMonth: -35689.95000000001,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: -18054.95,
                CommissionOverallBeforeThisMonth: -1054.23,
                EquityBeforeThisMonthAccCurrency: 73211.39,
                CommissionThisYearBeforeThisMonth: -1048.44,
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 89146,
            name: "ftd_risk_10005",
            properties: {
                path: ["ftd", "europe", "ftd-europe-turkey-private-ecn-1-100"],
                group: "FTD\\Europe\\Turkey\\Private\\ECN\\1:100",
                login: 10005,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 176,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "FTD\\Europe\\Turkey\\Private\\ECN\\1:100",
                    login: 10005,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 827071.41,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:37.276Z",
                    identifier: null,
                    last_login: "2021-03-26T12:22:33.000Z",
                    lead_source: "",
                    prev_equity: 728177.87,
                    prev_balance: 827071.41,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-03-26T12:22:33.000Z",
                    prevmonth_equity: 669254.37,
                    prevmonth_balance: 827071.41,
                },
                register_time: "2021-03-26T12:22:33.000+00:00",
            },
            value: {
                ActualPL: 719342.07,
                OpenLots: 172,
                PLOverall: -657.9300000001,
                FloatingPL: -80480,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 9020951,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 240,
                PLLastMonth: -250118.59,
                PLThisMonth: 50087.6999999999,
                PLYesterday: -707373.37,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 34,
                Withdrawable: 719342.07,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 709994.07,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -110971.34,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -84650.6,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: -1319.09,
                CountOfOpenTrades: 20,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: -30491.34,
                WdableAccCurrency: 719342.07,
                CommissionThisYear: -1319.09,
                NetDepositsOverall: 720000,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 719342.07,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -657.9300000001,
                "%ProfitableTotalTrade": 38.888888888888886,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 669254.37,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 61.76470588235294,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 21,
                PLThisMonthAccCurrency: 50087.6999999999,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 719342.07,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 707373.37,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -5522.902,
                ClosedPLOverallBeforeToday: 108390.50000000003,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 919372.96,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: -119698.04000000001,
                FloatingPLEndOfPreviousMonth: -157817.04,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 720000,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: 7483.299999999999,
                ClosedPLThisYearBeforeThisMonth: 108390.50000000003,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: -1319.0899999999997,
                EquityBeforeThisMonthAccCurrency: 669254.37,
                CommissionThisYearBeforeThisMonth: -1319.0899999999997,
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 89150,
            name: "ftd_risk_700004",
            properties: {
                path: ["ftd", "europe", "ftd-europe-turkey-private-ecn-1-100"],
                group: "FTD\\Europe\\Turkey\\Private\\ECN\\1:100",
                login: 700004,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 177,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "FTD\\Europe\\Turkey\\Private\\ECN\\1:100",
                    login: 700004,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:37.322Z",
                    identifier: null,
                    last_login: "2021-05-20T14:04:04.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-05-20T14:04:04.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2021-05-20T14:04:04.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 89178,
            name: "ftd_risk_10003",
            properties: {
                path: ["ftd", "europe", "ftd-europe-turkey-private-ecn2-1-100"],
                group: "FTD\\Europe\\Turkey\\Private\\ECN2\\1:100",
                login: 10003,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 301,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "FTD\\Europe\\Turkey\\Private\\ECN2\\1:100",
                    login: 10003,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 78262.96,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:42.641Z",
                    identifier: null,
                    last_login: "2021-04-02T09:44:17.000Z",
                    lead_source: "",
                    prev_equity: 45585.96,
                    prev_balance: 78262.96,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-12-01T10:34:50.000Z",
                    prevmonth_equity: 23072.46,
                    prevmonth_balance: 78262.96,
                },
                register_time: "2020-12-01T10:34:50.000+00:00",
            },
            value: {
                ActualPL: 47664.96,
                OpenLots: 15,
                PLOverall: -209157.04,
                FloatingPL: -29455,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 2045610,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 837.75,
                PLLastMonth: -251241.85,
                PLThisMonth: 24592.5,
                PLYesterday: -44093.46,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 168,
                Withdrawable: 47664.96,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 45129.96,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -31078,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -213859.39999999997,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: -5115.090000000001,
                CountOfOpenTrades: 2,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: -1623,
                WdableAccCurrency: 47664.96,
                CommissionThisYear: -4570.66,
                NetDepositsOverall: 256822,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 47664.96,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -209157.04,
                "%ProfitableTotalTrade": 62.35294117647059,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 23072.46,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 63.095238095238095,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 106,
                PLThisMonthAccCurrency: 24592.5,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 47664.96,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 44093.46,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -15509.75,
                ClosedPLOverallBeforeToday: -172419.11,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 274314.31,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: -34169.5,
                FloatingPLEndOfPreviousMonth: -55190.5,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 256822,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: -19209.2,
                ClosedPLThisYearBeforeThisMonth: -144424.36,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: -5115.090000000001,
                EquityBeforeThisMonthAccCurrency: 23072.46,
                CommissionThisYearBeforeThisMonth: -4570.66,
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 92438,
            name: "ftd_risk_700000",
            properties: {
                path: ["ftd", "ftd-coverage", "ftd-coverage-natro"],
                group: "FTD\\coverage\\Natro",
                login: 700000,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 229,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "FTD\\coverage\\Natro",
                    login: 700000,
                    phone: "",
                    state: "",
                    credit: 500000,
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 371386.3,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 200,
                    read_only: false,
                    timestamp: "2021-10-06T13:57:54.633Z",
                    identifier: null,
                    last_login: "2020-08-09T21:12:24.000Z",
                    lead_source: "",
                    prev_equity: 759618.16,
                    prev_balance: 371804.93,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-04-22T19:07:38.000Z",
                    prevmonth_equity: 714506.16,
                    prevmonth_balance: 378045.55,
                },
                register_time: "2020-04-22T19:07:38.000+00:00",
            },
            value: {
                ActualPL: 252304.04,
                OpenLots: 92.23,
                PLOverall: -2975151.96,
                FloatingPL: -108248.25,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 6705576.879999999,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 135654.93,
                PLLastMonth: -496283.86,
                PLThisMonth: 37797.88,
                PLYesterday: -252625.85,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 39599,
                Withdrawable: 252304.04,
                "%ChangeEquity": 100,
                ClosedPLToday: -44.94,
                CurrentCredit: 500000,
                CurrentEquity: 744215.08,
                MonthlyVolume: 5780861.97,
                "#LotsThisMonth": 109.79,
                ClosedLotsToday: 0.02,
                ClosedSwapToday: -3,
                TotalFloatingPL: -114315.09,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -290207.59,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: 2321.56,
                CommissionOverall: -470903.3400000001,
                CountOfOpenTrades: 9,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: 500000,
                TotalFloatingSwap: -6066.84,
                WdableAccCurrency: 253326.92,
                CommissionThisYear: -220183.8300000001,
                NetDepositsOverall: 3227456,
                TotalClosedPLToday: -47.94,
                ActualPLAccCurrency: 253326.92,
                CommissionThisMonth: -561.11,
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: -0.07,
                ClosedDealsThisMonth: 43,
                InitialMontlhyVolume: 8029626.3,
                PLOverallAccCurrency: -2974129.08,
                "%ProfitableTotalTrade": 56.8117552009695,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 714506.16,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 56.82466728957802,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 22502,
                PLThisMonthAccCurrency: 38820.76,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: 500000,
                CurrentCreditAccCurrency: 500000,
                CurrentEquityAccCurrency: 752304.04,
                DayBeforeYesterdayCredit: 500000,
                DayBeforeYesterdayEquity: 752625.85,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -12701.676666666666,
                ClosedPLOverallBeforeToday: -3135684.3900000034,
                CreditEndOfMonthBeforeLast: 500000,
                EquityEndOfMonthBeforeLast: 1210790.02,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 5778540.41,
                ClosedPLThisMonthBeforeToday: -10813.76,
                DayBeforeYesterdayFloatingPL: -114044.83,
                FloatingPLEndOfPreviousMonth: -163539.39,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 3227456,
                ClosedLotsThisMonthBeforeToday: 109.77,
                CommissionThisMonthBeforeToday: -561.11,
                FloatingPLEndOfMonthBeforeLast: 27052.52,
                ClosedPLThisYearBeforeThisMonth: -1952645.5399999998,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: -252.59767441860464,
                CommissionOverallBeforeThisMonth: -470342.2300000001,
                EquityBeforeThisMonthAccCurrency: 714506.16,
                CommissionThisYearBeforeThisMonth: -219622.72000000006,
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: 500000,
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 93369,
            name: "ftd_risk_700003",
            properties: {
                path: ["ftd", "asia", "ftd-asia-singapore-private-pro-1-100"],
                group: "FTD\\Asia\\Singapore\\Private\\Pro\\1:100",
                login: 700003,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 267,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "FTD\\Asia\\Singapore\\Private\\Pro\\1:100",
                    login: 700003,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 5,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:41.236Z",
                    identifier: null,
                    last_login: "2021-05-18T09:22:16.000Z",
                    lead_source: "",
                    prev_equity: 5,
                    prev_balance: 5,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-05-18T09:22:16.000Z",
                    prevmonth_equity: 5,
                    prevmonth_balance: 5,
                },
                register_time: "2021-05-18T09:22:16.000+00:00",
            },
            value: {
                ActualPL: 5,
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -5,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: 5,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 5,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 5,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 5,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 5,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 5,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 5,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 5,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 5,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 5,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 5,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 99223,
            name: "ftd_risk_10006",
            properties: {
                path: ["ftd", "asia", "ftd-asia-china-private-pro-1-100"],
                group: "FTD\\Asia\\China\\Private\\Pro\\1:100",
                login: 10006,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 260,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "FTD\\Asia\\China\\Private\\Pro\\1:100",
                    login: 10006,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:40.940Z",
                    identifier: null,
                    last_login: "2021-05-24T12:25:21.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-05-24T12:25:21.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2021-05-24T12:25:21.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 99225,
            name: "ftd_risk_10007",
            properties: {
                path: ["ftd", "asia", "ftd-asia-china-private-pro-1-100"],
                group: "FTD\\Asia\\China\\Private\\Pro\\1:100",
                login: 10007,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 261,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "FTD\\Asia\\China\\Private\\Pro\\1:100",
                    login: 10007,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 6,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:40.986Z",
                    identifier: null,
                    last_login: "2021-06-07T10:39:47.000Z",
                    lead_source: "",
                    prev_equity: 6,
                    prev_balance: 6,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-06-07T10:39:47.000Z",
                    prevmonth_equity: 6,
                    prevmonth_balance: 6,
                },
                register_time: "2021-06-07T10:39:47.000+00:00",
            },
            value: {
                ActualPL: 6,
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -6,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: 6,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 6,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 6,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 6,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 6,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 6,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 6,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 6,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 6,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 6,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 6,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 99231,
            name: "ftd_risk_10008",
            properties: {
                path: ["ftd", "asia", "ftd-asia-china-private-pro-1-100"],
                group: "FTD\\Asia\\China\\Private\\Pro\\1:100",
                login: 10008,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 262,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "FTD\\Asia\\China\\Private\\Pro\\1:100",
                    login: 10008,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 20,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:41.028Z",
                    identifier: null,
                    last_login: "2021-06-11T09:08:52.000Z",
                    lead_source: "",
                    prev_equity: 20,
                    prev_balance: 20,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-06-08T07:32:30.000Z",
                    prevmonth_equity: 20,
                    prevmonth_balance: 20,
                },
                register_time: "2021-06-08T07:32:30.000+00:00",
            },
            value: {
                ActualPL: 20,
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -20,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: 20,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 20,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 20,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 20,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 20,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 20,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 20,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 20,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 20,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 20,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 20,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 99238,
            name: "ftd_risk_10009",
            properties: {
                path: ["ftd", "asia", "ftd-asia-china-private-pro-1-100"],
                group: "FTD\\Asia\\China\\Private\\Pro\\1:100",
                login: 10009,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 263,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "FTD\\Asia\\China\\Private\\Pro\\1:100",
                    login: 10009,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:41.070Z",
                    identifier: null,
                    last_login: "2021-06-08T08:37:57.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-06-08T08:37:57.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2021-06-08T08:37:57.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 99245,
            name: "ftd_risk_10010",
            properties: {
                path: ["ftd", "asia", "ftd-asia-china-private-pro-1-100"],
                group: "FTD\\Asia\\China\\Private\\Pro\\1:100",
                login: 10010,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 264,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "FTD\\Asia\\China\\Private\\Pro\\1:100",
                    login: 10010,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:41.113Z",
                    identifier: null,
                    last_login: "2021-06-08T08:39:24.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-06-08T08:39:24.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2021-06-08T08:39:24.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 99247,
            name: "ftd_risk_10011",
            properties: {
                path: ["ftd", "asia", "ftd-asia-china-private-pro-1-100"],
                group: "FTD\\Asia\\China\\Private\\Pro\\1:100",
                login: 10011,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 265,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "FTD\\Asia\\China\\Private\\Pro\\1:100",
                    login: 10011,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:41.155Z",
                    identifier: null,
                    last_login: "2021-06-10T08:41:06.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-06-08T08:58:58.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2021-06-08T08:58:58.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 99249,
            name: "ftd_risk_10012",
            properties: {
                path: ["ftd", "asia", "ftd-asia-china-private-pro-1-100"],
                group: "FTD\\Asia\\China\\Private\\Pro\\1:100",
                login: 10012,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 266,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "FTD\\Asia\\China\\Private\\Pro\\1:100",
                    login: 10012,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:41.198Z",
                    identifier: null,
                    last_login: "2021-06-08T09:08:07.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-06-08T09:08:07.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2021-06-08T09:08:07.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 101392,
            name: "ftd_risk_700002",
            properties: {
                path: ["ftd", "asia", "ftd-asia-china-general-pro-1-100"],
                group: "FTD\\Asia\\China\\General\\Pro\\1:100",
                login: 700002,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 257,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "FTD\\Asia\\China\\General\\Pro\\1:100",
                    login: 700002,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:40.823Z",
                    identifier: null,
                    last_login: "2021-05-17T09:10:02.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-05-17T09:10:02.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2021-05-17T09:10:02.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 101398,
            name: "ftd_risk_700006",
            properties: {
                path: ["ftd", "asia", "ftd-asia-china-general-pro-1-100"],
                group: "FTD\\Asia\\China\\General\\Pro\\1:100",
                login: 700006,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 258,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "FTD\\Asia\\China\\General\\Pro\\1:100",
                    login: 700006,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:40.862Z",
                    identifier: null,
                    last_login: "2021-06-08T07:30:59.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-06-08T07:30:59.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2021-06-08T07:30:59.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 102556,
            name: "ftd_risk_700007",
            properties: {
                path: ["ftd", "asia", "ftd-asia-china-general-ecn-1-100"],
                group: "FTD\\Asia\\China\\General\\ECN\\1:100",
                login: 700007,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 259,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "FTD\\Asia\\China\\General\\ECN\\1:100",
                    login: 700007,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:40.900Z",
                    identifier: null,
                    last_login: "2021-06-08T08:46:12.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-06-08T08:46:12.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2021-06-08T08:46:12.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 103823,
            name: "ftd_risk_700001",
            properties: {
                path: ["ftd", "asia", "ftd-asia-china-general"],
                group: "FTD\\Asia\\China\\General",
                login: 700001,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 171,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "FTD\\Asia\\China\\General",
                    login: 700001,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 1,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:37.057Z",
                    identifier: null,
                    last_login: "2021-05-14T06:14:43.000Z",
                    lead_source: "",
                    prev_equity: 1,
                    prev_balance: 1,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-05-14T06:14:43.000Z",
                    prevmonth_equity: 1,
                    prevmonth_balance: 1,
                },
                register_time: "2021-05-14T06:14:43.000+00:00",
            },
            value: {
                ActualPL: 1,
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -1,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: 1,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 1,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 1,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 1,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 1,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 1,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 1,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 1,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 1,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 1,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 1,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 104474,
            name: "ftd_risk_700005",
            properties: {
                path: ["ftd", "asia", "ftd-asia"],
                group: "FTD\\Asia",
                login: 700005,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 169,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "FTD\\Asia",
                    login: 700005,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 1,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:36.977Z",
                    identifier: null,
                    last_login: "2021-06-07T12:03:20.000Z",
                    lead_source: "",
                    prev_equity: 1,
                    prev_balance: 1,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-06-07T12:03:20.000Z",
                    prevmonth_equity: 1,
                    prevmonth_balance: 1,
                },
                register_time: "2021-06-07T12:03:20.000+00:00",
            },
            value: {
                ActualPL: 1,
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -1,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: 1,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 1,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 1,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 1,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 1,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 1,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 1,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 1,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 1,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 1,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 1,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 104665,
            name: "ftd_risk_1009",
            properties: {
                path: ["coverage", "coverage-1", "coverage-sucden"],
                group: "coverage\\Sucden",
                login: 1009,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 230,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "coverage\\Sucden",
                    login: 1009,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 2034826.11,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 50,
                    read_only: false,
                    timestamp: "2021-10-06T13:57:54.967Z",
                    identifier: null,
                    last_login: "2020-05-12T09:41:27.000Z",
                    lead_source: "",
                    prev_equity: 1855398.28,
                    prev_balance: 2034978.49,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-05-12T09:41:27.000Z",
                    prevmonth_equity: 1858048.69,
                    prevmonth_balance: 2195278.99,
                },
                register_time: "2020-05-12T09:41:27.000+00:00",
            },
            value: {
                ActualPL: 1840342.25,
                OpenLots: 51.68,
                PLOverall: 1326300.56,
                FloatingPL: -168544.22,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 6391031.71,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 5576.36,
                PLLastMonth: -318554.11,
                PLThisMonth: -15016.44,
                PLYesterday: -1840493.8,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 3935,
                Withdrawable: 1840342.25,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 1830018.12,
                MonthlyVolume: 4215000,
                "#LotsThisMonth": 87.86,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -191511.09,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -31444.23,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: -7171.399999999998,
                CountOfOpenTrades: 3,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: -2690,
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: -22966.870000000003,
                WdableAccCurrency: 1840342.25,
                CommissionThisYear: -3391.650000000001,
                NetDepositsOverall: 514041.68999999994,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 1840342.25,
                CommissionThisMonth: -120.59,
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: -67000,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 30,
                InitialMontlhyVolume: 8228542.84,
                PLOverallAccCurrency: 1326300.56,
                "%ProfitableTotalTrade": 52.81868969019807,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 1858048.69,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 52.858958068614996,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 2080,
                PLThisMonthAccCurrency: -15016.44,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 1840342.25,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 1840493.8,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -63756.04666666667,
                ClosedPLOverallBeforeToday: -80998.24999999999,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 2243602.8,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 4215000,
                ClosedPLThisMonthBeforeToday: -159623.76000000004,
                DayBeforeYesterdayFloatingPL: -192350.84000000003,
                FloatingPLEndOfPreviousMonth: -337230.30000000005,
                MonthlyNetDepositAccCurrency: -2690,
                NetDepositsOverallAccCurrency: 514041.69,
                ClosedLotsThisMonthBeforeToday: 87.86,
                CommissionThisMonthBeforeToday: -120.59,
                FloatingPLEndOfMonthBeforeLast: -50268.81,
                ClosedPLThisYearBeforeThisMonth: 167143.37000000008,
                NetDepositsThisMonthBeforeToday: -2690,
                AVGProfitPerClosedTradeThisMonth: -5320.792,
                CommissionOverallBeforeThisMonth: -7050.809999999998,
                EquityBeforeThisMonthAccCurrency: 1858048.69,
                CommissionThisYearBeforeThisMonth: -3271.060000000001,
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: -2690,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },

        {
            id: 105260,
            name: "ftd_risk_1008",
            properties: {
                path: ["coverage", "coverage-1", "coverage-isprimeswap"],
                group: "coverage\\ISPrimeSwap",
                login: 1008,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 179,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "coverage\\ISPrimeSwap",
                    login: 1008,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 1000000,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:37.406Z",
                    identifier: null,
                    last_login: "2020-04-29T14:04:58.000Z",
                    lead_source: "",
                    prev_equity: 1000000,
                    prev_balance: 1000000,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-04-29T14:04:58.000Z",
                    prevmonth_equity: 1044559.56,
                    prevmonth_balance: 1044559.56,
                },
                register_time: "2020-04-29T14:04:58.000+00:00",
            },
            value: {
                ActualPL: 1000000,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 972409.25,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 37347,
                PLLastMonth: 491.19,
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -1000000,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 2064,
                Withdrawable: 1000000,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 1000000,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: 492.07,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: -2264.449999999997,
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: -44559.56,
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 1000000,
                CommissionThisYear: -1155.39,
                NetDepositsOverall: 27590.75,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 1000000,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 972409.25,
                "%ProfitableTotalTrade": 58.236434108527135,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 1044559.56,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 58.236434108527135,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 1202,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 1000000,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 1000000,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -133654.74000000002,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 1044068.37,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: -44559.56,
                NetDepositsOverallAccCurrency: 27590.750000000004,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -45847.04,
                NetDepositsThisMonthBeforeToday: -44559.56,
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: -2264.4499999999966,
                EquityBeforeThisMonthAccCurrency: 1044559.56,
                CommissionThisYearBeforeThisMonth: -1155.39,
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: -44559.56,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 105625,
            name: "ftd_risk_1007",
            properties: {
                path: ["coverage", "coverage-1", "coverage-isprimefx"],
                group: "coverage\\ISPrimeFX",
                login: 1007,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 178,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "coverage\\ISPrimeFX",
                    login: 1007,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 1892677.4,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:37.365Z",
                    identifier: null,
                    last_login: "2020-04-29T14:04:33.000Z",
                    lead_source: "",
                    prev_equity: 1713841.09,
                    prev_balance: 1892921.01,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-04-29T14:04:33.000Z",
                    prevmonth_equity: 1584715.31,
                    prevmonth_balance: 1848038,
                },
                register_time: "2020-04-29T14:04:33.000+00:00",
            },
            value: {
                ActualPL: 1701731.94,
                OpenLots: 142.88,
                PLOverall: 640521.02,
                FloatingPL: -160592.12,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 11879380.73,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 46780.11,
                PLLastMonth: -645757.88,
                PLThisMonth: 75900.63,
                PLYesterday: -1699398.69,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 27687,
                Withdrawable: 1701731.94,
                "%ChangeEquity": 100,
                ClosedPLToday: -39.45,
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 1683182.81,
                MonthlyVolume: 1940808.33,
                "#LotsThisMonth": 38.62,
                ClosedLotsToday: 0.03,
                ClosedSwapToday: -4.050000000000001,
                TotalFloatingPL: -170470.16,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -331088.94999999995,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: 3482.34,
                CommissionOverall: -57678.18000000003,
                CountOfOpenTrades: 6,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: 41116,
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: -12871.740000000002,
                WdableAccCurrency: 1704149.24,
                CommissionThisYear: -27332.61,
                NetDepositsOverall: 1061210.92,
                TotalClosedPLToday: -43.5,
                ActualPLAccCurrency: 1704149.24,
                CommissionThisMonth: -50.73,
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: 6200,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: -0.03,
                ClosedDealsThisMonth: 19,
                InitialMontlhyVolume: 4480157.64,
                PLOverallAccCurrency: 642938.32,
                "%ProfitableTotalTrade": 56.024988264182284,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 1584715.31,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 56.0371293386788,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 15515,
                PLThisMonthAccCurrency: 78317.93,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 1701157.48,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 1699398.69,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -28411.693333333333,
                ClosedPLOverallBeforeToday: -1331288.0799999996,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 2224273.19,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 1937325.99,
                ClosedPLThisMonthBeforeToday: -14440.329999999998,
                DayBeforeYesterdayFloatingPL: -175264.25,
                FloatingPLEndOfPreviousMonth: -263322.69,
                MonthlyNetDepositAccCurrency: 41116,
                NetDepositsOverallAccCurrency: 1061210.92,
                ClosedLotsThisMonthBeforeToday: 38.59,
                CommissionThisMonthBeforeToday: -50.730000000000004,
                FloatingPLEndOfMonthBeforeLast: 49328.91,
                ClosedPLThisYearBeforeThisMonth: -709086.3300000003,
                NetDepositsThisMonthBeforeToday: 41116,
                AVGProfitPerClosedTradeThisMonth: -762.3068421052632,
                CommissionOverallBeforeThisMonth: -57627.450000000026,
                EquityBeforeThisMonthAccCurrency: 1584715.31,
                CommissionThisYearBeforeThisMonth: -27281.88,
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: 41116,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 106016,
            name: "ftd_risk_1006",
            properties: {
                path: ["coverage", "coverage-1", "coverage-cfh"],
                group: "coverage\\CFH",
                login: 1006,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 170,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "coverage\\CFH",
                    login: 1006,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 1642514.59,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:37.019Z",
                    identifier: null,
                    last_login: "2019-12-17T10:25:52.000Z",
                    lead_source: "",
                    prev_equity: 1622189.45,
                    prev_balance: 1643757.45,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2019-12-17T10:25:52.000Z",
                    prevmonth_equity: 1475646.15,
                    prevmonth_balance: 1629900.97,
                },
                register_time: "2019-12-17T10:25:52.000+00:00",
            },
            value: {
                ActualPL: 1613854.38,
                OpenLots: 243.85,
                PLOverall: 14645.66,
                FloatingPL: 2952.670000000001,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 13874465.43,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 61317.55,
                PLLastMonth: -336654.36,
                PLThisMonth: 110217.62,
                PLYesterday: -1596256.84,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 24859,
                Withdrawable: 1614198.23,
                "%ChangeEquity": 100,
                ClosedPLToday: -12.27,
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 1598856.51,
                MonthlyVolume: 1901126.92,
                "#LotsThisMonth": 52.39,
                ClosedLotsToday: 0.01,
                ClosedSwapToday: -1.15,
                TotalFloatingPL: -14196.8,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -227059.49000000002,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: 1160.78,
                CommissionOverall: -73505.60000000005,
                CountOfOpenTrades: 6,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: 28200,
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: -17149.469999999998,
                WdableAccCurrency: 1618899.32,
                CommissionThisYear: -26219.40000000002,
                NetDepositsOverall: 1599418.1099999999,
                TotalClosedPLToday: -13.42,
                ActualPLAccCurrency: 1618899.32,
                CommissionThisMonth: -69.7,
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: 26000,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: -0.01,
                ClosedDealsThisMonth: 20,
                InitialMontlhyVolume: 5340552,
                PLOverallAccCurrency: 19481.21,
                "%ProfitableTotalTrade": 55.65654534486226,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 1475646.15,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 55.66997867975381,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 13839,
                PLThisMonthAccCurrency: 115053.17,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 1614198.23,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 1596256.84,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -2366.133333333333,
                ClosedPLOverallBeforeToday: -1638814.9499999993,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 1786300.51,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 1899966.1400000001,
                ClosedPLThisMonthBeforeToday: -29392.229999999996,
                DayBeforeYesterdayFloatingPL: -32382.2,
                FloatingPLEndOfPreviousMonth: -154254.81999999998,
                MonthlyNetDepositAccCurrency: 28200,
                NetDepositsOverallAccCurrency: 1599418.11,
                ClosedLotsThisMonthBeforeToday: 52.38,
                CommissionThisMonthBeforeToday: -69.7,
                FloatingPLEndOfMonthBeforeLast: -46744.18000000001,
                ClosedPLThisYearBeforeThisMonth: -733135.8200000003,
                NetDepositsThisMonthBeforeToday: 28200,
                AVGProfitPerClosedTradeThisMonth: -1470.2825,
                CommissionOverallBeforeThisMonth: -73435.90000000005,
                EquityBeforeThisMonthAccCurrency: 1475646.15,
                CommissionThisYearBeforeThisMonth: -26149.700000000015,
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: 28200,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 111142,
            name: "ftd_risk_7013",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7042-gold-1-100"],
                group: "Natro\\IB\\IB-7042\\Gold\\1:100",
                login: 7013,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 294,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7042\\Gold\\1:100",
                    login: 7013,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:42.343Z",
                    identifier: null,
                    last_login: "2021-08-25T08:35:13.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: 7042,
                    register_time: "2020-05-15T10:29:59.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2020-05-15T10:29:59.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: -19984.85,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 174.08,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 160,
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 19984.85,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -19984.85,
                "%ProfitableTotalTrade": 23.125,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 23.125,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 37,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -19984.850000000006,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 19984.85,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -19984.850000000006,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },

        {
            id: 111144,
            name: "ftd_risk_7054",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7042-gold-1-100"],
                group: "Natro\\IB\\IB-7042\\Gold\\1:100",
                login: 7054,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 296,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7042\\Gold\\1:100",
                    login: 7054,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 0.58,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:42.429Z",
                    identifier: null,
                    last_login: "2021-09-21T19:31:54.000Z",
                    lead_source: "",
                    prev_equity: 0.58,
                    prev_balance: 0.58,
                    agent_account: 7055,
                    register_time: "2020-09-28T10:16:45.000Z",
                    prevmonth_equity: 0.58,
                    prevmonth_balance: 0.58,
                },
                register_time: "2020-09-28T10:16:45.000+00:00",
            },
            value: {
                ActualPL: 0.58,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -17590.42,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 177.51,
                PLLastMonth: -7316,
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -0.58,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 260,
                Withdrawable: 0.58,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 0.58,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -7316,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 0.58,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 17591,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 0.58,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: 7316,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -17590.42,
                "%ProfitableTotalTrade": 43.07692307692308,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 0.58,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 43.07692307692308,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 112,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 0.58,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 0.58,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -17590.420000000002,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 0.58,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 17591,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -17590.420000000002,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 0.58,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 111145,
            name: "ftd_risk_7093",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7042-gold-1-100"],
                group: "Natro\\IB\\IB-7042\\Gold\\1:100",
                login: 7093,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 297,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7042\\Gold\\1:100",
                    login: 7093,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 0.54,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:42.472Z",
                    identifier: null,
                    last_login: "2021-08-25T08:38:06.000Z",
                    lead_source: "",
                    prev_equity: 0.54,
                    prev_balance: 0.54,
                    agent_account: 7042,
                    register_time: "2021-05-24T09:25:14.000Z",
                    prevmonth_equity: 0.54,
                    prevmonth_balance: 0.54,
                },
                register_time: "2021-05-24T09:25:14.000+00:00",
            },
            value: {
                ActualPL: 0.54,
                OpenLots: getRandomInt(1, 500),
                PLOverall: -20601.46,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 162.17,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -0.54,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 187,
                Withdrawable: 0.54,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 0.54,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 0.54,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 20602,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 0.54,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -20601.46,
                "%ProfitableTotalTrade": 75.93582887700535,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 0.54,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 75.93582887700535,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 142,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 0.54,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 0.54,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -20601.46,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 0.54,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 20602,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -20601.46,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 0.54,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 111146,
            name: "ftd_risk_7107",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7042-gold-1-100"],
                group: "Natro\\IB\\IB-7042\\Gold\\1:100",
                login: 7107,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 300,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7042\\Gold\\1:100",
                    login: 7107,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 3.35,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:42.594Z",
                    identifier: null,
                    last_login: "2021-09-20T10:32:40.000Z",
                    lead_source: "",
                    prev_equity: 3.35,
                    prev_balance: 3.35,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-08-31T14:04:19.000Z",
                    prevmonth_equity: 3.35,
                    prevmonth_balance: 3.35,
                },
                register_time: "2021-08-31T14:04:19.000+00:00",
            },
            value: {
                ActualPL: 3.35,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 803.35,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: 675.85,
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -3.35,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: 3.35,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 3.35,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 3.35,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -800,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 3.35,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: -800,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 803.35,
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 3.35,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 3.35,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 3.35,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 127.5,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: -800,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 3.35,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 111147,
            name: "ftd_risk_7105",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7042-gold-1-100"],
                group: "Natro\\IB\\IB-7042\\Gold\\1:100",
                login: 7105,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 298,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7042\\Gold\\1:100",
                    login: 7105,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 79997.27,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-07T09:30:28.857Z",
                    identifier: null,
                    last_login: "2021-10-07T08:58:13.000Z",
                    lead_source: "",
                    prev_equity: 4969.26,
                    prev_balance: 79997.27,
                    agent_account: 7038,
                    register_time: "2021-08-25T09:59:41.000Z",
                    prevmonth_equity: 14725.18,
                    prevmonth_balance: 89100.27,
                },
                register_time: "2021-08-25T09:59:41.000+00:00",
            },
            value: {
                ActualPL: 3321.56,
                OpenLots: 27.2,
                PLOverall: -4148.44,
                FloatingPL: -58557.6,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 4322076.1,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 1199.01,
                PLLastMonth: 2749.68,
                PLThisMonth: -6403.62,
                PLYesterday: -4747.74,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 255,
                Withdrawable: 3321.56,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 2990.96,
                MonthlyVolume: 6635456.4,
                "#LotsThisMonth": 37.8,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -58632.51,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: 74419.77000000002,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 8,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: -5000,
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: -74.91,
                WdableAccCurrency: 3321.56,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 7470,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 3321.56,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: -17530,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 8,
                InitialMontlhyVolume: 5896440,
                PLOverallAccCurrency: -4148.44,
                "%ProfitableTotalTrade": 60.836501901140686,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 14725.18,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 62.745098039215684,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 160,
                PLThisMonthAccCurrency: -6403.62,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 3321.56,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 4747.74,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -7328.74125,
                ClosedPLOverallBeforeToday: 57056.87000000002,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 29505.5,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 6635456.4,
                ClosedPLThisMonthBeforeToday: -19573.4,
                DayBeforeYesterdayFloatingPL: -59779.130000000005,
                FloatingPLEndOfPreviousMonth: -74375.09,
                MonthlyNetDepositAccCurrency: -5000,
                NetDepositsOverallAccCurrency: 7470,
                ClosedLotsThisMonthBeforeToday: 37.8,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: -2705,
                ClosedPLThisYearBeforeThisMonth: 76630.27000000002,
                NetDepositsThisMonthBeforeToday: -5000,
                AVGProfitPerClosedTradeThisMonth: -2446.675,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 14725.18,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: -5000,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 111141,
            name: "ftd_risk_7110",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7042-gold-1-100"],
                group: "Natro\\IB\\IB-7042\\Gold\\1:100",
                login: 7110,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 132,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7042\\Gold\\1:100",
                    login: 7110,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 19216.08,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-07T12:24:06.766Z",
                    identifier: null,
                    last_login: "2021-10-07T10:58:41.000Z",
                    lead_source: "",
                    prev_equity: 14333.08,
                    prev_balance: 18388.08,
                    agent_account: 7109,
                    register_time: "2021-09-15T17:43:28.000Z",
                    prevmonth_equity: 3839.98,
                    prevmonth_balance: 2377.98,
                },
                register_time: "2021-09-15T17:43:28.000+00:00",
            },
            value: {
                ActualPL: 20369.08,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 9261.08,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 909.74,
                PLLastMonth: -9560.02,
                PLThisMonth: 18821.1,
                PLYesterday: -20021.08,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 286,
                Withdrawable: 20369.08,
                "%ChangeEquity": 100,
                ClosedPLToday: 146,
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 20369.08,
                MonthlyVolume: 30490478.64999999,
                "#LotsThisMonth": 577.13,
                ClosedLotsToday: 6,
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -9322.02,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: 1052796,
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: -1700,
                MonthlyNetDeposit: -592,
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 20353.08,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 11108,
                TotalClosedPLToday: 130,
                ActualPLAccCurrency: 20353.08,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: 11700,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 132,
                InitialMontlhyVolume: 23180743.6,
                PLOverallAccCurrency: 3953.08,
                "%ProfitableTotalTrade": 86.7132867132867,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 3839.98,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 86.7132867132867,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 248,
                PLThisMonthAccCurrency: 18805.1,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: 1700,
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 20369.08,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 20021.08,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: 9115.079999999998,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 29437682.64999999,
                ClosedPLThisMonthBeforeToday: 18437.1,
                DayBeforeYesterdayFloatingPL: -202,
                FloatingPLEndOfPreviousMonth: -238,
                MonthlyNetDepositAccCurrency: -592,
                NetDepositsOverallAccCurrency: 16400,
                ClosedLotsThisMonthBeforeToday: 571.13,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -9322.02,
                NetDepositsThisMonthBeforeToday: -592,
                AVGProfitPerClosedTradeThisMonth: 140.65984848484848,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 3839.98,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: 1700,
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: -592,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 111148,
            name: "ftd_risk_7106",
            properties: {
                path: ["natro", "ib", "natro-ib-ib-7042-gold-1-100"],
                group: "Natro\\IB\\IB-7042\\Gold\\1:100",
                login: 7106,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 299,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "Natro\\IB\\IB-7042\\Gold\\1:100",
                    login: 7106,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 17.78,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T21:00:02.598Z",
                    identifier: null,
                    last_login: "2021-10-05T16:46:13.000Z",
                    lead_source: "",
                    prev_equity: 21.56,
                    prev_balance: 17.78,
                    agent_account: 7107,
                    register_time: "2021-08-31T14:03:17.000Z",
                    prevmonth_equity: 19.53,
                    prevmonth_balance: 17.78,
                },
                register_time: "2021-08-31T14:03:17.000+00:00",
            },
            value: {
                ActualPL: 20.13,
                OpenLots: 0.01,
                PLOverall: -1979.87,
                FloatingPL: 2.13,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 1160.78,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 30.61,
                PLLastMonth: -2228.47,
                PLThisMonth: 0.6,
                PLYesterday: -19.75,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 152,
                Withdrawable: 20.13,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 21.53,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: 2.17,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -2230.22,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 1,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: 0.04,
                WdableAccCurrency: 20.13,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 2000,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 20.13,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: -500,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: -1979.87,
                "%ProfitableTotalTrade": 81.69934640522875,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 19.53,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 82.23684210526316,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 125,
                PLThisMonthAccCurrency: 0.6,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 20.13,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 19.75,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: 2.17,
                ClosedPLOverallBeforeToday: -1982.22,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 2748,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: 1.9700000000000002,
                FloatingPLEndOfPreviousMonth: 1.75,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 2000,
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -1982.22,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 19.53,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 111408,
            name: "ftd_risk_1003",
            properties: {
                path: ["fx-cubic", "fx-cubic-1", "fxcubic"],
                group: "fxcubic",
                login: 1003,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 157,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "fxcubic",
                    login: 1003,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 996915.21,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:36.610Z",
                    identifier: null,
                    last_login: "2019-09-20T12:15:38.000Z",
                    lead_source: "",
                    prev_equity: 996915.21,
                    prev_balance: 996915.21,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2019-09-20T12:15:38.000Z",
                    prevmonth_equity: 996915.21,
                    prevmonth_balance: 996915.21,
                },
                register_time: "2019-09-20T12:15:38.000+00:00",
            },
            value: {
                ActualPL: 996915.21,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 996915.21,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 19.18,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -996915.21,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 33,
                Withdrawable: 996915.21,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 996915.21,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 996915.21,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 996915.21,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 996915.21,
                "%ProfitableTotalTrade": 54.54545454545455,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 996915.21,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 54.54545454545455,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 18,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 996915.21,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 996915.21,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -3084.77,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 996915.21,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 996915.21,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 111411,
            name: "ftd_risk_1004",
            properties: {
                path: ["fx-cubic", "fx-cubic-1", "fxcubic"],
                group: "fxcubic",
                login: 1004,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 158,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "fxcubic",
                    login: 1004,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 1250970.54,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T21:00:07.359Z",
                    identifier: null,
                    last_login: "2019-09-20T12:15:54.000Z",
                    lead_source: "",
                    prev_equity: 1251092.64,
                    prev_balance: 1250970.54,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2019-09-20T12:15:54.000Z",
                    prevmonth_equity: 1251098.26,
                    prevmonth_balance: 1250970.54,
                },
                register_time: "2019-09-20T12:15:54.000+00:00",
            },
            value: {
                ActualPL: 1251096,
                OpenLots: 0.01,
                PLOverall: 1251096,
                FloatingPL: 126.27,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 1770.81,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 110.82,
                PLLastMonth: 56.69,
                PLThisMonth: -2.26,
                PLYesterday: -1251098.17,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 273,
                Withdrawable: 1251096,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 1251102.3,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: 126.27,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 1,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 1251096,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 1251096,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 1251096,
                "%ProfitableTotalTrade": 43.065693430656935,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 1251098.26,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 43.223443223443226,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 118,
                PLThisMonthAccCurrency: -2.26,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 1251096,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 1251098.17,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: 126.27,
                ClosedPLOverallBeforeToday: 970.5399999999992,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 1251041.57,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: 127.63,
                FloatingPLEndOfPreviousMonth: 127.72,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: 71.03,
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 1251098.26,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 111412,
            name: "ftd_risk_1005",
            properties: {
                path: ["fx-cubic", "fx-cubic-1", "fxcubic"],
                group: "fxcubic",
                login: 1005,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 159,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "fxcubic",
                    login: 1005,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 1049928.8,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T21:00:07.373Z",
                    identifier: null,
                    last_login: "2019-09-20T12:16:11.000Z",
                    lead_source: "",
                    prev_equity: 1050269.18,
                    prev_balance: 1049928.8,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2019-09-20T12:16:11.000Z",
                    prevmonth_equity: 1050265.99,
                    prevmonth_balance: 1049928.8,
                },
                register_time: "2019-09-20T12:16:11.000+00:00",
            },
            value: {
                ActualPL: 1050283.3,
                OpenLots: 0.03,
                PLOverall: 1050283.3,
                FloatingPL: 350.14,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 3770.81,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 0.34,
                PLLastMonth: 56.66,
                PLThisMonth: 17.31,
                PLYesterday: -1050277.49,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 33,
                Withdrawable: 1050283.3,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 1050275.22,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: 350.14,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 2,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 1050283.3,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 1050283.3,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 1050283.3,
                "%ProfitableTotalTrade": 60,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 1050265.99,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 63.63636363636363,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 21,
                PLThisMonthAccCurrency: 17.31,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 1050283.3,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 1050277.49,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: 175.07,
                ClosedPLOverallBeforeToday: -101.16,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 1050209.33,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: 348.69,
                FloatingPLEndOfPreviousMonth: 337.19,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: 280.53,
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 1050265.99,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 111413,
            name: "ftd_risk_1013",
            properties: {
                path: ["fx-cubic", "fx-cubic-1", "fxcubic"],
                group: "fxcubic",
                login: 1013,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 163,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "fxcubic",
                    login: 1013,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 1735189.85,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T21:00:07.400Z",
                    identifier: null,
                    last_login: "2020-06-05T11:25:24.000Z",
                    lead_source: "",
                    prev_equity: 1485306.33,
                    prev_balance: 1735189.85,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-06-05T11:25:24.000Z",
                    prevmonth_equity: 1489556.33,
                    prevmonth_balance: 1735189.85,
                },
                register_time: "2020-06-05T11:25:24.000+00:00",
            },
            value: {
                ActualPL: 1493680.22,
                OpenLots: 53,
                PLOverall: 1493680.22,
                FloatingPL: -190743.22999999998,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 6635374,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 10495.57,
                PLLastMonth: -234476.04,
                PLThisMonth: 4123.89,
                PLYesterday: -1493314.22,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 4663,
                Withdrawable: 1493680.22,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 1482385.22,
                MonthlyVolume: 2176602.88,
                "#LotsThisMonth": 14.11,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -190743.23,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -93037.58,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 2,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 1493680.22,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 1493680.22,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 5,
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 1493680.22,
                "%ProfitableTotalTrade": 39.356913183279744,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 1489556.33,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 39.37379369504611,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 1836,
                PLThisMonthAccCurrency: 4123.89,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 1493680.22,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 1493314.22,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -95371.615,
                ClosedPLOverallBeforeToday: -59140.55000000014,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 1724032.37,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 2176602.8800000004,
                ClosedPLThisMonthBeforeToday: -50559.4,
                DayBeforeYesterdayFloatingPL: -191316.23,
                FloatingPLEndOfPreviousMonth: -245633.52,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: 14.11,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: -104195.06,
                ClosedPLThisYearBeforeThisMonth: -93037.58,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: -10111.88,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 1489556.33,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 111414,
            name: "ftd_risk_1016",
            properties: {
                path: ["fx-cubic", "fx-cubic-1", "fxcubic"],
                group: "fxcubic",
                login: 1016,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 166,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "fxcubic",
                    login: 1016,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:36.831Z",
                    identifier: null,
                    last_login: "2021-01-07T13:38:31.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-01-07T12:46:10.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2021-01-07T12:46:10.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 111415,
            name: "ftd_risk_1021",
            properties: {
                path: ["fx-cubic", "fx-cubic-1", "fxcubic"],
                group: "fxcubic",
                login: 1021,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 167,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "fxcubic",
                    login: 1021,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 495289.41,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-07T14:34:15.051Z",
                    identifier: null,
                    last_login: "2021-09-29T07:51:32.000Z",
                    lead_source: "",
                    prev_equity: 506282.69,
                    prev_balance: 502728.44,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-09-29T07:51:32.000Z",
                    prevmonth_equity: 498076.45,
                    prevmonth_balance: 499679.41,
                },
                register_time: "2021-09-29T07:51:32.000+00:00",
            },
            value: {
                ActualPL: 500489.29,
                OpenLots: 4.66,
                PLOverall: 489.29,
                FloatingPL: -99.39999999999999,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 244213.71999999997,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 233.04,
                PLLastMonth: -1923.55,
                PLThisMonth: 2412.84,
                PLYesterday: -500650.66,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 84,
                Withdrawable: 500489.29,
                "%ChangeEquity": 100,
                ClosedPLToday: -4.530000000000008,
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 501440.02,
                MonthlyVolume: 24078188.577496,
                "#LotsThisMonth": 173.02,
                ClosedLotsToday: 4.4,
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -56.75,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -320.5899999999997,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: 472156,
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 2,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 500404.03,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 500000,
                TotalClosedPLToday: -4.530000000000008,
                ActualPLAccCurrency: 500404.03,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: 500000,
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 75,
                InitialMontlhyVolume: 21732483.817495998,
                PLOverallAccCurrency: 404.03,
                "%ProfitableTotalTrade": 48.83720930232558,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 498076.45,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 50,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 42,
                PLThisMonthAccCurrency: 2327.58,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 500489.29,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 500650.66,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -28.375,
                ClosedPLOverallBeforeToday: 625.7700000000004,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 23606032.577496003,
                ClosedPLThisMonthBeforeToday: 946.3599999999997,
                DayBeforeYesterdayFloatingPL: 24.89,
                FloatingPLEndOfPreviousMonth: -1602.96,
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: 500000,
                ClosedLotsThisMonthBeforeToday: 168.62,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: -320.5899999999997,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: 12.55773333333333,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 498076.45,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },

        {
            id: 111417,
            name: "ftd_risk_1015",
            properties: {
                path: ["fx-cubic", "fx-cubic-1", "fxcubic"],
                group: "fxcubic",
                login: 1015,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 165,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "fxcubic",
                    login: 1015,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 39171.79,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-07T14:34:14.976Z",
                    identifier: null,
                    last_login: "2020-08-06T14:49:40.000Z",
                    lead_source: "",
                    prev_equity: 38132.67,
                    prev_balance: 38132.67,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-08-06T14:49:40.000Z",
                    prevmonth_equity: 34311.53,
                    prevmonth_balance: 34311.53,
                },
                register_time: "2020-08-06T14:49:40.000+00:00",
            },
            value: {
                ActualPL: 39627.08,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 39627.08,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 11607.8,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 6403.36,
                PLLastMonth: 760.49,
                PLThisMonth: 5315.55,
                PLYesterday: -39535.27,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 5920,
                Withdrawable: 39627.08,
                "%ChangeEquity": 100,
                ClosedPLToday: 91.81,
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 39627.08,
                MonthlyVolume: 42536977.89499201,
                "#LotsThisMonth": 291.08,
                ClosedLotsToday: 5.48,
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: 760.49,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: 797227.36,
                CommissionOverall: 12.24,
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 39623.4,
                CommissionThisYear: 12.24,
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: 91.81,
                ActualPLAccCurrency: 39623.4,
                CommissionThisMonth: 12.239999999999998,
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: 13.44,
                ClosedDealsThisMonth: 147,
                InitialMontlhyVolume: 35189373.354992,
                PLOverallAccCurrency: 39623.4,
                "%ProfitableTotalTrade": 99.62831559385032,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 34311.53,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 99.62831559385032,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 5898,
                PLThisMonthAccCurrency: 5311.87,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 39627.08,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 39535.27,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: 45531.56,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 33551.04,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 41767470.054992005,
                ClosedPLThisMonthBeforeToday: 5214.699999999999,
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: 285.6,
                CommissionThisMonthBeforeToday: 9.04,
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: 760.49,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: 36.320753424657525,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 34311.53,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 111418,
            name: "ftd_risk_1017",
            properties: {
                path: ["fx-cubic", "fx-cubic-1", "fxcubic"],
                group: "fxcubic",
                login: 1017,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 129,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "fxcubic",
                    login: 1017,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 557914.77,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-07T18:31:54.472Z",
                    identifier: null,
                    last_login: "2021-07-14T09:38:39.000Z",
                    lead_source: "",
                    prev_equity: 549885.07,
                    prev_balance: 558301.39,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-07-14T09:38:39.000Z",
                    prevmonth_equity: 548392.15,
                    prevmonth_balance: 564373.3,
                },
                register_time: "2021-07-14T09:38:39.000+00:00",
            },
            value: {
                ActualPL: 501484.49,
                OpenLots: 16.33,
                PLOverall: 564947.24,
                FloatingPL: -1689.08,
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 468069.8599999999,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 2380.84,
                PLLastMonth: -74897.45,
                PLThisMonth: 16555.09,
                PLYesterday: -498930.37,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 5314,
                Withdrawable: 501484.49,
                "%ChangeEquity": 100,
                ClosedPLToday: 350.69999999999993,
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 500656.63,
                MonthlyVolume: 32147486.96000001,
                "#LotsThisMonth": 403.76,
                ClosedLotsToday: 26.1,
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: -1730.53,
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -31412.14,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: 4278010.3,
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: 6,
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: -63462.75,
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 501523.58,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: -63462.75,
                TotalClosedPLToday: 431.24,
                ActualPLAccCurrency: 501523.58,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 447,
                InitialMontlhyVolume: 12342501.40000002,
                PLOverallAccCurrency: 564986.33,
                "%ProfitableTotalTrade": 44.32330827067669,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 548392.15,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 44.3733534060971,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 2358,
                PLThisMonthAccCurrency: 16594.18,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 501484.49,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 498930.37,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: -288.4216666666667,
                ClosedPLOverallBeforeToday: 66285.62,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 623289.6,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 27952712.56000001,
                ClosedPLThisMonthBeforeToday: 1912.3199999999997,
                DayBeforeYesterdayFloatingPL: -3892.5,
                FloatingPLEndOfPreviousMonth: -15981.15,
                MonthlyNetDepositAccCurrency: -63462.75,
                NetDepositsOverallAccCurrency: -63462.75,
                ClosedLotsThisMonthBeforeToday: 377.66,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: 27504.16,
                ClosedPLThisYearBeforeThisMonth: 7441.09,
                NetDepositsThisMonthBeforeToday: -63462.75,
                AVGProfitPerClosedTradeThisMonth: 5.242863534675616,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 548392.15,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: -63462.75,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },

        {
            id: 111420,
            name: "ftd_risk_1020",
            properties: {
                path: ["fx-cubic", "fx-cubic-1", "fxcubic"],
                group: "fxcubic",
                login: 1020,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 127,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "fxcubic",
                    login: 1020,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 456598.38,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-07T18:37:19.906Z",
                    identifier: null,
                    last_login: "2021-08-10T10:28:42.000Z",
                    lead_source: "",
                    prev_equity: 457675.44,
                    prev_balance: 456268.35,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-08-10T10:28:42.000Z",
                    prevmonth_equity: 461154.81,
                    prevmonth_balance: 461010.11,
                },
                register_time: "2021-08-10T10:28:42.000+00:00",
            },
            value: {
                ActualPL: 500000,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 455420.39,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: 48904.509999999995,
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 1720.67,
                PLLastMonth: -31227.65,
                PLThisMonth: -5734.42,
                PLYesterday: -500000,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 4153,
                Withdrawable: 500000,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 500000,
                MonthlyVolume: 12104789.01,
                "#LotsThisMonth": 228.16,
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -31536.099999999995,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: 44579.61,
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 500000,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 44579.61,
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 500000,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 405,
                InitialMontlhyVolume: 11555135.479999999,
                PLOverallAccCurrency: 455420.39,
                "%ProfitableTotalTrade": 39.056104021189505,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 461154.81,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 39.056104021189505,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 1622,
                PLThisMonthAccCurrency: -5734.42,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 500000,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 500000,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -44579.60999999999,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 492382.46,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 12104789.010000002,
                ClosedPLThisMonthBeforeToday: -5589.719999999999,
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: 144.7,
                MonthlyNetDepositAccCurrency: 44579.61,
                NetDepositsOverallAccCurrency: 44579.61,
                ClosedLotsThisMonthBeforeToday: 228.16,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: -163.75,
                ClosedPLThisYearBeforeThisMonth: -29012.2,
                NetDepositsThisMonthBeforeToday: 44579.61,
                AVGProfitPerClosedTradeThisMonth: -13.801777777777776,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 461154.81,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: 44579.61,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 111421,
            name: "ftd_risk_1019",
            properties: {
                path: ["fx-cubic", "fx-cubic-1", "fxcubic"],
                group: "fxcubic",
                login: 1019,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 128,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "fxcubic",
                    login: 1019,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 455756.93,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-07T18:31:54.458Z",
                    identifier: null,
                    last_login: "2021-08-09T14:33:00.000Z",
                    lead_source: "",
                    prev_equity: 454640.67,
                    prev_balance: 456072.02,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2021-08-09T14:33:00.000Z",
                    prevmonth_equity: 461288.88,
                    prevmonth_balance: 461176.33,
                },
                register_time: "2021-08-09T14:33:00.000+00:00",
            },
            value: {
                ActualPL: 500160.57,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 452935.98,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 1642.72,
                PLLastMonth: -26575.93,
                PLThisMonth: -8352.9,
                PLYesterday: -500000,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 3988,
                Withdrawable: 500160.57,
                "%ChangeEquity": 100,
                ClosedPLToday: 160.57,
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 500160.57,
                MonthlyVolume: 11942880.97,
                "#LotsThisMonth": 215.2,
                ClosedLotsToday: 1.14,
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: -27953.11999999999,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: 10920.2,
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: 47224.59,
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 500000.08,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: 47224.59,
                TotalClosedPLToday: 160.57,
                ActualPLAccCurrency: 500000.08,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: 384,
                InitialMontlhyVolume: 11346689.559999999,
                PLOverallAccCurrency: 452775.49,
                "%ProfitableTotalTrade": 37.30856138589003,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 461288.88,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 37.38716148445336,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 1491,
                PLThisMonthAccCurrency: -8513.39,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 500160.57,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 500000,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: -47224.58999999999,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 487864.81,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 11931960.77,
                ClosedPLThisMonthBeforeToday: -8400.92,
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: 112.55,
                MonthlyNetDepositAccCurrency: 47224.59,
                NetDepositsOverallAccCurrency: 47224.59,
                ClosedLotsThisMonthBeforeToday: 214.06,
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: -1264.64,
                ClosedPLThisYearBeforeThisMonth: -24744.659999999996,
                NetDepositsThisMonthBeforeToday: 47224.59,
                AVGProfitPerClosedTradeThisMonth: -21.459244791666666,
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 461288.88,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: 47224.59,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 111407,
            name: "ftd_risk_1002",
            properties: {
                path: ["fx-cubic", "fx-cubic-1", "fxcubic"],
                group: "fxcubic",
                login: 1002,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 156,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "fxcubic",
                    login: 1002,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 812576.05,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-07T15:04:37.042Z",
                    identifier: null,
                    last_login: "2019-09-20T12:15:21.000Z",
                    lead_source: "",
                    prev_equity: 812554.43,
                    prev_balance: 812554.43,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2019-09-20T12:15:21.000Z",
                    prevmonth_equity: 801692.26,
                    prevmonth_balance: 801692.26,
                },
                register_time: "2019-09-20T12:15:21.000+00:00",
            },
            value: {
                ActualPL: 813655.77,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 813655.77,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 115526.25,
                PLLastMonth: 41176,
                PLThisMonth: 11963.51,
                PLYesterday: -813614.83,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 24693,
                Withdrawable: 813655.77,
                "%ChangeEquity": 100,
                ClosedPLToday: 32.4,
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 813656.97,
                MonthlyVolume: 18324675.42,
                "#LotsThisMonth": 134.63,
                ClosedLotsToday: 1.9,
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: 23530.989999999998,
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: 191607.8,
                CommissionOverall: 20837.92,
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 813655.77,
                CommissionThisYear: 20837.92,
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: 32.4,
                ActualPLAccCurrency: 813655.77,
                CommissionThisMonth: 701.97,
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: 0.56,
                ClosedDealsThisMonth: 51,
                InitialMontlhyVolume: 16163807.869999997,
                PLOverallAccCurrency: 813655.77,
                "%ProfitableTotalTrade": 98.29101364759244,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 801692.26,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 98.29101364759244,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 24271,
                PLThisMonthAccCurrency: 11963.51,
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 813655.77,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 813614.83,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: 243036.5099999999,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 760516.26,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: 18133067.619999997,
                ClosedPLThisMonthBeforeToday: 11221.09,
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: 132.73,
                CommissionThisMonthBeforeToday: 701.48,
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: 37669.62,
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: 220.65666666666667,
                CommissionOverallBeforeThisMonth: 20135.95,
                EquityBeforeThisMonthAccCurrency: 801692.26,
                CommissionThisYearBeforeThisMonth: 20135.95,
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 111409,
            name: "ftd_risk_1010",
            properties: {
                path: ["fx-cubic", "fx-cubic-1", "fxcubic"],
                group: "fxcubic",
                login: 1010,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 160,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "fxcubic",
                    login: 1010,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: getRandomInt(1, 500),
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:36.628Z",
                    identifier: null,
                    last_login: "2021-10-03T21:00:06.000Z",
                    lead_source: "",
                    prev_equity: getRandomInt(1, 500),
                    prev_balance: getRandomInt(1, 500),
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-01-17T10:06:09.000Z",
                    prevmonth_equity: getRandomInt(1, 500),
                    prevmonth_balance: getRandomInt(1, 500),
                },
                register_time: "2020-01-17T10:06:09.000+00:00",
            },
            value: {
                ActualPL: getRandomInt(1, 500),
                OpenLots: getRandomInt(1, 500),
                PLOverall: getRandomInt(1, 500),
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: getRandomInt(1, 500),
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: getRandomInt(1, 500),
                DepositToday: getRandomInt(1, 500),
                OverallDeals: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                "%ChangeEquity": getRandomInt(1, 500),
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: getRandomInt(1, 500),
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: getRandomInt(1, 500),
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: getRandomInt(1, 500),
                "%ProfitableTotalTrade": getRandomInt(1, 500),
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": getRandomInt(1, 500),
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: getRandomInt(1, 500),
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: getRandomInt(1, 500),
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: getRandomInt(1, 500),
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: getRandomInt(1, 500),
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: getRandomInt(1, 500),
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: getRandomInt(1, 500),
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
        {
            id: 111410,
            name: "ftd_risk_1014",
            properties: {
                path: ["fx-cubic", "fx-cubic-1", "fxcubic"],
                group: "fxcubic",
                login: 1014,
                server: "ftd_risk",
                currency: "USD",
                mt_account: {
                    id: 164,
                    city: "",
                    lock: false,
                    name: "name-" + getRandomInt(1, 50000),
                    email: "",
                    group: "fxcubic",
                    login: 1014,
                    phone: "",
                    state: "",
                    credit: getRandomInt(1, 500),
                    enable: true,
                    server: "ftd_risk",
                    status: "",
                    address: "",
                    balance: 1001642.1,
                    comment: "",
                    country: "",
                    last_ip: null,
                    zipcode: "",
                    currency: "USD",
                    leverage: 100,
                    read_only: false,
                    timestamp: "2021-10-06T09:55:36.748Z",
                    identifier: null,
                    last_login: "2020-08-06T06:59:16.000Z",
                    lead_source: "",
                    prev_equity: 1001642.1,
                    prev_balance: 1001642.1,
                    agent_account: getRandomInt(1, 500),
                    register_time: "2020-08-06T06:59:16.000Z",
                    prevmonth_equity: 1001642.1,
                    prevmonth_balance: 1001642.1,
                },
                register_time: "2020-08-06T06:59:16.000+00:00",
            },
            value: {
                ActualPL: 1001642.1,
                OpenLots: getRandomInt(1, 500),
                PLOverall: 1001642.1,
                FloatingPL: getRandomInt(1, 500),
                NetCredits: getRandomInt(1, 500),
                OpenVolume: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                OverallLots: 70.44,
                PLLastMonth: getRandomInt(1, 500),
                PLThisMonth: getRandomInt(1, 500),
                PLYesterday: -1001642.1,
                DepositToday: getRandomInt(1, 500),
                OverallDeals: 150,
                Withdrawable: 1001642.1,
                "%ChangeEquity": 100,
                ClosedPLToday: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: 1001642.1,
                MonthlyVolume: getRandomInt(1, 500),
                "#LotsThisMonth": getRandomInt(1, 500),
                ClosedLotsToday: getRandomInt(1, 500),
                ClosedSwapToday: getRandomInt(1, 500),
                TotalFloatingPL: getRandomInt(1, 500),
                WithdrawalToday: getRandomInt(1, 500),
                YesterdayCredit: getRandomInt(1, 500),
                YesterdayEquity: getRandomInt(1, 500),
                DepositYesterday: getRandomInt(1, 500),
                ClosedPLLastMonth: getRandomInt(1, 500),
                ClosedPLYesterday: getRandomInt(1, 500),
                ClosedVolumeToday: getRandomInt(1, 500),
                CommissionOverall: getRandomInt(1, 500),
                CountOfOpenTrades: getRandomInt(1, 500),
                MonthlyNetCredits: getRandomInt(1, 500),
                MonthlyNetDeposit: getRandomInt(1, 500),
                OverallNetCredits: getRandomInt(1, 500),
                TotalFloatingSwap: getRandomInt(1, 500),
                WdableAccCurrency: 1001642.1,
                CommissionThisYear: getRandomInt(1, 500),
                NetDepositsOverall: getRandomInt(1, 500),
                TotalClosedPLToday: getRandomInt(1, 500),
                ActualPLAccCurrency: 1001642.1,
                CommissionThisMonth: getRandomInt(1, 500),
                CreditEndOfLastYear: getRandomInt(1, 500),
                EquityEndOfLastYear: getRandomInt(1, 500),
                FloatingPLYesterday: getRandomInt(1, 500),
                NetDepositLastMonth: getRandomInt(1, 500),
                SOCompensationToday: getRandomInt(1, 500),
                WithdrawalYesterday: getRandomInt(1, 500),
                YesterdayNetDeposit: getRandomInt(1, 500),
                AgentCommissionToday: getRandomInt(1, 500),
                ClosedDealsThisMonth: getRandomInt(1, 500),
                InitialMontlhyVolume: getRandomInt(1, 500),
                PLOverallAccCurrency: 1001642.1,
                "%ProfitableTotalTrade": 66.66666666666667,
                ClosedVolumeYesterday: getRandomInt(1, 500),
                EquityBeforeThisMonth: 1001642.1,
                SOCompensationOverall: getRandomInt(1, 500),
                WithdrawableYesterday: getRandomInt(1, 500),
                "%ProfitableClosedTrade": 66.66666666666667,
                NetDepositsAccCurrency: getRandomInt(1, 500),
                OverallProfitableDeals: 100,
                PLThisMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationThisYear: getRandomInt(1, 500),
                DepositTodayAccCurrency: getRandomInt(1, 500),
                FloatingPLBeforeNewYear: getRandomInt(1, 500),
                SOCompensationThisMonth: getRandomInt(1, 500),
                CreditEndOfPreviousMonth: getRandomInt(1, 500),
                CurrentCreditAccCurrency: getRandomInt(1, 500),
                CurrentEquityAccCurrency: 1001642.1,
                DayBeforeYesterdayCredit: getRandomInt(1, 500),
                DayBeforeYesterdayEquity: 1001642.1,
                YesterdayCreditAccCredit: getRandomInt(1, 500),
                AVGFloatingPLPerOpenTrade: getRandomInt(1, 500),
                ClosedPLOverallBeforeToday: 3460.1000000000004,
                CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
                EquityEndOfMonthBeforeLast: 1001642.1,
                WithdrawalTodayAccCurrency: getRandomInt(1, 500),
                YesterdayEquityAccCurrency: getRandomInt(1, 500),
                NewMonthlyVolumeBeforeToday: getRandomInt(1, 500),
                ClosedPLThisMonthBeforeToday: getRandomInt(1, 500),
                DayBeforeYesterdayFloatingPL: getRandomInt(1, 500),
                FloatingPLEndOfPreviousMonth: getRandomInt(1, 500),
                MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
                NetDepositsOverallAccCurrency: getRandomInt(1, 500),
                ClosedLotsThisMonthBeforeToday: getRandomInt(1, 500),
                CommissionThisMonthBeforeToday: getRandomInt(1, 500),
                FloatingPLEndOfMonthBeforeLast: getRandomInt(1, 500),
                ClosedPLThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
                AVGProfitPerClosedTradeThisMonth: getRandomInt(1, 500),
                CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
                EquityBeforeThisMonthAccCurrency: 1001642.1,
                CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
                CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
                SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
                SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
                NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
                ClosedPL: getRandomInt(1, 500),
                OpenPL: getRandomInt(1, 500),
                PL: getRandomInt(1, 500),
                CurrentCredit: getRandomInt(1, 500),
                CurrentEquity: getRandomInt(1, 500),
                Withdrawable: getRandomInt(1, 500),
                Commission: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedWin: getRandomInt(1, 500),
                VolTraded: getRandomInt(1, 500),
                ClosedLoss: getRandomInt(1, 500),
                SOCompensation: getRandomInt(1, 500),
                ActualClosedPL: getRandomInt(1, 500),
                Deposits: getRandomInt(1, 500),
                Withdrawals: getRandomInt(1, 500),
                NetDeposits: getRandomInt(1, 500),
                symbol: 40,
                openPL: 10,
                closedPL: 30,
                netPL: 30,
                volumeTraded: 22,
                lotsTraded: 10,
            },
            metric_definition_id: 8,
            metric_definition_name: "CurrentUserStatistics",
        },
    ],
};

mock.onGet("/CurrentUserStatistics/data").reply(() => [200, CurrentUserStatistics.data]);
