import mock from "./mock";

/* eslint-disable import/extensions */

// JWT
import "./jwt";

import "./data/b-book/market-exposure";
import "./data/tables/currentUserStatistics";
import "./data/tables/groupLevels";
import "./data/tables/groupOrganization";
import "./data/b-book/platform-open-positions";
import "./data/b-book/risk-threshold";
import "./data/me";
import "./data/getServerTimezone";
import "./data/getCompanyFavicon";
import "./data/accountSetup";
import "./data/groupOrganization";
import "./data/riskManagement";
import "./data/partners";
import "./data/tradingPlatforms";
import "./data/mtAccount";
import "./data/accountAnalysis";
/* eslint-enable import/extensions */

mock.onAny().passThrough(); // forwards the matched request over network
