import mock from "@/@fake-db/mock";

const Partners = [
    {
        id: 1,
        user_id: 7,
        wallet_id: 6,
        parent_id: null,
        commission_profile_id: 1,
        ib_type: "Direct",
        state: "enabled",
        created_at: "2021-06-04T12:50:53.757Z",
        commission_structure: [],
        commission_type: null,
        level: 0,
        ib_code: 1,
        master_ib: {
            id: 1,
            user_id: 7,
            wallet_id: 6,
            parent_id: null,
            commission_profile_id: 1,
            ib_type: "Direct",
            state: "enabled",
            created_at: "2021-06-04T12:50:53.757Z",
            commission_structure: [],
            commission_type: null,
            level: 0,
            ref_id: "dc671937-c502-402b-981a-24cd23dcb94c",
            ref_type: "Ib",
        },
        new_leads_this_month: 0,
        new_clients_this_month: 0,
        new_funded_this_month: 0,
        wallet: {
            id: 6,
            owner_id: 8,
            owner_type: "Client",
            balance: "1921454.53",
            currency: "USD",
            created_at: "2021-06-04T12:50:32.746Z",
            updated_at: "2022-09-06T00:23:08.100Z",
            amount_locked: "0.0",
            state: "enabled",
            wallet_info: {},
            auto_transfer: false,
        },
        commission_profile: {
            id: 1,
            name: "Standard Type - EU",
            currency: "USD",
            state: "enabled",
            created_at: "2021-06-04T03:43:10.095Z",
            updated_at: "2021-07-06T08:25:10.383Z",
            note: "",
            layer_settings: [
                {
                    groups: {},
                    symbols: {
                        demo: {
                            EURSEK: {
                                id: 3305,
                                name: "EURSEK",
                                type: "Perlot",
                                group: "Forex\\Exotics\\EURSEK",
                                state: "enabled",
                                amount: 10,
                                digits: 5,
                                server: "demo",
                                source: "",
                                symbol: "EURSEK",
                                checked: true,
                                currency: "EUR",
                                treeType: "SYMBOL",
                                symbolName: "EURSEK",
                                description: "Euro vs Swedish Kronor",
                                configuration: {
                                    Trade: 4,
                                    Digits: 5,
                                    Exemode: 2,
                                    SwapLong: -58.51,
                                    SwapShort: -35.06,
                                    MarginMode: 0,
                                    ContractSize: 100000,
                                    CurrencyProfit: "SEK",
                                },
                            },
                            USDJPY: {
                                id: 12930,
                                name: "USDJPY",
                                type: "Perlot",
                                group: "Forex\\Majors\\USDJPY",
                                state: "enabled",
                                amount: 10,
                                digits: 3,
                                server: "demo",
                                source: "",
                                symbol: "USDJPY",
                                checked: true,
                                currency: "USD",
                                treeType: "SYMBOL",
                                symbolName: "USDJPY",
                                description: "US Dollar vs Japanese Yen",
                                configuration: {
                                    Trade: 4,
                                    Digits: 3,
                                    Exemode: 2,
                                    SwapLong: -1.65,
                                    SwapShort: -3.75,
                                    MarginMode: 0,
                                    ContractSize: 100000,
                                    CurrencyProfit: "JPY",
                                },
                            },
                        },
                        live1: {
                            EURSEK: {
                                id: 3005,
                                name: "EURSEK",
                                type: "Perlot",
                                group: "Forex\\Exotics\\EURSEK",
                                state: "enabled",
                                amount: 10,
                                digits: 5,
                                server: "live1",
                                source: "",
                                symbol: "EURSEK",
                                checked: true,
                                currency: "EUR",
                                treeType: "SYMBOL",
                                symbolName: "EURSEK",
                                description: "Euro vs Swedish Kronor",
                                configuration: {
                                    Trade: 4,
                                    Digits: 5,
                                    Exemode: 2,
                                    SwapLong: -58.51,
                                    SwapShort: -35.06,
                                    MarginMode: 0,
                                    ContractSize: 100000,
                                    CurrencyProfit: "SEK",
                                },
                            },
                            USDJPY: {
                                id: 3030,
                                name: "USDJPY",
                                type: "Perlot",
                                group: "Forex\\Majors\\USDJPY",
                                state: "enabled",
                                amount: 10,
                                digits: 3,
                                server: "live1",
                                source: "",
                                symbol: "USDJPY",
                                checked: true,
                                currency: "USD",
                                treeType: "SYMBOL",
                                symbolName: "USDJPY",
                                description: "US Dollar vs Japanese Yen",
                                configuration: {
                                    Trade: 4,
                                    Digits: 3,
                                    Exemode: 2,
                                    SwapLong: -1.65,
                                    SwapShort: -3.75,
                                    MarginMode: 0,
                                    ContractSize: 100000,
                                    CurrencyProfit: "JPY",
                                },
                            },
                        },
                    },
                    is_group_restriction_enabled: false,
                },
            ],
        },
        user: {
            id: 7,
            email: "cuonghv98@gmail.com",
            first_name: "HoangViet",
            middle_name: null,
            last_name: "Cuong",
            personal_info: {
                city: "Hanoi",
                street: "20 Tong Duy Tan",
                hired_at: "2021-06-01",
                nationality: "VN",
                postal_code: 100000,
                mobile_phone: "dsd",
                date_of_birth: "2021-06-01T00:00:00.000+00:00",
                register_step: 6,
                financial_info: {
                    annual_incoming: "10,000 - 100,000",
                    source_of_wealth: "Employment",
                    investment_amount: "500 - 1,000",
                    reason_for_opening: "Investment",
                    estimated_net_worth: "500 - 1,000",
                    us_citizen_or_us_resident: false,
                },
                position_title: "Mr",
                employment_info: {
                    industry: "IT",
                    education_level: "Master",
                    employment_status: "Student",
                },
                trading_experince: {
                    experience: false,
                    number_of_trades: false,
                    years_of_experience: "None",
                },
            },
            dashboard_language: "en",
            email_language: "en",
            state: "enabled",
            created_at: "2021-06-03T10:08:47.049Z",
            updated_at: "2022-09-30T09:51:04.958Z",
            country: "VN",
            settings: {},
            display_currency: "JPY",
            last_change_password: "2021-06-08T16:22:22.341Z",
            change_email_token: null,
            change_email_sent_at: null,
            last_activity: "2022-01-17T04:03:09.856Z",
            report_filters: {},
            referral_link: null,
            referral_params: {},
            full_name: "HoangViet Cuong",
            client: {
                id: 8,
                user_id: 7,
                compliance_id: null,
                category: "Trader",
                client_info: {
                    city: "Hanoi",
                    street: "20 Tong Duy Tan",
                    postal_code: 100000,
                    financial_info: {
                        annual_incoming: "10,000 - 100,000",
                        source_of_wealth: "Employment",
                        investment_amount: "500 - 1,000",
                        reason_for_opening: "Investment",
                        estimated_net_worth: "500 - 1,000",
                        us_citizen_or_us_resident: false,
                    },
                    employment_info: {
                        industry: "IT",
                        education_level: "Master",
                        employment_status: "Student",
                    },
                    trading_experince: {
                        experience: false,
                        number_of_trades: false,
                        years_of_experience: "None",
                    },
                },
                state: "active",
                registration_date: "2021-06-03T10:08:47.049Z",
                created_at: "2021-06-04T07:27:28.341Z",
                updated_at: "2022-09-06T00:23:08.749Z",
                ib_id: null,
                verification_type: null,
                account_manager_id: null,
                auto_transfer: false,
                referral_code: "97da8ff",
                referral_wallet_id: 48,
                is_funded: true,
                funded_at: "2021-06-08T06:40:32.073Z",
            },
        },
        ref_id: "dc671937-c502-402b-981a-24cd23dcb94c",
        ref_type: "Ib",
    },
    {
        id: 3,
        user_id: 6,
        wallet_id: 5,
        parent_id: 1,
        commission_profile_id: 1,
        ib_type: "Sub IB",
        state: "enabled",
        created_at: "2021-06-12T03:47:08.143Z",
        commission_structure: [
            {
                id: 1,
                state: "enabled",
                title: "1 | Hoang Viet  Cuong",
                checked: false,
                children: [
                    {
                        state: "enabled",
                        title: "New IB | Hai  Le",
                        percent_of_amount: 100,
                    },
                ],
                expanded: true,
                percent_of_amount: "35",
            },
        ],
        commission_type: "Flex",
        level: 1,
        ib_code: 3,
        master_ib: {
            id: 1,
            user_id: 7,
            wallet_id: 6,
            parent_id: null,
            commission_profile_id: 1,
            ib_type: "Direct",
            state: "enabled",
            created_at: "2021-06-04T12:50:53.757Z",
            commission_structure: [],
            commission_type: null,
            level: 0,
            ref_id: "dc671937-c502-402b-981a-24cd23dcb94c",
            ref_type: "Ib",
        },
        new_leads_this_month: 0,
        new_clients_this_month: 0,
        new_funded_this_month: 0,
        wallet: {
            id: 5,
            owner_id: 3,
            owner_type: "Client",
            balance: "11000.13",
            currency: "USD",
            created_at: "2021-06-04T08:01:34.211Z",
            updated_at: "2022-09-06T00:23:12.126Z",
            amount_locked: "0.0",
            state: "enabled",
            wallet_info: {},
            auto_transfer: false,
        },
        parent: {
            id: 1,
            user_id: 7,
            wallet_id: 6,
            parent_id: null,
            commission_profile_id: 1,
            ib_type: "Direct",
            state: "enabled",
            created_at: "2021-06-04T12:50:53.757Z",
            commission_structure: [],
            commission_type: null,
            level: 0,
            ib_code: 1,
            master_ib: {
                id: 1,
                user_id: 7,
                wallet_id: 6,
                parent_id: null,
                commission_profile_id: 1,
                ib_type: "Direct",
                state: "enabled",
                created_at: "2021-06-04T12:50:53.757Z",
                commission_structure: [],
                commission_type: null,
                level: 0,
                ref_id: "dc671937-c502-402b-981a-24cd23dcb94c",
                ref_type: "Ib",
            },
            new_leads_this_month: 0,
            new_clients_this_month: 0,
            new_funded_this_month: 0,
            wallet: {
                id: 6,
                owner_id: 8,
                owner_type: "Client",
                balance: "1921454.53",
                currency: "USD",
                created_at: "2021-06-04T12:50:32.746Z",
                updated_at: "2022-09-06T00:23:08.100Z",
                amount_locked: "0.0",
                state: "enabled",
                wallet_info: {},
                auto_transfer: false,
            },
            commission_profile: {
                id: 1,
                name: "Standard Type - EU",
                currency: "USD",
                state: "enabled",
                created_at: "2021-06-04T03:43:10.095Z",
                updated_at: "2021-07-06T08:25:10.383Z",
                note: "",
                layer_settings: [
                    {
                        groups: {},
                        symbols: {
                            demo: {
                                EURSEK: {
                                    id: 3305,
                                    name: "EURSEK",
                                    type: "Perlot",
                                    group: "Forex\\Exotics\\EURSEK",
                                    state: "enabled",
                                    amount: 10,
                                    digits: 5,
                                    server: "demo",
                                    source: "",
                                    symbol: "EURSEK",
                                    checked: true,
                                    currency: "EUR",
                                    treeType: "SYMBOL",
                                    symbolName: "EURSEK",
                                    description: "Euro vs Swedish Kronor",
                                    configuration: {
                                        Trade: 4,
                                        Digits: 5,
                                        Exemode: 2,
                                        SwapLong: -58.51,
                                        SwapShort: -35.06,
                                        MarginMode: 0,
                                        ContractSize: 100000,
                                        CurrencyProfit: "SEK",
                                    },
                                },
                                USDJPY: {
                                    id: 12930,
                                    name: "USDJPY",
                                    type: "Perlot",
                                    group: "Forex\\Majors\\USDJPY",
                                    state: "enabled",
                                    amount: 10,
                                    digits: 3,
                                    server: "demo",
                                    source: "",
                                    symbol: "USDJPY",
                                    checked: true,
                                    currency: "USD",
                                    treeType: "SYMBOL",
                                    symbolName: "USDJPY",
                                    description: "US Dollar vs Japanese Yen",
                                    configuration: {
                                        Trade: 4,
                                        Digits: 3,
                                        Exemode: 2,
                                        SwapLong: -1.65,
                                        SwapShort: -3.75,
                                        MarginMode: 0,
                                        ContractSize: 100000,
                                        CurrencyProfit: "JPY",
                                    },
                                },
                            },
                            live1: {
                                EURSEK: {
                                    id: 3005,
                                    name: "EURSEK",
                                    type: "Perlot",
                                    group: "Forex\\Exotics\\EURSEK",
                                    state: "enabled",
                                    amount: 10,
                                    digits: 5,
                                    server: "live1",
                                    source: "",
                                    symbol: "EURSEK",
                                    checked: true,
                                    currency: "EUR",
                                    treeType: "SYMBOL",
                                    symbolName: "EURSEK",
                                    description: "Euro vs Swedish Kronor",
                                    configuration: {
                                        Trade: 4,
                                        Digits: 5,
                                        Exemode: 2,
                                        SwapLong: -58.51,
                                        SwapShort: -35.06,
                                        MarginMode: 0,
                                        ContractSize: 100000,
                                        CurrencyProfit: "SEK",
                                    },
                                },
                                USDJPY: {
                                    id: 3030,
                                    name: "USDJPY",
                                    type: "Perlot",
                                    group: "Forex\\Majors\\USDJPY",
                                    state: "enabled",
                                    amount: 10,
                                    digits: 3,
                                    server: "live1",
                                    source: "",
                                    symbol: "USDJPY",
                                    checked: true,
                                    currency: "USD",
                                    treeType: "SYMBOL",
                                    symbolName: "USDJPY",
                                    description: "US Dollar vs Japanese Yen",
                                    configuration: {
                                        Trade: 4,
                                        Digits: 3,
                                        Exemode: 2,
                                        SwapLong: -1.65,
                                        SwapShort: -3.75,
                                        MarginMode: 0,
                                        ContractSize: 100000,
                                        CurrencyProfit: "JPY",
                                    },
                                },
                            },
                        },
                        is_group_restriction_enabled: false,
                    },
                ],
            },
            user: {
                id: 7,
                email: "cuonghv98@gmail.com",
                first_name: "HoangViet",
                middle_name: null,
                last_name: "Cuong",
                personal_info: {
                    city: "Hanoi",
                    street: "20 Tong Duy Tan",
                    hired_at: "2021-06-01",
                    nationality: "VN",
                    postal_code: 100000,
                    mobile_phone: "dsd",
                    date_of_birth: "2021-06-01T00:00:00.000+00:00",
                    register_step: 6,
                    financial_info: {
                        annual_incoming: "10,000 - 100,000",
                        source_of_wealth: "Employment",
                        investment_amount: "500 - 1,000",
                        reason_for_opening: "Investment",
                        estimated_net_worth: "500 - 1,000",
                        us_citizen_or_us_resident: false,
                    },
                    position_title: "Mr",
                    employment_info: {
                        industry: "IT",
                        education_level: "Master",
                        employment_status: "Student",
                    },
                    trading_experince: {
                        experience: false,
                        number_of_trades: false,
                        years_of_experience: "None",
                    },
                },
                dashboard_language: "en",
                email_language: "en",
                state: "enabled",
                created_at: "2021-06-03T10:08:47.049Z",
                updated_at: "2022-09-30T09:51:04.958Z",
                country: "VN",
                settings: {},
                display_currency: "JPY",
                last_change_password: "2021-06-08T16:22:22.341Z",
                change_email_token: null,
                change_email_sent_at: null,
                last_activity: "2022-01-17T04:03:09.856Z",
                report_filters: {},
                referral_link: null,
                referral_params: {},
                full_name: "HoangViet Cuong",
                client: {
                    id: 8,
                    user_id: 7,
                    compliance_id: null,
                    category: "Trader",
                    client_info: {
                        city: "Hanoi",
                        street: "20 Tong Duy Tan",
                        postal_code: 100000,
                        financial_info: {
                            annual_incoming: "10,000 - 100,000",
                            source_of_wealth: "Employment",
                            investment_amount: "500 - 1,000",
                            reason_for_opening: "Investment",
                            estimated_net_worth: "500 - 1,000",
                            us_citizen_or_us_resident: false,
                        },
                        employment_info: {
                            industry: "IT",
                            education_level: "Master",
                            employment_status: "Student",
                        },
                        trading_experince: {
                            experience: false,
                            number_of_trades: false,
                            years_of_experience: "None",
                        },
                    },
                    state: "active",
                    registration_date: "2021-06-03T10:08:47.049Z",
                    created_at: "2021-06-04T07:27:28.341Z",
                    updated_at: "2022-09-06T00:23:08.749Z",
                    ib_id: null,
                    verification_type: null,
                    account_manager_id: null,
                    auto_transfer: false,
                    referral_code: "97da8ff",
                    referral_wallet_id: 48,
                    is_funded: true,
                    funded_at: "2021-06-08T06:40:32.073Z",
                },
            },
            ref_id: "dc671937-c502-402b-981a-24cd23dcb94c",
            ref_type: "Ib",
        },
        commission_profile: {
            id: 1,
            name: "Standard Type - EU",
            currency: "USD",
            state: "enabled",
            created_at: "2021-06-04T03:43:10.095Z",
            updated_at: "2021-07-06T08:25:10.383Z",
            note: "",
            layer_settings: [
                {
                    groups: {},
                    symbols: {
                        demo: {
                            EURSEK: {
                                id: 3305,
                                name: "EURSEK",
                                type: "Perlot",
                                group: "Forex\\Exotics\\EURSEK",
                                state: "enabled",
                                amount: 10,
                                digits: 5,
                                server: "demo",
                                source: "",
                                symbol: "EURSEK",
                                checked: true,
                                currency: "EUR",
                                treeType: "SYMBOL",
                                symbolName: "EURSEK",
                                description: "Euro vs Swedish Kronor",
                                configuration: {
                                    Trade: 4,
                                    Digits: 5,
                                    Exemode: 2,
                                    SwapLong: -58.51,
                                    SwapShort: -35.06,
                                    MarginMode: 0,
                                    ContractSize: 100000,
                                    CurrencyProfit: "SEK",
                                },
                            },
                            USDJPY: {
                                id: 12930,
                                name: "USDJPY",
                                type: "Perlot",
                                group: "Forex\\Majors\\USDJPY",
                                state: "enabled",
                                amount: 10,
                                digits: 3,
                                server: "demo",
                                source: "",
                                symbol: "USDJPY",
                                checked: true,
                                currency: "USD",
                                treeType: "SYMBOL",
                                symbolName: "USDJPY",
                                description: "US Dollar vs Japanese Yen",
                                configuration: {
                                    Trade: 4,
                                    Digits: 3,
                                    Exemode: 2,
                                    SwapLong: -1.65,
                                    SwapShort: -3.75,
                                    MarginMode: 0,
                                    ContractSize: 100000,
                                    CurrencyProfit: "JPY",
                                },
                            },
                        },
                        live1: {
                            EURSEK: {
                                id: 3005,
                                name: "EURSEK",
                                type: "Perlot",
                                group: "Forex\\Exotics\\EURSEK",
                                state: "enabled",
                                amount: 10,
                                digits: 5,
                                server: "live1",
                                source: "",
                                symbol: "EURSEK",
                                checked: true,
                                currency: "EUR",
                                treeType: "SYMBOL",
                                symbolName: "EURSEK",
                                description: "Euro vs Swedish Kronor",
                                configuration: {
                                    Trade: 4,
                                    Digits: 5,
                                    Exemode: 2,
                                    SwapLong: -58.51,
                                    SwapShort: -35.06,
                                    MarginMode: 0,
                                    ContractSize: 100000,
                                    CurrencyProfit: "SEK",
                                },
                            },
                            USDJPY: {
                                id: 3030,
                                name: "USDJPY",
                                type: "Perlot",
                                group: "Forex\\Majors\\USDJPY",
                                state: "enabled",
                                amount: 10,
                                digits: 3,
                                server: "live1",
                                source: "",
                                symbol: "USDJPY",
                                checked: true,
                                currency: "USD",
                                treeType: "SYMBOL",
                                symbolName: "USDJPY",
                                description: "US Dollar vs Japanese Yen",
                                configuration: {
                                    Trade: 4,
                                    Digits: 3,
                                    Exemode: 2,
                                    SwapLong: -1.65,
                                    SwapShort: -3.75,
                                    MarginMode: 0,
                                    ContractSize: 100000,
                                    CurrencyProfit: "JPY",
                                },
                            },
                        },
                    },
                    is_group_restriction_enabled: false,
                },
            ],
        },
        user: {
            id: 6,
            email: "hai98th@gmail.com",
            first_name: "Hai",
            middle_name: null,
            last_name: "Le",
            personal_info: {
                city: "Ha Noi",
                street: "543 Giai Phong",
                nationality: "VN",
                postal_code: 123456,
                mobile_phone: "+84 326 264 271",
                date_of_birth: "2003-04-05T00:00:00.000Z",
                register_step: 6,
                financial_info: {
                    annual_incoming: "10,000 - 100,000",
                    source_of_wealth: "Employment",
                    investment_amount: "500 - 1,000",
                    reason_for_opening: "Investment",
                    estimated_net_worth: "500 - 1,000",
                    us_citizen_or_us_resident: false,
                },
                state_province: "Ha Noi",
                employment_info: {
                    industry: "IT",
                    education_level: "High School",
                    employment_status: "Student",
                },
                trading_experince: {
                    experience: "Yes",
                    number_of_trades: false,
                    years_of_experience: "None",
                },
            },
            dashboard_language: "en",
            email_language: "en",
            state: "enabled",
            created_at: "2021-06-03T10:05:38.237Z",
            updated_at: "2022-06-10T13:57:34.883Z",
            country: "VN",
            settings: {
                is_sms_auth_enable: false,
                is_google_auth_enable: false,
                is_fiat_trading_disable: false,
            },
            display_currency: "USD",
            last_change_password: "2021-11-12T08:28:13.042Z",
            change_email_token: null,
            change_email_sent_at: null,
            last_activity: "2022-06-10T13:57:13.707Z",
            report_filters: {},
            referral_link: null,
            referral_params: {},
            full_name: "Hai Le",
            client: {
                id: 3,
                user_id: 6,
                compliance_id: null,
                category: "Trader",
                client_info: {
                    city: "Ha Noi",
                    street: "543 Giai Phong",
                    postal_code: 123456,
                    financial_info: {
                        annual_incoming: "10,000 - 100,000",
                        source_of_wealth: "Employment",
                        investment_amount: "500 - 1,000",
                        reason_for_opening: "Investment",
                        estimated_net_worth: "500 - 1,000",
                        us_citizen_or_us_resident: false,
                    },
                    previous_state: "archived",
                    employment_info: {
                        industry: "IT",
                        education_level: "High School",
                        employment_status: "Student",
                    },
                    trading_experince: {
                        experience: false,
                        number_of_trades: false,
                        years_of_experience: "None",
                    },
                },
                state: "active",
                registration_date: "2021-06-03T10:05:38.237Z",
                created_at: "2021-06-03T10:25:41.693Z",
                updated_at: "2022-09-06T00:23:12.256Z",
                ib_id: null,
                verification_type: null,
                account_manager_id: null,
                auto_transfer: false,
                referral_code: "dd2a00",
                referral_wallet_id: 36,
                is_funded: true,
                funded_at: "2021-06-04T08:18:33.712Z",
            },
        },
        ref_id: "36ca6d7c-ef8e-4235-aacb-b1b53da4c33b",
        ref_type: "Ib",
    },
    {
        id: 4,
        user_id: 12,
        wallet_id: 10,
        parent_id: 1,
        commission_profile_id: 2,
        ib_type: "Sub IB",
        state: "enabled",
        created_at: "2021-06-16T04:48:38.873Z",
        commission_structure: [
            {
                id: 1,
                state: "enabled",
                title: "1 | Hoang Viet  Cuong",
                checked: false,
                children: [
                    {
                        state: "enabled",
                        title: "New IB | Le  Phong",
                        checked: false,
                        percent_of_amount: 100,
                    },
                ],
                expanded: true,
                percent_of_amount: 10,
            },
        ],
        commission_type: "Flex",
        level: 1,
        ib_code: 4,
        master_ib: {
            id: 1,
            user_id: 7,
            wallet_id: 6,
            parent_id: null,
            commission_profile_id: 1,
            ib_type: "Direct",
            state: "enabled",
            created_at: "2021-06-04T12:50:53.757Z",
            commission_structure: [],
            commission_type: null,
            level: 0,
            ref_id: "dc671937-c502-402b-981a-24cd23dcb94c",
            ref_type: "Ib",
        },
        new_leads_this_month: 0,
        new_clients_this_month: 0,
        new_funded_this_month: 0,
        wallet: {
            id: 10,
            owner_id: 7,
            owner_type: "Client",
            balance: "57368.0",
            currency: "JPY",
            created_at: "2021-06-05T06:14:24.607Z",
            updated_at: "2022-09-06T00:23:10.510Z",
            amount_locked: "0.0",
            state: "enabled",
            wallet_info: {},
            auto_transfer: false,
        },
        parent: {
            id: 1,
            user_id: 7,
            wallet_id: 6,
            parent_id: null,
            commission_profile_id: 1,
            ib_type: "Direct",
            state: "enabled",
            created_at: "2021-06-04T12:50:53.757Z",
            commission_structure: [],
            commission_type: null,
            level: 0,
            ib_code: 1,
            master_ib: {
                id: 1,
                user_id: 7,
                wallet_id: 6,
                parent_id: null,
                commission_profile_id: 1,
                ib_type: "Direct",
                state: "enabled",
                created_at: "2021-06-04T12:50:53.757Z",
                commission_structure: [],
                commission_type: null,
                level: 0,
                ref_id: "dc671937-c502-402b-981a-24cd23dcb94c",
                ref_type: "Ib",
            },
            new_leads_this_month: 0,
            new_clients_this_month: 0,
            new_funded_this_month: 0,
            wallet: {
                id: 6,
                owner_id: 8,
                owner_type: "Client",
                balance: "1921454.53",
                currency: "USD",
                created_at: "2021-06-04T12:50:32.746Z",
                updated_at: "2022-09-06T00:23:08.100Z",
                amount_locked: "0.0",
                state: "enabled",
                wallet_info: {},
                auto_transfer: false,
            },
            commission_profile: {
                id: 1,
                name: "Standard Type - EU",
                currency: "USD",
                state: "enabled",
                created_at: "2021-06-04T03:43:10.095Z",
                updated_at: "2021-07-06T08:25:10.383Z",
                note: "",
                layer_settings: [
                    {
                        groups: {},
                        symbols: {
                            demo: {
                                EURSEK: {
                                    id: 3305,
                                    name: "EURSEK",
                                    type: "Perlot",
                                    group: "Forex\\Exotics\\EURSEK",
                                    state: "enabled",
                                    amount: 10,
                                    digits: 5,
                                    server: "demo",
                                    source: "",
                                    symbol: "EURSEK",
                                    checked: true,
                                    currency: "EUR",
                                    treeType: "SYMBOL",
                                    symbolName: "EURSEK",
                                    description: "Euro vs Swedish Kronor",
                                    configuration: {
                                        Trade: 4,
                                        Digits: 5,
                                        Exemode: 2,
                                        SwapLong: -58.51,
                                        SwapShort: -35.06,
                                        MarginMode: 0,
                                        ContractSize: 100000,
                                        CurrencyProfit: "SEK",
                                    },
                                },
                                USDJPY: {
                                    id: 12930,
                                    name: "USDJPY",
                                    type: "Perlot",
                                    group: "Forex\\Majors\\USDJPY",
                                    state: "enabled",
                                    amount: 10,
                                    digits: 3,
                                    server: "demo",
                                    source: "",
                                    symbol: "USDJPY",
                                    checked: true,
                                    currency: "USD",
                                    treeType: "SYMBOL",
                                    symbolName: "USDJPY",
                                    description: "US Dollar vs Japanese Yen",
                                    configuration: {
                                        Trade: 4,
                                        Digits: 3,
                                        Exemode: 2,
                                        SwapLong: -1.65,
                                        SwapShort: -3.75,
                                        MarginMode: 0,
                                        ContractSize: 100000,
                                        CurrencyProfit: "JPY",
                                    },
                                },
                            },
                            live1: {
                                EURSEK: {
                                    id: 3005,
                                    name: "EURSEK",
                                    type: "Perlot",
                                    group: "Forex\\Exotics\\EURSEK",
                                    state: "enabled",
                                    amount: 10,
                                    digits: 5,
                                    server: "live1",
                                    source: "",
                                    symbol: "EURSEK",
                                    checked: true,
                                    currency: "EUR",
                                    treeType: "SYMBOL",
                                    symbolName: "EURSEK",
                                    description: "Euro vs Swedish Kronor",
                                    configuration: {
                                        Trade: 4,
                                        Digits: 5,
                                        Exemode: 2,
                                        SwapLong: -58.51,
                                        SwapShort: -35.06,
                                        MarginMode: 0,
                                        ContractSize: 100000,
                                        CurrencyProfit: "SEK",
                                    },
                                },
                                USDJPY: {
                                    id: 3030,
                                    name: "USDJPY",
                                    type: "Perlot",
                                    group: "Forex\\Majors\\USDJPY",
                                    state: "enabled",
                                    amount: 10,
                                    digits: 3,
                                    server: "live1",
                                    source: "",
                                    symbol: "USDJPY",
                                    checked: true,
                                    currency: "USD",
                                    treeType: "SYMBOL",
                                    symbolName: "USDJPY",
                                    description: "US Dollar vs Japanese Yen",
                                    configuration: {
                                        Trade: 4,
                                        Digits: 3,
                                        Exemode: 2,
                                        SwapLong: -1.65,
                                        SwapShort: -3.75,
                                        MarginMode: 0,
                                        ContractSize: 100000,
                                        CurrencyProfit: "JPY",
                                    },
                                },
                            },
                        },
                        is_group_restriction_enabled: false,
                    },
                ],
            },
            user: {
                id: 7,
                email: "cuonghv98@gmail.com",
                first_name: "HoangViet",
                middle_name: null,
                last_name: "Cuong",
                personal_info: {
                    city: "Hanoi",
                    street: "20 Tong Duy Tan",
                    hired_at: "2021-06-01",
                    nationality: "VN",
                    postal_code: 100000,
                    mobile_phone: "dsd",
                    date_of_birth: "2021-06-01T00:00:00.000+00:00",
                    register_step: 6,
                    financial_info: {
                        annual_incoming: "10,000 - 100,000",
                        source_of_wealth: "Employment",
                        investment_amount: "500 - 1,000",
                        reason_for_opening: "Investment",
                        estimated_net_worth: "500 - 1,000",
                        us_citizen_or_us_resident: false,
                    },
                    position_title: "Mr",
                    employment_info: {
                        industry: "IT",
                        education_level: "Master",
                        employment_status: "Student",
                    },
                    trading_experince: {
                        experience: false,
                        number_of_trades: false,
                        years_of_experience: "None",
                    },
                },
                dashboard_language: "en",
                email_language: "en",
                state: "enabled",
                created_at: "2021-06-03T10:08:47.049Z",
                updated_at: "2022-09-30T09:51:04.958Z",
                country: "VN",
                settings: {},
                display_currency: "JPY",
                last_change_password: "2021-06-08T16:22:22.341Z",
                change_email_token: null,
                change_email_sent_at: null,
                last_activity: "2022-01-17T04:03:09.856Z",
                report_filters: {},
                referral_link: null,
                referral_params: {},
                full_name: "HoangViet Cuong",
                client: {
                    id: 8,
                    user_id: 7,
                    compliance_id: null,
                    category: "Trader",
                    client_info: {
                        city: "Hanoi",
                        street: "20 Tong Duy Tan",
                        postal_code: 100000,
                        financial_info: {
                            annual_incoming: "10,000 - 100,000",
                            source_of_wealth: "Employment",
                            investment_amount: "500 - 1,000",
                            reason_for_opening: "Investment",
                            estimated_net_worth: "500 - 1,000",
                            us_citizen_or_us_resident: false,
                        },
                        employment_info: {
                            industry: "IT",
                            education_level: "Master",
                            employment_status: "Student",
                        },
                        trading_experince: {
                            experience: false,
                            number_of_trades: false,
                            years_of_experience: "None",
                        },
                    },
                    state: "active",
                    registration_date: "2021-06-03T10:08:47.049Z",
                    created_at: "2021-06-04T07:27:28.341Z",
                    updated_at: "2022-09-06T00:23:08.749Z",
                    ib_id: null,
                    verification_type: null,
                    account_manager_id: null,
                    auto_transfer: false,
                    referral_code: "97da8ff",
                    referral_wallet_id: 48,
                    is_funded: true,
                    funded_at: "2021-06-08T06:40:32.073Z",
                },
            },
            ref_id: "dc671937-c502-402b-981a-24cd23dcb94c",
            ref_type: "Ib",
        },
        commission_profile: {
            id: 2,
            name: "Test",
            currency: "USD",
            state: "enabled",
            created_at: "2021-06-08T04:44:26.180Z",
            updated_at: "2021-07-14T09:04:25.214Z",
            note: "",
            layer_settings: [
                {
                    groups: {},
                    symbols: {
                        live1: {
                            USDJPY: {
                                id: 3030,
                                name: "USDJPY",
                                type: "Perlot",
                                group: "Forex\\Majors\\USDJPY",
                                state: "enabled",
                                amount: 5,
                                digits: 3,
                                server: "live1",
                                source: "",
                                symbol: "USDJPY",
                                checked: true,
                                currency: "USD",
                                treeType: "SYMBOL",
                                symbolName: "USDJPY",
                                description: "US Dollar vs Japanese Yen",
                                configuration: {
                                    Trade: 4,
                                    Digits: 3,
                                    Exemode: 2,
                                    SwapLong: -1.65,
                                    SwapShort: -3.75,
                                    MarginMode: 0,
                                    ContractSize: 100000,
                                    CurrencyProfit: "JPY",
                                },
                            },
                        },
                    },
                    is_group_restriction_enabled: false,
                },
            ],
        },
        user: {
            id: 12,
            email: "phong.le@eaera.com",
            first_name: "Le",
            middle_name: null,
            last_name: "Phong",
            personal_info: {
                city: "def gh",
                street: "123",
                hired_at: "",
                nationality: "BO",
                postal_code: 456,
                mobile_phone: "+84 98 805 05 01",
                date_of_birth: "2021-01-20T00:00:00.000+00:00",
                register_step: "2",
                financial_info: {
                    annual_incoming: "10,000 - 100,000",
                    source_of_wealth: "Employment",
                    investment_amount: "100,000+",
                    reason_for_opening: "Investment",
                    estimated_net_worth: "50,000 - 100,000",
                    us_citizen_or_us_resident: false,
                },
                position_title: "bug fixer",
                state_province: "abc",
                employment_info: {
                    industry: "IT",
                    education_level: "Bachelor",
                    employment_status: "Student",
                },
                trading_experince: {
                    experience: "No",
                    number_of_trades: "Yes",
                    years_of_experience: "0-1 year",
                },
            },
            dashboard_language: "en",
            email_language: "en",
            state: "enabled",
            created_at: "2021-06-04T02:13:55.177Z",
            updated_at: "2022-09-12T07:07:47.180Z",
            country: "VN",
            settings: {
                is_sms_auth_enable: false,
                is_google_auth_enable: false,
            },
            display_currency: "USD",
            last_change_password: "2022-06-14T04:20:24.300Z",
            change_email_token: null,
            change_email_sent_at: null,
            last_activity: "2022-09-05T07:50:04.098Z",
            report_filters: {},
            referral_link: null,
            referral_params: {},
            full_name: "Le Phong",
            client: {
                id: 7,
                user_id: 12,
                compliance_id: null,
                category: "Trader",
                client_info: {
                    city: "Hanoi",
                    street: "20 Tong Duy Tan",
                    postal_code: 100000,
                    financial_info: {
                        annual_incoming: "10,000 - 100,000",
                        source_of_wealth: "Employment",
                        investment_amount: "100,000+",
                        reason_for_opening: "Investment",
                        estimated_net_worth: "50,000 - 100,000",
                        us_citizen_or_us_resident: false,
                    },
                    previous_state: "archived",
                    employment_info: {
                        industry: "IT",
                        education_level: "Bachelor",
                        employment_status: "Student",
                    },
                    trading_experince: {
                        experience: false,
                        number_of_trades: false,
                        years_of_experience: "None",
                    },
                },
                state: "active",
                registration_date: "2021-06-04T02:13:55.177Z",
                created_at: "2021-06-04T05:32:38.277Z",
                updated_at: "2022-09-06T00:23:10.581Z",
                ib_id: 5,
                verification_type: null,
                account_manager_id: 113,
                auto_transfer: false,
                referral_code: "4228fc3",
                referral_wallet_id: 9,
                is_funded: true,
                funded_at: "2021-06-05T06:21:09.144Z",
            },
        },
        ref_id: "07da5d0d-2999-4cfb-99f0-1ee9182e633b",
        ref_type: "Ib",
    },
    {
        id: 5,
        user_id: 9,
        wallet_id: 7,
        parent_id: null,
        commission_profile_id: 2,
        ib_type: "Direct",
        state: "enabled",
        created_at: "2021-06-16T06:38:19.166Z",
        commission_structure: [],
        commission_type: null,
        level: 0,
        ib_code: 5,
        master_ib: {
            id: 5,
            user_id: 9,
            wallet_id: 7,
            parent_id: null,
            commission_profile_id: 2,
            ib_type: "Direct",
            state: "enabled",
            created_at: "2021-06-16T06:38:19.166Z",
            commission_structure: [],
            commission_type: null,
            level: 0,
            ref_id: "d9949b4d-5e2f-40ea-9753-2c6f157de0ba",
            ref_type: "Ib",
        },
        new_leads_this_month: 0,
        new_clients_this_month: 0,
        new_funded_this_month: 0,
        wallet: {
            id: 7,
            owner_id: 12,
            owner_type: "Client",
            balance: "9573.69",
            currency: "USD",
            created_at: "2021-06-05T02:49:45.002Z",
            updated_at: "2022-09-06T00:23:20.845Z",
            amount_locked: "0.0",
            state: "enabled",
            wallet_info: {},
            auto_transfer: false,
        },
        commission_profile: {
            id: 2,
            name: "Test",
            currency: "USD",
            state: "enabled",
            created_at: "2021-06-08T04:44:26.180Z",
            updated_at: "2021-07-14T09:04:25.214Z",
            note: "",
            layer_settings: [
                {
                    groups: {},
                    symbols: {
                        live1: {
                            USDJPY: {
                                id: 3030,
                                name: "USDJPY",
                                type: "Perlot",
                                group: "Forex\\Majors\\USDJPY",
                                state: "enabled",
                                amount: 5,
                                digits: 3,
                                server: "live1",
                                source: "",
                                symbol: "USDJPY",
                                checked: true,
                                currency: "USD",
                                treeType: "SYMBOL",
                                symbolName: "USDJPY",
                                description: "US Dollar vs Japanese Yen",
                                configuration: {
                                    Trade: 4,
                                    Digits: 3,
                                    Exemode: 2,
                                    SwapLong: -1.65,
                                    SwapShort: -3.75,
                                    MarginMode: 0,
                                    ContractSize: 100000,
                                    CurrencyProfit: "JPY",
                                },
                            },
                        },
                    },
                    is_group_restriction_enabled: false,
                },
            ],
        },
        user: {
            id: 9,
            email: "vytedoit@gmail.com",
            first_name: "Thinh",
            middle_name: null,
            last_name: "Nguyen",
            personal_info: {
                city: "Hanoi",
                street: "Hang Bong 22",
                hired_at: "2021-06-24",
                nationality: "VN",
                postal_code: 100000,
                mobile_phone: "453453463454",
                date_of_birth: "2021-06-01T00:00:00.000+00:00",
                register_step: 6,
                financial_info: {
                    annual_incoming: "100,000 - 500,000",
                    source_of_wealth: "Employment",
                    investment_amount: "500 - 1,000",
                    reason_for_opening: "Investment",
                    estimated_net_worth: "500 - 1,000",
                    us_citizen_or_us_resident: false,
                },
                position_title: "Mr",
                state_province: "Hoan Kiem",
                employment_info: {
                    industry: "Agriculture",
                    education_level: "High School",
                    employment_status: "Student",
                },
                trading_experince: {
                    experience: false,
                    number_of_trades: false,
                    years_of_experience: "3+ years",
                },
            },
            dashboard_language: "en",
            email_language: "vi",
            state: "enabled",
            created_at: "2021-06-04T01:19:31.863Z",
            updated_at: "2022-08-12T10:35:50.858Z",
            country: "VN",
            settings: {
                is_sms_auth_enable: false,
                is_google_auth_enable: false,
            },
            display_currency: "EUR",
            last_change_password: null,
            change_email_token: null,
            change_email_sent_at: null,
            last_activity: "2021-07-07T07:45:31.801Z",
            report_filters: {},
            referral_link: null,
            referral_params: {},
            full_name: "Thinh Nguyen",
            client: {
                id: 12,
                user_id: 9,
                compliance_id: null,
                category: "Trader",
                client_info: {
                    city: "Hanoi",
                    street: "Hang Bong 22",
                    postal_code: 100000,
                    financial_info: {
                        annual_incoming: "100,000 - 500,000",
                        source_of_wealth: "Employment",
                        investment_amount: "500 - 1,000",
                        reason_for_opening: "Investment",
                        estimated_net_worth: "500 - 1,000",
                        us_citizen_or_us_resident: false,
                    },
                    state_province: "Hanoi",
                    employment_info: {
                        industry: "Agriculture",
                        education_level: "High School",
                        employment_status: "Student",
                    },
                    trading_experince: {
                        experience: false,
                        number_of_trades: false,
                        years_of_experience: "3+ years",
                    },
                },
                state: "active",
                registration_date: "2021-06-04T01:19:31.863Z",
                created_at: "2021-06-05T02:48:54.232Z",
                updated_at: "2022-09-06T00:23:20.937Z",
                ib_id: null,
                verification_type: null,
                account_manager_id: 113,
                auto_transfer: false,
                referral_code: "3eb974",
                referral_wallet_id: 7,
                is_funded: true,
                funded_at: "2021-06-05T02:54:44.484Z",
            },
        },
        ref_id: "d9949b4d-5e2f-40ea-9753-2c6f157de0ba",
        ref_type: "Ib",
    },
    {
        id: 7,
        user_id: 13,
        wallet_id: 4,
        parent_id: null,
        commission_profile_id: 1,
        ib_type: "Direct",
        state: "enabled",
        created_at: "2021-07-05T02:44:39.223Z",
        commission_structure: [],
        commission_type: null,
        level: 0,
        ib_code: 7,
        master_ib: {
            id: 7,
            user_id: 13,
            wallet_id: 4,
            parent_id: null,
            commission_profile_id: 1,
            ib_type: "Direct",
            state: "enabled",
            created_at: "2021-07-05T02:44:39.223Z",
            commission_structure: [],
            commission_type: null,
            level: 0,
            ref_id: "04e2c864-4067-4ede-8d55-ed9c82e0e347",
            ref_type: "Ib",
        },
        new_leads_this_month: 0,
        new_clients_this_month: 0,
        new_funded_this_month: 0,
        wallet: {
            id: 4,
            owner_id: 5,
            owner_type: "Client",
            balance: "52165.0",
            currency: "USD",
            created_at: "2021-06-04T05:15:13.156Z",
            updated_at: "2022-09-06T00:23:17.027Z",
            amount_locked: "0.0",
            state: "enabled",
            wallet_info: {},
            auto_transfer: false,
        },
        commission_profile: {
            id: 1,
            name: "Standard Type - EU",
            currency: "USD",
            state: "enabled",
            created_at: "2021-06-04T03:43:10.095Z",
            updated_at: "2021-07-06T08:25:10.383Z",
            note: "",
            layer_settings: [
                {
                    groups: {},
                    symbols: {
                        demo: {
                            EURSEK: {
                                id: 3305,
                                name: "EURSEK",
                                type: "Perlot",
                                group: "Forex\\Exotics\\EURSEK",
                                state: "enabled",
                                amount: 10,
                                digits: 5,
                                server: "demo",
                                source: "",
                                symbol: "EURSEK",
                                checked: true,
                                currency: "EUR",
                                treeType: "SYMBOL",
                                symbolName: "EURSEK",
                                description: "Euro vs Swedish Kronor",
                                configuration: {
                                    Trade: 4,
                                    Digits: 5,
                                    Exemode: 2,
                                    SwapLong: -58.51,
                                    SwapShort: -35.06,
                                    MarginMode: 0,
                                    ContractSize: 100000,
                                    CurrencyProfit: "SEK",
                                },
                            },
                            USDJPY: {
                                id: 12930,
                                name: "USDJPY",
                                type: "Perlot",
                                group: "Forex\\Majors\\USDJPY",
                                state: "enabled",
                                amount: 10,
                                digits: 3,
                                server: "demo",
                                source: "",
                                symbol: "USDJPY",
                                checked: true,
                                currency: "USD",
                                treeType: "SYMBOL",
                                symbolName: "USDJPY",
                                description: "US Dollar vs Japanese Yen",
                                configuration: {
                                    Trade: 4,
                                    Digits: 3,
                                    Exemode: 2,
                                    SwapLong: -1.65,
                                    SwapShort: -3.75,
                                    MarginMode: 0,
                                    ContractSize: 100000,
                                    CurrencyProfit: "JPY",
                                },
                            },
                        },
                        live1: {
                            EURSEK: {
                                id: 3005,
                                name: "EURSEK",
                                type: "Perlot",
                                group: "Forex\\Exotics\\EURSEK",
                                state: "enabled",
                                amount: 10,
                                digits: 5,
                                server: "live1",
                                source: "",
                                symbol: "EURSEK",
                                checked: true,
                                currency: "EUR",
                                treeType: "SYMBOL",
                                symbolName: "EURSEK",
                                description: "Euro vs Swedish Kronor",
                                configuration: {
                                    Trade: 4,
                                    Digits: 5,
                                    Exemode: 2,
                                    SwapLong: -58.51,
                                    SwapShort: -35.06,
                                    MarginMode: 0,
                                    ContractSize: 100000,
                                    CurrencyProfit: "SEK",
                                },
                            },
                            USDJPY: {
                                id: 3030,
                                name: "USDJPY",
                                type: "Perlot",
                                group: "Forex\\Majors\\USDJPY",
                                state: "enabled",
                                amount: 10,
                                digits: 3,
                                server: "live1",
                                source: "",
                                symbol: "USDJPY",
                                checked: true,
                                currency: "USD",
                                treeType: "SYMBOL",
                                symbolName: "USDJPY",
                                description: "US Dollar vs Japanese Yen",
                                configuration: {
                                    Trade: 4,
                                    Digits: 3,
                                    Exemode: 2,
                                    SwapLong: -1.65,
                                    SwapShort: -3.75,
                                    MarginMode: 0,
                                    ContractSize: 100000,
                                    CurrencyProfit: "JPY",
                                },
                            },
                        },
                    },
                    is_group_restriction_enabled: false,
                },
            ],
        },
        user: {
            id: 13,
            email: "nhung.nguyen@eaera.com",
            first_name: "Nhung",
            middle_name: null,
            last_name: "Nguyen",
            personal_info: {
                city: "Hanoi",
                street: "20 Tong Duy Tan",
                hired_at: "",
                por_type: "Utility Bill",
                nationality: "VN",
                postal_code: 100000,
                mobile_phone: "+84 329 230 560",
                date_of_birth: "1993-10-10T00:00:00.000+00:00",
                register_step: 6,
                financial_info: {
                    annual_incoming: "10,000 - 100,000",
                    source_of_wealth: "Employment",
                    investment_amount: "500 - 1,000",
                    reason_for_opening: "Other",
                    estimated_net_worth: "500 - 1,000",
                    us_citizen_or_us_resident: false,
                },
                por_issue_date: null,
                position_title: "Admin Assistant",
                state_province: "Ba Dinh",
                employment_info: {
                    industry: "Other",
                    education_level: "Bachelor",
                    employment_status: "Employed",
                },
                trading_experince: {
                    experience: false,
                    number_of_trades: false,
                    years_of_experience: "None",
                },
            },
            dashboard_language: "en",
            email_language: "en",
            state: "enabled",
            created_at: "2021-06-04T02:46:33.040Z",
            updated_at: "2021-11-10T07:45:16.890Z",
            country: "VN",
            settings: {
                is_sms_auth_enable: false,
                is_google_auth_enable: false,
            },
            display_currency: "JPY",
            last_change_password: null,
            change_email_token: null,
            change_email_sent_at: null,
            last_activity: "2021-11-10T07:45:16.881Z",
            report_filters: {},
            referral_link: null,
            referral_params: {},
            full_name: "Nhung Nguyen",
            client: {
                id: 5,
                user_id: 13,
                compliance_id: null,
                category: "Trader",
                client_info: {
                    city: "Hanoi",
                    street: "20 Tong Duy Tan",
                    postal_code: 100000,
                    financial_info: {
                        annual_incoming: "10,000 - 100,000",
                        source_of_wealth: "Employment",
                        investment_amount: "500 - 1,000",
                        reason_for_opening: "Other",
                        estimated_net_worth: "500 - 1,000",
                        us_citizen_or_us_resident: false,
                    },
                    employment_info: {
                        industry: "Other",
                        education_level: "Bachelor",
                        employment_status: "Employed",
                    },
                    trading_experince: {
                        experience: false,
                        number_of_trades: false,
                        years_of_experience: "None",
                    },
                },
                state: "active",
                registration_date: "2021-06-04T02:46:33.040Z",
                created_at: "2021-06-04T03:53:18.616Z",
                updated_at: "2022-09-06T00:23:17.075Z",
                ib_id: 4,
                verification_type: null,
                account_manager_id: 88,
                auto_transfer: false,
                referral_code: "d18b31e",
                referral_wallet_id: 4,
                is_funded: true,
                funded_at: "2021-06-04T10:35:59.850Z",
            },
        },
        ref_id: "04e2c864-4067-4ede-8d55-ed9c82e0e347",
        ref_type: "Ib",
    },
    {
        id: 8,
        user_id: 92,
        wallet_id: 43,
        parent_id: null,
        commission_profile_id: 3,
        ib_type: "Direct",
        state: "enabled",
        created_at: "2021-07-05T07:05:17.086Z",
        commission_structure: [],
        commission_type: null,
        level: 0,
        ib_code: 8,
        master_ib: {
            id: 8,
            user_id: 92,
            wallet_id: 43,
            parent_id: null,
            commission_profile_id: 3,
            ib_type: "Direct",
            state: "enabled",
            created_at: "2021-07-05T07:05:17.086Z",
            commission_structure: [],
            commission_type: null,
            level: 0,
            ref_id: "9b24cc04-3d16-4eb7-8eb0-d9ab2661c598",
            ref_type: "Ib",
        },
        new_leads_this_month: 0,
        new_clients_this_month: 0,
        new_funded_this_month: 0,
        wallet: {
            id: 43,
            owner_id: 30,
            owner_type: "Client",
            balance: "50389.48",
            currency: "EUR",
            created_at: "2021-06-29T03:25:22.044Z",
            updated_at: "2022-09-06T00:23:11.385Z",
            amount_locked: "0.0",
            state: "enabled",
            wallet_info: {},
            auto_transfer: false,
        },
        commission_profile: {
            id: 3,
            name: "1Test",
            currency: "USD",
            state: "enabled",
            created_at: "2021-06-08T04:44:44.880Z",
            updated_at: "2021-06-17T02:38:20.348Z",
            note: "",
            layer_settings: [
                {
                    groups: {},
                    symbols: {
                        live1: {
                            MMM: {
                                id: 3205,
                                name: "MMM",
                                type: "Perlot",
                                group: "CFDs on Stocks\\US Stocks\\MMM",
                                state: "enabled",
                                amount: 0,
                                digits: 2,
                                server: "live1",
                                source: "",
                                symbol: "MMM",
                                checked: true,
                                currency: "USD",
                                treeType: "SYMBOL",
                                symbolName: "MMM",
                                description: "3M Co - US 1lot=100",
                                configuration: {
                                    Trade: 4,
                                    Digits: 2,
                                    Exemode: 2,
                                    SwapLong: -6.5,
                                    SwapShort: -5.5,
                                    MarginMode: 2,
                                    ContractSize: 100,
                                    CurrencyProfit: "USD",
                                },
                            },
                        },
                    },
                    is_group_restriction_enabled: false,
                },
            ],
        },
        user: {
            id: 92,
            email: "nhung.nguyen+9@eaera.com",
            first_name: "Nhung",
            middle_name: null,
            last_name: "Nguyen",
            personal_info: {
                city: "2",
                street: "3",
                nationality: "ME",
                postal_code: 4,
                mobile_phone: "+38238222222",
                date_of_birth: "2003-06-28",
                register_step: 6,
                financial_info: {
                    annual_incoming: "10,000 - 100,000",
                    source_of_wealth: "Employment",
                    investment_amount: "500 - 1,000",
                    reason_for_opening: "Investment",
                    estimated_net_worth: "500 - 1,000",
                    us_citizen_or_us_resident: false,
                },
                state_province: "1",
                employment_info: {
                    industry: "Agriculture",
                    education_level: "High School",
                    employment_status: "Student",
                },
                trading_experince: {
                    experience: false,
                    number_of_trades: false,
                    years_of_experience: "None",
                },
            },
            dashboard_language: "en",
            email_language: "en",
            state: "enabled",
            created_at: "2021-06-29T02:13:17.561Z",
            updated_at: "2021-07-20T08:09:51.638Z",
            country: "ME",
            settings: {},
            display_currency: "USD",
            last_change_password: null,
            change_email_token: null,
            change_email_sent_at: null,
            last_activity: "2021-07-01T08:51:38.656Z",
            report_filters: {},
            referral_link: null,
            referral_params: {},
            full_name: "Nhung Nguyen",
            client: {
                id: 30,
                user_id: 92,
                compliance_id: null,
                category: "Trader",
                client_info: {
                    city: "2",
                    street: "3",
                    postal_code: 4,
                    financial_info: {
                        annual_incoming: "10,000 - 100,000",
                        source_of_wealth: "Employment",
                        investment_amount: "500 - 1,000",
                        reason_for_opening: "Investment",
                        estimated_net_worth: "500 - 1,000",
                        us_citizen_or_us_resident: false,
                    },
                    state_province: "1",
                    employment_info: {
                        industry: "Agriculture",
                        education_level: "High School",
                        employment_status: "Student",
                    },
                    trading_experince: {
                        experience: false,
                        number_of_trades: false,
                        years_of_experience: "None",
                    },
                },
                state: "active",
                registration_date: "2021-06-29T02:13:17.561Z",
                created_at: "2021-06-29T03:24:14.172Z",
                updated_at: "2022-09-06T00:23:11.414Z",
                ib_id: 4,
                verification_type: null,
                account_manager_id: 88,
                auto_transfer: false,
                referral_code: "474d90",
                referral_wallet_id: 43,
                is_funded: true,
                funded_at: "2021-06-29T07:51:14.383Z",
            },
        },
        ref_id: "9b24cc04-3d16-4eb7-8eb0-d9ab2661c598",
        ref_type: "Ib",
    },
];

mock.onGet("/Partners/data").reply(() => [200, Partners]);
