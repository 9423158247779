import Vue from 'vue'

// axios
import axios from 'axios'
import router from '@/router'
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.response.use(
  res => {
    return res
  },
  err => {
    if (err.response.status == 401) {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userData')
      router.push({ name: 'page-login', params: { errors: err.response.data, come_from: router.history.current.name } })
    }
    return Promise.reject(err);
  }
)

Vue.prototype.$http = axiosIns

export default axiosIns
