import axios from "@axios";
import Vue from "vue";
import moment from "moment-timezone";

export default {
    namespaced: true,
    state: {
        timezoneOffset: 0,
        site_settings: {
            settings: {},
            children: {
                company: {},
                email_settings: {},
                trading_platforms: {},
                account_setup: {},
                transaction_settings: {},
                country_settings: {},
                client_portal_translations: {},
                registration_and_kyc: {},
                partner_setting: {},
                risk_management: { settings: { currency: "" } },
            },
        },
        companyLogoURL: "",
        companyLogoDarkURL: "",
        companyFaviconURL: "",
    },
    getters: {
        timezoneOffset: (state) => state.timezoneOffset,
        timezoneName: (state) => {
            return (
                moment.tz.names().find((timezoneName) => {
                    return state.timezoneOffset === moment.tz(timezoneName).utcOffset();
                }) ?? "Europe/Lisbon"
            );
        },
        settingsByPath: (state) => (path) => {
            var result = state["site_settings"];
            for (const p of path) {
                if (!result.children) return null;
                result = result.children[p];
                if (result == null) return null;
            }
            return result;
        },

        companyLogoURL: (state) => state.companyLogoURL,
        companyLogoDarkURL: (state) => state.companyLogoDarkURL,
        companyFaviconURL: (state) => state.companyFaviconURL,
    },
    mutations: {
        SET_TIMEZONE_OFFSET(state, val) {
            state.timezoneOffset = val / 60;
        },
        SET_SETTINGS(state, { path, data, getters }) {
            let pp = [...path];
            if (pp.length > 0) {
                let name = pp.pop();

                parent = getters.settingsByPath(pp);
                Vue.set(parent.children, name, data);
            } else {
                Vue.set(state, "site_settings", data);
            }
        },

        SET_LOGO(state, { url }) {
            state.companyLogoURL = url;
        },

        SET_LOGO_DARK(state, { url }) {
            state.companyLogoDarkURL = url;
        },

        SET_FAVICON(state, { url }) {
            state.companyFaviconURL = url;
        },
    },
    actions: {
        async fetchTimezoneOffset({ commit }) {
            try {
                let res = await axios
                    // .get(`${process.env.VUE_APP_API_URL}/site_settings/get_server_timezone`)
                    //? mockData
                    .get("/GetServerTimezone/data");
                commit("SET_TIMEZONE_OFFSET", res.data.timezone_offset);
            } catch (error) {
                console.error(error);
            }
        },
        fetchSettings({ commit, getters }, path = []) {
            return new Promise((resolve, reject) => {
                axios
                    // .get(`${process.env.VUE_APP_API_URL}site_settings/${path.join("/")}?recursive`)
                    //? mockData
                    .get("/RiskManagement/data")
                    .then((res) => {
                        var data = res.data;
                        data.state = "clean";
                        commit("SET_SETTINGS", { path, data, getters });
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        fetchCompanyLogo({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_API_URL}site_settings/get_company_logo`)
                    .then((res) => {
                        commit("SET_LOGO", { url: res.data });
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        fetchCompanyLogoDark({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_API_URL}site_settings/get_company_logo_dark`)
                    .then((res) => {
                        commit("SET_LOGO_DARK", { url: res.data });
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        fetchCompanyFavicon({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    // .get(`${process.env.VUE_APP_API_URL}site_settings/get_company_favicon`)
                    //? mockData
                    .get("/GetCompanyFavicon/data")
                    .then((res) => {
                        commit("SET_FAVICON", { url: res.data });
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        updateSettings({ commit, getters }, { path, data }) {
            // Commit setting changes to server
            return new Promise((resolve, reject) => {
                // PATCH
                axios
                    .patch(`${process.env.VUE_APP_API_URL}site_settings/${path.join("/")}`, { setting: data })
                    .then((res) => {
                        data.state = "clean";
                        commit("SET_SETTINGS", { path, data, getters });
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
};
