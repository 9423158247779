import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import { BootstrapVue } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import i18n from "@/libs/i18n";
import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@axios";
import "@/libs/acl";
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import "@/libs/tour";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);

// Make BootstrapVue available throughout your project

// Axios Mock Adapter
import "@/@fake-db/db";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// Fontawesome pro
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon, FontAwesomeLayers } from "@fortawesome/vue-fontawesome";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";

library.add(fas);
library.add(far);
library.add(fal);
library.add(fad);
library.add(fas);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);

import vClickOutside from "v-click-outside";
Vue.use(vClickOutside);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

// import { getWebSocketURL } from "@/helper";
import { notifySuccess, notifyError } from "@/helper/notifications";

// Vue.use(ActionCableVue, {
//     debug: true,
//     debugLevel: "error",
//     connectionUrl: getWebSocketURL, // or function which returns a string with your JWT appended to your server URL as a query parameter
//     connectImmediately: false,
// });

Vue.config.productionTip = false;

Vue.prototype.$notifySuccess = notifySuccess;
Vue.prototype.$notifyError = notifyError;

export const vm = new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
    // methods: {
    // connectWithRefreshedToken() {
    //     this.$cable.connection.connect(getWebSocketURL(),);
    // },
    // disconnectActionCable() {
    // this.$cable.connection.disconnect();
    // },
    // },
}).$mount("#app");
