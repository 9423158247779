import mock from "@/@fake-db/mock";
import { getRandomInt } from "@/@fake-db/utils";

const AccountAnalysis = [
    {
        id: 65403,
        name: "ftd_risk_7024",
        properties: {
            path: ["natro", "ib", "natro-ib-ib001-gold-pro-1-100"],
            group: "Natro\\IB\\IB001\\Gold Pro\\1:100",
            login: 7024,
            server: "ftd_risk",
            currency: "USD",
            mt_account: {
                id: 161,
                city: "",
                lock: false,
                name: "name_1",
                email: "email_1",
                group: "Natro\\IB\\IB001\\Gold Pro\\1:100",
                login: 7024,
                phone: "",
                state: "",
                credit: getRandomInt(1, 500),
                enable: true,
                server: "ftd_risk",
                status: "",
                address: "",
                balance: 70871.73,
                comment: "",
                country: "",
                last_ip: null,
                zipcode: "",
                currency: "USD",
                leverage: 100,
                read_only: false,
                timestamp: "2021-10-06T14:09:23.129Z",
                identifier: null,
                last_login: "2021-10-06T14:09:21.000Z",
                lead_source: "",
                prev_equity: 57653.2,
                prev_balance: 69853.33,
                agent_account: 7019,
                register_time: "2020-06-12T11:16:25.000Z",
                prevmonth_equity: 87575.96,
                prevmonth_balance: 94738.15,
                from_date: "2021-10-06T14:09:23.129Z",
            },
            register_time: "2020-06-12T11:16:25.000+00:00",
        },
        value: {
            // ActualPL: 88740.04,
            OpenLots: 7.78,
            PLOverall: 261939.94,
            FloatingPL: -15063.02,
            NetCredits: getRandomInt(1, 500),
            OpenVolume: 794172.32,
            NetDeposits: getRandomInt(1, 500),
            OverallLots: 1036.74,
            PLLastMonth: 2.87000000001,
            PLThisMonth: 1164.07999999998,
            PLYesterday: -84092.48,
            DepositToday: getRandomInt(1, 500),
            OverallDeals: 2364,
            Withdrawable: 88740.04,
            "%ChangeEquity": 100,
            ClosedPLToday: 2177.7799999999997,
            CurrentCredit: getRandomInt(1, 500),
            // CurrentEquity: 88179.95,
            MonthlyVolume: 1662415.3599999999,
            "#LotsThisMonth": 21.3,
            ClosedLotsToday: 3.48,
            ClosedSwapToday: 159.63,
            TotalFloatingPL: -12933.18,
            WithdrawalToday: getRandomInt(1, 500),
            YesterdayCredit: getRandomInt(1, 500),
            YesterdayEquity: getRandomInt(1, 500),
            DepositYesterday: getRandomInt(1, 500),
            ClosedPLLastMonth: 12484.899999999996,
            ClosedPLYesterday: getRandomInt(1, 500),
            ClosedVolumeToday: 565033.9199999999,
            CommissionOverall: getRandomInt(1, 500),
            CountOfOpenTrades: 11,
            MonthlyNetCredits: getRandomInt(1, 500),
            MonthlyNetDeposit: getRandomInt(1, 500),
            OverallNetCredits: getRandomInt(1, 500),
            TotalFloatingSwap: 1796.62,
            WdableAccCurrency: 88532.04,
            CommissionThisYear: getRandomInt(1, 500),
            NetDepositsOverall: -173199.90000000002,
            TotalClosedPLToday: 2337.41,
            ActualPLAccCurrency: 88532.04,
            CommissionThisMonth: getRandomInt(1, 500),
            CreditEndOfLastYear: getRandomInt(1, 500),
            EquityEndOfLastYear: getRandomInt(1, 500),
            FloatingPLYesterday: getRandomInt(1, 500),
            NetDepositLastMonth: 10000,
            SOCompensationToday: getRandomInt(1, 500),
            WithdrawalYesterday: getRandomInt(1, 500),
            YesterdayNetDeposit: getRandomInt(1, 500),
            AgentCommissionToday: getRandomInt(1, 500),
            ClosedDealsThisMonth: 61,
            InitialMontlhyVolume: 2581836.2000000016,
            PLOverallAccCurrency: 261731.94,
            "%ProfitableTotalTrade": 61.85263157894737,
            ClosedVolumeYesterday: getRandomInt(1, 500),
            EquityBeforeThisMonth: 87575.96,
            SOCompensationOverall: getRandomInt(1, 500),
            WithdrawableYesterday: getRandomInt(1, 500),
            "%ProfitableClosedTrade": 62.1404399323181,
            NetDepositsAccCurrency: getRandomInt(1, 500),
            OverallProfitableDeals: 1469,
            PLThisMonthAccCurrency: 956.07999999998,
            SOCompensationThisYear: getRandomInt(1, 500),
            DepositTodayAccCurrency: getRandomInt(1, 500),
            FloatingPLBeforeNewYear: getRandomInt(1, 500),
            SOCompensationThisMonth: getRandomInt(1, 500),
            CreditEndOfPreviousMonth: getRandomInt(1, 500),
            CurrentCreditAccCurrency: getRandomInt(1, 500),
            CurrentEquityAccCurrency: 88740.04,
            DayBeforeYesterdayCredit: getRandomInt(1, 500),
            DayBeforeYesterdayEquity: 84092.48,
            YesterdayCreditAccCredit: getRandomInt(1, 500),
            AVGFloatingPLPerOpenTrade: -1175.7436363636364,
            ClosedPLOverallBeforeToday: 150311.77000000002,
            CreditEndOfMonthBeforeLast: getRandomInt(1, 500),
            EquityEndOfMonthBeforeLast: 77573.09,
            WithdrawalTodayAccCurrency: getRandomInt(1, 500),
            YesterdayEquityAccCurrency: getRandomInt(1, 500),
            NewMonthlyVolumeBeforeToday: 1097381.44,
            ClosedPLThisMonthBeforeToday: 4717.74,
            DayBeforeYesterdayFloatingPL: -15363.410000000002,
            FloatingPLEndOfPreviousMonth: -7162.19,
            MonthlyNetDepositAccCurrency: getRandomInt(1, 500),
            NetDepositsOverallAccCurrency: -173199.9,
            ClosedLotsThisMonthBeforeToday: 17.82,
            CommissionThisMonthBeforeToday: getRandomInt(1, 500),
            FloatingPLEndOfMonthBeforeLast: 5319.84,
            ClosedPLThisYearBeforeThisMonth: 131769.05,
            NetDepositsThisMonthBeforeToday: getRandomInt(1, 500),
            AVGProfitPerClosedTradeThisMonth: 115.65819672131147,
            CommissionOverallBeforeThisMonth: getRandomInt(1, 500),
            EquityBeforeThisMonthAccCurrency: 87575.96,
            CommissionThisYearBeforeThisMonth: getRandomInt(1, 500),
            SOCompensationThisMonthBeforeToday: getRandomInt(1, 500),
            CreditEndOfPreviousMonthAccCurrency: getRandomInt(1, 500),
            SOCompensationOverallBeforeThisMonth: getRandomInt(1, 500),
            SOCompensationThisYearBeforeThisMonth: getRandomInt(1, 500),
            NetDepositsThisMonthBeforeTodayAccCurrency: getRandomInt(1, 500),
            ClosedPL: getRandomInt(1, 500),
            OpenPL: getRandomInt(1, 500),
            PL: getRandomInt(1, 500),
            CurrentCredit: getRandomInt(1, 500),
            CurrentEquity: getRandomInt(1, 500),
            Withdrawable: getRandomInt(1, 500),
            Commission: getRandomInt(1, 500),
            Deposits: getRandomInt(1, 500),
            Withdrawals: getRandomInt(1, 500),
            VolTraded: getRandomInt(1, 500),
            ClosedWin: getRandomInt(1, 500),
            VolTraded: getRandomInt(1, 500),
            ClosedLoss: getRandomInt(1, 500),
            SOCompensation: getRandomInt(1, 500),
            ActualClosedPL: getRandomInt(1, 500),
            Deposits: getRandomInt(1, 500),
            Withdrawals: getRandomInt(1, 500),
            NetDeposits: getRandomInt(1, 500),
        },
        metric_definition_id: 8,
        metric_definition_name: "CurrentUserStatistics",
    },
];

mock.onGet("/AccountAnalysis/data").reply(() => [200, AccountAnalysis]);
