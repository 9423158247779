import mock from "@/@fake-db/mock";

const GroupLevel = {
    short_name: "group_levels",
    schema: { type: "array", items: { type: "string" }, additionalProperties: true },
    settings: ["Company", "Region", "Team"],
    created_at: "2021-08-26T12:10:35.851Z",
    ref_id: "910afe77-b4a7-4c9e-b4a8-923287f887bd",
    ref_type: "SiteSetting",
};

mock.onGet("/GroupLevel/data").reply(() => [200, GroupLevel]);
