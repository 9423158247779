import mock from "@/@fake-db/mock";

const GroupOrganization = {
    short_name: "group_organization",
    schema: {
        type: "object",
        properties: { name: { type: "string" }, level: { type: "number" } },
        additionalProperties: true,
    },
    settings: {
        groups: [
            {
                name: "EU A_USD",
                path: null,
                group: "real\\EAERA\\EU\\A_USD",
                enable: false,
                parent: "",
                server: "live1",
                currency: "USD",
                timestamp: "2021-10-06T13:20:06.244+00:00",
            },
            {
                name: "EU A_P_USD",
                path: null,
                group: "real\\EAERA\\EU\\A_P_USD",
                enable: false,
                parent: "",
                server: "live1",
                currency: "USD",
                timestamp: "2021-10-06T13:20:10.566+00:00",
            },
            {
                name: "EU A_EUR",
                path: null,
                group: "real\\EAERA\\EU\\A_EUR",
                enable: false,
                parent: "",
                server: "live1",
                currency: "EUR",
                timestamp: "2021-10-06T13:20:27.870+00:00",
            },
            {
                name: "EU A_P_EUR",
                path: null,
                group: "real\\EAERA\\EU\\A_P_EUR",
                enable: false,
                parent: "",
                server: "live1",
                currency: "EUR",
                timestamp: "2021-10-06T13:20:18.948+00:00",
            },
            {
                name: "EU A_GBP",
                path: null,
                group: "real\\EAERA\\EU\\A_GBP",
                enable: false,
                parent: "",
                server: "live1",
                currency: "GBP",
                timestamp: "2021-10-06T13:20:26.060+00:00",
            },
            {
                name: "EU A_P_GBP",
                path: null,
                group: "real\\EAERA\\EU\\A_P_GBP",
                enable: false,
                parent: "",
                server: "live1",
                currency: "GBP",
                timestamp: "2021-10-06T13:20:16.382+00:00",
            },
            {
                name: "EU A_JPY",
                path: null,
                group: "real\\EAERA\\EU\\A_JPY",
                enable: false,
                parent: "",
                server: "live1",
                currency: "JPY",
                timestamp: "2021-10-06T13:20:23.994+00:00",
            },
            {
                name: "EU A_P_JPY",
                path: null,
                group: "real\\EAERA\\EU\\A_P_JPY",
                enable: false,
                parent: "",
                server: "live1",
                currency: "JPY",
                timestamp: "2021-10-06T13:20:13.636+00:00",
            },
            {
                name: "EU A_CHF",
                path: null,
                group: "real\\EAERA\\EU\\A_CHF",
                enable: false,
                parent: "",
                server: "live1",
                currency: "CHF",
                timestamp: "2021-10-06T13:20:29.836+00:00",
            },
            {
                name: "EU A_P_CHF",
                path: null,
                group: "real\\EAERA\\EU\\A_P_CHF",
                enable: false,
                parent: "",
                server: "live1",
                currency: "CHF",
                timestamp: "2021-10-06T13:20:21.565+00:00",
            },
            {
                name: "SEA A_USD",
                path: null,
                group: "real\\EAERA\\ASIA\\A_USD",
                enable: false,
                parent: "/group_organization/eaera/test/test-2",
                server: "live1",
                currency: "USD",
                timestamp: "2022-09-27T07:36:34.484+00:00",
            },
            {
                name: "SEA A_P_USD",
                path: null,
                group: "real\\EAERA\\ASIA\\A_P_USD",
                enable: false,
                parent: "",
                server: "live1",
                currency: "USD",
                timestamp: "2021-10-06T13:18:48.374+00:00",
            },
            {
                name: "SEA A_EUR",
                path: null,
                group: "real\\EAERA\\ASIA\\A_EUR",
                enable: false,
                parent: "",
                server: "live1",
                currency: "EUR",
                timestamp: "2021-10-07T11:56:32.335+00:00",
            },
            {
                name: "SEA A_P_EUR",
                path: null,
                group: "real\\EAERA\\ASIA\\A_P_EUR",
                enable: false,
                parent: "",
                server: "live1",
                currency: "EUR",
                timestamp: "2021-10-06T13:19:07.113+00:00",
            },
            {
                name: "SEA A_GBP",
                path: null,
                group: "real\\EAERA\\ASIA\\A_GBP",
                enable: false,
                parent: "",
                server: "live1",
                currency: "GBP",
                timestamp: "2021-10-06T13:19:11.828+00:00",
            },
            {
                name: "SEA A_P_GBP",
                path: null,
                group: "real\\EAERA\\ASIA\\A_P_GBP",
                enable: false,
                parent: "",
                server: "live1",
                currency: "GBP",
                timestamp: "2021-10-06T13:19:05.705+00:00",
            },
            {
                name: "SEA A_JPY",
                path: null,
                group: "real\\EAERA\\ASIA\\A_JPY",
                enable: false,
                parent: "",
                server: "live1",
                currency: "JPY",
                timestamp: "2021-10-06T13:19:09.576+00:00",
            },
            {
                name: "SEA A_P_JPY",
                path: null,
                group: "real\\EAERA\\ASIA\\A_P_JPY",
                enable: false,
                parent: "",
                server: "live1",
                currency: "JPY",
                timestamp: "2021-10-06T13:18:57.182+00:00",
            },
            {
                name: "SEA A_CHF",
                path: null,
                group: "real\\EAERA\\ASIA\\A_CHF",
                enable: false,
                parent: "",
                server: "live1",
                currency: "CHF",
                timestamp: "2021-10-06T13:19:15.606+00:00",
            },
            {
                name: "SEA A_P_CHF",
                path: null,
                group: "real\\EAERA\\ASIA\\A_P_CHF",
                enable: false,
                parent: "",
                server: "live1",
                currency: "CHF",
                timestamp: "2021-10-06T13:19:08.506+00:00",
            },
            {
                name: "JP A_USD",
                path: null,
                group: "real\\EAERA\\JP\\A_USD",
                enable: false,
                parent: "",
                server: "live1",
                currency: "USD",
                timestamp: "2021-10-06T13:19:21.611+00:00",
            },
            {
                name: "JP A_P_USD",
                path: null,
                group: "real\\EAERA\\JP\\A_P_USD",
                enable: false,
                parent: "",
                server: "live1",
                currency: "USD",
                timestamp: "2021-10-06T13:19:24.748+00:00",
            },
            {
                name: "JP A_EUR",
                path: null,
                group: "real\\EAERA\\JP\\A_EUR",
                enable: false,
                parent: "",
                server: "live1",
                currency: "EUR",
                timestamp: "2021-10-06T13:20:00.950+00:00",
            },
            {
                name: "JP A_P_EUR",
                path: null,
                group: "real\\EAERA\\JP\\A_P_EUR",
                enable: false,
                parent: "",
                server: "live1",
                currency: "EUR",
                timestamp: "2021-10-06T13:19:51.633+00:00",
            },
            {
                name: "JP A_GBP",
                path: null,
                group: "real\\EAERA\\JP\\A_GBP",
                enable: false,
                parent: "",
                server: "live1",
                currency: "GBP",
                timestamp: "2021-10-06T13:19:57.550+00:00",
            },
            {
                name: "JP A_P_GBP",
                path: null,
                group: "real\\EAERA\\JP\\A_P_GBP",
                enable: false,
                parent: "",
                server: "live1",
                currency: "GBP",
                timestamp: "2021-10-06T13:19:39.995+00:00",
            },
            {
                name: "JP A_JPY",
                path: null,
                group: "real\\EAERA\\JP\\A_JPY",
                enable: false,
                parent: "",
                server: "live1",
                currency: "JPY",
                timestamp: "2021-10-06T13:19:42.180+00:00",
            },
            {
                name: "JP A_P_JPY",
                path: null,
                group: "real\\EAERA\\JP\\A_P_JPY",
                enable: false,
                parent: "",
                server: "live1",
                currency: "JPY",
                timestamp: "2021-10-06T13:19:26.889+00:00",
            },
            {
                name: "JP A_CHF",
                path: null,
                group: "real\\EAERA\\JP\\A_CHF",
                enable: false,
                parent: "",
                server: "live1",
                currency: "CHF",
                timestamp: "2021-10-06T13:20:03.988+00:00",
            },
            {
                name: "JP A_P_CHF",
                path: null,
                group: "real\\EAERA\\JP\\A_P_CHF",
                enable: false,
                parent: "",
                server: "live1",
                currency: "CHF",
                timestamp: "2021-10-06T13:19:54.442+00:00",
            },
            {
                name: "SEA MilicaTestUSD",
                path: null,
                group: "real\\EAERA\\ASIA\\MilicaTestUSD",
                enable: false,
                parent: "/group_organization/eaera/test/test1",
                server: "live1",
                currency: "USD",
                timestamp: "2022-09-27T02:08:11.936+00:00",
            },
            {
                name: "SA ThinhTest",
                path: null,
                group: "real\\EAERA\\ASIA\\ThinhTestUSD",
                enable: false,
                parent: "",
                server: "live1",
                currency: "USD",
                timestamp: "2021-10-07T01:49:43.159+00:00",
            },
            {
                name: "PhongTestUSD1",
                path: null,
                group: "real\\EAERA\\ASIA\\PhongTestUSD",
                enable: false,
                parent: "",
                server: "live1",
                currency: "USD",
                timestamp: "2021-10-06T13:19:20.190+00:00",
            },
            {
                name: "real\\EAERA\\ASIA\\PhongTestUSD1",
                path: null,
                group: "real\\EAERA\\ASIA\\PhongTestUSD1",
                enable: false,
                parent: "",
                server: "live1",
                currency: "USD",
                timestamp: "2021-10-06T13:19:18.643+00:00",
            },
        ],
    },
    created_at: "2021-08-26T12:10:33.412Z",
    ref_id: "6384c7dc-cfae-4074-8f45-0f226c07d09b",
    ref_type: "SiteSetting",
    children: {
        eaera: {
            short_name: "eaera",
            schema: {
                type: "object",
                properties: { name: { type: "string" }, level: { type: "number" } },
                additionalProperties: true,
            },
            settings: { name: "EAERA", level: 1 },
            created_at: "2021-08-26T12:13:25.669Z",
            ref_id: "ea5f4ed5-9b82-4235-be29-ff7ea8d31c93",
            ref_type: "SiteSetting",
            children: {
                asia: {
                    short_name: "asia",
                    schema: {
                        type: "object",
                        properties: { name: { type: "string" }, level: { type: "number" } },
                        additionalProperties: true,
                    },
                    settings: { name: "Asia", level: 2 },
                    created_at: "2021-09-01T14:48:49.673Z",
                    ref_id: "d46260fe-af2a-4e32-9aa9-e1ab18b50eaf",
                    ref_type: "SiteSetting",
                    children: {
                        sea: {
                            short_name: "sea",
                            schema: {
                                type: "object",
                                properties: { name: { type: "string" }, level: { type: "number" } },
                                additionalProperties: true,
                            },
                            settings: { name: "SEA", level: 3 },
                            created_at: "2021-10-06T13:13:12.546Z",
                            ref_id: "8eec5b48-54de-4cae-a85e-fa2b91a08b31",
                            ref_type: "SiteSetting",
                        },
                        japan: {
                            short_name: "japan",
                            schema: {
                                type: "object",
                                properties: { name: { type: "string" }, level: { type: "number" } },
                                additionalProperties: true,
                            },
                            settings: { name: "Japan", level: 3 },
                            created_at: "2021-09-07T06:54:07.374Z",
                            ref_id: "ddd3864c-bdb1-44ff-babc-be0dab2b31ea",
                            ref_type: "SiteSetting",
                        },
                    },
                },
                europe: {
                    short_name: "europe",
                    schema: {
                        type: "object",
                        properties: { name: { type: "string" }, level: { type: "number" } },
                        additionalProperties: true,
                    },
                    settings: { name: "Europe", level: 2 },
                    created_at: "2021-09-01T14:49:27.307Z",
                    ref_id: "cd4241fa-bba5-4c86-946a-53064b9fc0ec",
                    ref_type: "SiteSetting",
                    children: {
                        eu: {
                            short_name: "eu",
                            schema: {
                                type: "object",
                                properties: { name: { type: "string" }, level: { type: "number" } },
                                additionalProperties: true,
                            },
                            settings: { name: "EU", level: 3 },
                            created_at: "2021-09-06T06:48:06.272Z",
                            ref_id: "0e44664c-b97e-4af4-bcc9-5a75702e18ab",
                            ref_type: "SiteSetting",
                        },
                    },
                },
                test: {
                    short_name: "test",
                    schema: {
                        type: "object",
                        properties: { name: { type: "string" }, level: { type: "number" } },
                        additionalProperties: true,
                    },
                    settings: { name: "test", level: 2 },
                    created_at: "2021-09-22T11:32:09.604Z",
                    ref_id: "1c160f79-c5f3-457d-b6cf-6c117a985cb1",
                    ref_type: "SiteSetting",
                    children: {
                        test1: {
                            short_name: "test1",
                            schema: {
                                type: "object",
                                properties: { name: { type: "string" }, level: { type: "number" } },
                                additionalProperties: true,
                            },
                            settings: { name: "test1", level: 3 },
                            created_at: "2021-09-22T12:06:42.476Z",
                            ref_id: "4250acff-6b1d-41c1-bf6e-1a1f95c8257d",
                            ref_type: "SiteSetting",
                        },
                        "test-2": {
                            short_name: "test-2",
                            schema: {
                                type: "object",
                                properties: { name: { type: "string" }, level: { type: "number" } },
                                additionalProperties: true,
                            },
                            settings: { name: "test 2", level: 3 },
                            created_at: "2022-09-23T04:48:48.911Z",
                            ref_id: "acda7dce-6b00-4d19-aab3-ef96d8a1e38d",
                            ref_type: "SiteSetting",
                        },
                    },
                },
            },
        },
    },
};

mock.onGet("/GroupOrganization/data").reply(() => [200, GroupOrganization]);
