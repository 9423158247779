import mock from "@/@fake-db/mock";
import jwt from "jsonwebtoken";

const data = {
    user: [
        {
            id: 5,
            email: "john@doe.com",
            password: "Password123!",
            first_name: "John",
            middle_name: "Johny",
            last_name: "Doe",
            personal_info: {
                city: "Hanoi",
                title: "Mr",
                gender: "male",
                id_type: "passport",
                id_number: "77847545",
                nationality: "Viet Nam",
                postal_code: "5564",
                mobile_phone: "+1298123443",
                date_of_birth: "1985-01-15",
                trading_experince: {
                    experience: "no",
                    no_experience: "I have a relevant education/professional qualification",
                    number_of_trades: "",
                    years_of_experience: "",
                },
                employment_financial_info: {
                    industry: "IT",
                    anual_incoming: "50.000",
                    source_of_wealth: "dasdhaj",
                    educatation_level: "BSC",
                    employment_status: "employed",
                    investment_amount: "1000000",
                    reason_for_opening: "dfsdfs",
                    estimated_net_worth: "50000 - 100000",
                    us_citizen_or_us_resident: "no",
                },
            },
            dashboard_language: "en",
            email_language: "en",
            state: "disabled",
            country: "VN",
            settings: {},
            display_currency: "USD",
            report_filters: {
                volume: [
                    {
                        filter_name: "testFilter-Volume",
                        selectedGroups: ["live1"],
                        accountsFilters: [],
                        selectedSymbols: ["live1"],
                        applyingAccountFilter: "",
                    },
                ],
                profit_loss: [
                    {
                        filter_name: "testFilter",
                        selectedGroups: ["live1"],
                        accountsFilters: [
                            {
                                name: "TESTTTTTT",
                                selectedAccounts: [
                                    { name: "Lucy Bay", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200186 },
                                    { name: "Ava Bee", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200175 },
                                    { name: "Lucy Bay", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200186 },
                                    { name: "Milica Refer", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200192 },
                                    { name: "Thinh Transfer", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200346 },
                                    { name: "Tung Nguyen", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200193 },
                                    { name: "Hello Refer", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200191 },
                                    { name: "Duc Hoang20", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200201 },
                                    { name: "Duc Hoang", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200202 },
                                    { name: "Milica Refer", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200173 },
                                    { name: "Thinh Nguyen", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200248 },
                                    { name: "Test Country", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200215 },
                                    { name: "Thinh Transfer", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200383 },
                                    { name: "Friend Refer", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200127 },
                                    { name: "Lenny Lenny", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200230 },
                                    { name: "Thinh Nguyen", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200386 },
                                    { name: "Dave Dave", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200231 },
                                    { name: "Thinh Nguyen", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200181 },
                                    { name: "trader ib1", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200139 },
                                    { name: "Hieu Dam", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200387 },
                                    { name: "Tom Tom", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200284 },
                                    { name: "Maddie Maddie", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200280 },
                                    { name: "Another Friend", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200129 },
                                    { name: "Duc Hoang", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200137 },
                                    { name: "Xiem Ngo", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200136 },
                                    { name: "Thinh Nguyen", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200232 },
                                    { name: "Thinh Nguyen", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200316 },
                                    { name: "Duc Hoang", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200325 },
                                    { name: "Thinh Nguyen", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200345 },
                                    { name: "Hieu dam", group: "real\\EAERA\\ASIA\\A_P_USD", login: 200429 },
                                ],
                            },
                        ],
                        selectedSymbols: ["live1\\Crypto"],
                        applyingAccountFilter: "TESTTTTTT",
                    },
                ],
            },
            referral_link: null,
            referral_params: {},
            allow_routes: [],
            roles: ["Admin", "Client"],
            full_name: "John Johny Doe",
            photo_url:
                "https://staging.eaera.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBczRCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--7c98a27c9b965ae20eb97c503f8b2eeebce63a1a/1519905263507.png",
            last_sign_in_at: "2022-10-14T09:46:27.577Z",
            ref_id: "7b0a15c1-fc93-4b69-a0fb-cf907dfbd0dc",
            ref_type: "User",
        },
    ],
};
// ! These two secrets shall be in .env file and not in any other file
const jwtConfig = {
    secret: "dd5f3089-40c3-403d-af14-d0c228b05cb4",
    refreshTokenSecret: "7c4c1c50-3230-45bf-9eae-c9b2e401c767",
    expireTime: "10m",
    refreshTokenExpireTime: "10m",
};

mock.onPost("/jwt/login").reply((request) => {
    const obj = JSON.parse(request.data);
    const email = obj.email;
    const password = obj.password;
    let error = {
        email: ["Something went wrong"],
    };

    const user = data.user.find((u) => u.email === email && u.password === password);
    console.log(user);

    if (user) {
        try {
            const accessToken = jwt.sign({ id: user.id }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime });
            const refreshToken = jwt.sign({ id: user.id }, jwtConfig.refreshTokenSecret, {
                expiresIn: jwtConfig.refreshTokenExpireTime,
            });

            const userData = { ...user };

            delete userData.password;

            const response = {
                userData,
                accessToken,
                refreshToken,
            };

            return [200, response];
        } catch (e) {
            error = e;
        }
    } else {
        error = {
            email: ["Email or Password is Invalid"],
        };
    }

    return [400, { error }];
});

mock.onPost("/jwt/register").reply((request) => {
    const { username, email, password } = JSON.parse(request.data);

    // If not any of data is missing return 400
    if (!(username && email && password)) return [400];

    const isEmailAlreadyInUse = data.users.find((user) => user.email === email);
    const isUsernameAlreadyInUse = data.users.find((user) => user.username === username);

    const error = {
        password: !password ? ["Please enter password"] : null,
        email: (() => {
            if (!email) return ["Please enter your email."];
            if (isEmailAlreadyInUse) return ["This email is already in use."];
            return null;
        })(),
        username: (() => {
            if (!username) return ["Please enter your username."];
            if (isUsernameAlreadyInUse) return ["This username is already in use."];
            return null;
        })(),
    };

    if (!error.username && !error.email) {
        const userData = {
            email,
            password,
            username,
            fullName: "",
            avatar: null,
            role: "admin",
            ability: [
                {
                    action: "manage",
                    subject: "all",
                },
            ],
        };

        // Add user id
        const { length } = data.users;
        let lastIndex = 0;
        if (length) {
            lastIndex = data.users[length - 1].id;
        }
        userData.id = lastIndex + 1;

        data.users.push(userData);

        const accessToken = jwt.sign({ id: userData.id }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime });

        const user = { ...userData };
        delete user.password;
        const response = {
            userData: user,
            accessToken,
        };

        return [200, response];
    }
    return [400, { error }];
});

mock.onPost("/jwt/refresh-token").reply((request) => {
    const { refreshToken } = JSON.parse(request.data);

    try {
        const { id } = jwt.verify(refreshToken, jwtConfig.refreshTokenSecret);

        const userData = { ...data.users.find((user) => user.id === id) };

        const newAccessToken = jwt.sign({ id: userData.id }, jwtConfig.secret, { expiresIn: jwtConfig.expiresIn });
        const newRefreshToken = jwt.sign({ id: userData.id }, jwtConfig.refreshTokenSecret, {
            expiresIn: jwtConfig.refreshTokenExpireTime,
        });

        delete userData.password;
        const response = {
            userData,
            accessToken: newAccessToken,
            refreshToken: newRefreshToken,
        };

        return [200, response];
    } catch (e) {
        const error = "Invalid refresh token";
        return [401, { error }];
    }
});
