import Vue from "vue";
import VueRouter from "vue-router";
import { vm } from "@/main";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from "@/auth/utils";
import pages from "./routes/pages";
import dashboard from "./routes/dashboard";
// import apps from './routes/apps'
// import uiElements from './routes/ui-elements/index'
// import chartsMaps from './routes/charts-maps'
// import formsTable from './routes/forms-tables'
// import others from './routes/others'
import risk_management from "./risk_management";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        { path: "/", redirect: { name: "dashboard-ecommerce" } },
        ...dashboard,
        ...pages,
        ...risk_management,
        // ...apps,
        // ...chartsMaps,
        // ...formsTable,
        // ...uiElements,
        // ...others,
        {
            path: "*",
            redirect: "error-404",
        },
    ],
});

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn();
    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        if (!isLoggedIn) return next({ name: "auth-login" });

        // If logged in => error
        return next({ name: "error-404" });
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData();
        next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
    }

    return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
        appLoading.style.display = "none";
    }
    // vm.disconnectActionCable()
    // vm.connectWithRefreshedToken()
});

export default router;
