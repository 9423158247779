import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue"

export const notifySuccess = (title, text) => {
  Vue.$toast({
    component: ToastificationContent,
    position: "top-right",
    props: {title, text, icon: 'CheckIcon', variant: "success"},
  });
}

export const notifyError = (title, text) => {
  Vue.$toast({
    component: ToastificationContent,
    position: "top-right",
    props: {title, text, icon: 'AlertCircleIcon', variant: "danger"},
  });
}
