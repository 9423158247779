export default {
  market_exposure: (state) => {
    return state.market_exposure
  },

  is_market_exposure_fetched: (state) => {
    return state.is_market_exposure_fetched
  },

  current_user_statistics: (state) => {
    return state.current_user_statistics
  },

  is_current_user_statistics_fetched: (state) => {
    return state.is_current_user_statistics_fetched
  },

  account_analysis: (state) => {
    return state.account_analysis
  },

  is_account_analysis_fetched: (state) => {
    return state.is_account_analysis_fetched
  },

  risk_threshold: (state) => {
    return state.risk_threshold
  },

  group_levels: (state) => {
    return state.group_levels
  },

  max_group_level: (state) => {
    return state.group_levels.length
  }
}