import mock from "@/@fake-db/mock";
import { getRandomInt } from "@/@fake-db/utils";

const MarketExposure = {
    data: [
        {
            id: 1,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/EU/London/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "USDCHF",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "USDCHF",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },

        {
            id: 2,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/EU/London/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "USDCHF",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "USDCHF",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },

        {
            id: 3,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/EU/London/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "USDCHF",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "USDCHF",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },
        {
            id: 4,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/EU/London/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "USDCHF",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "USDCHF",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },

        {
            id: 5,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/EU/London/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },

        {
            id: 6,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/EU/London/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },

        {
            id: 7,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/EU/London/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },
        {
            id: 8,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/EU/London/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },
        {
            id: 9,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/EU/Amsterdam/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "USDCHF",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "USDCHF",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },

        {
            id: 10,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/EU/Amsterdam/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "USDCHF",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "USDCHF",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },

        {
            id: 11,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/EU/Amsterdam/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "USDCHF",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "USDCHF",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },
        {
            id: 12,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/EU/Amsterdam/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "USDCHF",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "USDCHF",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },

        {
            id: 13,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/EU/Amsterdam/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },

        {
            id: 14,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/EU/Amsterdam/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },

        {
            id: 15,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/EU/Amsterdam/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },
        {
            id: 16,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/EU/Amsterdam/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },

        {
            id: 17,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/ASIA/HCM/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "USDCHF",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "USDCHF",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },

        {
            id: 18,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/ASIA/HCM/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "USDCHF",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "USDCHF",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },

        {
            id: 19,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/ASIA/HCM/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "USDCHF",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "USDCHF",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },
        {
            id: 20,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/ASIA/HCM/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "USDCHF",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "USDCHF",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },

        {
            id: 21,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/ASIA/HCM/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },

        {
            id: 22,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/ASIA/HCM/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },

        {
            id: 23,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/ASIA/HCM/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },
        {
            id: 24,
            name: "Mk_exp_" + getRandomInt(1, 50000),
            properties: {
                region: "XTX Markets/ASIA/HCM/asia/japan",
                server: "MT5-",
                login: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                user: "Name-" + getRandomInt(1, 50000),
            },
            value: {
                ExposureIndicator: [getRandomInt(1, 50000), getRandomInt(1, 50000)],
                NetExposure: getRandomInt(1, 50000),
                symbol: "NZDUSD",
                CompanyFloatingPL: getRandomInt(1, 50000),
                NetPositionLots: getRandomInt(1, 50000),
                LongLots: getRandomInt(1, 50000),
                ShortLots: getRandomInt(1, 50000),
                ClosedPLToday: getRandomInt(1, 50000),
                ClosedPLPerLot: getRandomInt(1, 50000),
            },
        },
    ],
};

mock.onGet("/MarketExposure/data").reply(() => [200, MarketExposure.data]);
