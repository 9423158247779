export default {
    "short_name": "group_organization",
    "schema": {
      "type": "object",
      "properties": {
        "name": {
          "type": "string"
        },
        "level": {
          "type": "number"
        }
      },
      "additionalProperties": true
    },
    "settings": {
      "groups": [
        {
          "name": "EU A_USD",
          "path": "eaera/europe/eu/eu-a_usd",
          "group": "real\\EAERA\\EU\\A_USD",
          "enable": true,
          "parent": "/group_organization/eaera/europe/eu",
          "server": "live1",
          "currency": "USD",
          "timestamp": "2021-09-13T14:49:13.124+00:00"
        },
        {
          "name": "EU A_P_USD",
          "path": "eaera/europe/eu/eu-a_p_usd",
          "group": "real\\EAERA\\EU\\A_P_USD",
          "enable": true,
          "parent": "/group_organization/eaera/europe/eu",
          "server": "live1",
          "currency": "USD",
          "timestamp": "2021-09-07T07:00:11.851+00:00"
        },
        {
          "name": "EU A_EUR",
          "path": "eaera/europe/eu/eu-a_eur-1",
          "group": "real\\EAERA\\EU\\A_EUR",
          "enable": true,
          "parent": "/group_organization/eaera/europe/eu",
          "server": "live1",
          "currency": "EUR",
          "timestamp": "2021-09-09T08:06:09.501+00:00"
        },
        {
          "name": "EU A_P_EUR",
          "path": "eaera/europe/eu/eu-a_p_eur",
          "group": "real\\EAERA\\EU\\A_P_EUR",
          "enable": true,
          "parent": "/group_organization/eaera/europe/eu",
          "server": "live1",
          "currency": "EUR",
          "timestamp": "2021-09-07T07:00:15.232+00:00"
        },
        {
          "name": "EU A_GBP",
          "path": "eaera/europe/eu/eu-a_gbp",
          "group": "real\\EAERA\\EU\\A_GBP",
          "enable": true,
          "parent": "/group_organization/eaera/europe/eu",
          "server": "live1",
          "currency": "GBP",
          "timestamp": "2021-09-08T11:06:19.398+00:00"
        },
        {
          "name": "EU A_P_GBP",
          "path": "eaera/europe/eu/eu-a_p_gbp",
          "group": "real\\EAERA\\EU\\A_P_GBP",
          "enable": true,
          "parent": "/group_organization/eaera/europe/eu",
          "server": "live1",
          "currency": "GBP",
          "timestamp": "2021-09-07T07:00:18.337+00:00"
        },
        {
          "name": "EU A_JPY",
          "path": "eaera/europe/eu/eu-a_jpy",
          "group": "real\\EAERA\\EU\\A_JPY",
          "enable": true,
          "parent": "/group_organization/eaera/europe/eu",
          "server": "live1",
          "currency": "JPY",
          "timestamp": "2021-09-07T07:00:19.913+00:00"
        },
        {
          "name": "EU A_P_JPY",
          "path": "eaera/europe/eu/eu-a_p_jpy",
          "group": "real\\EAERA\\EU\\A_P_JPY",
          "enable": true,
          "parent": "/group_organization/eaera/europe/eu",
          "server": "live1",
          "currency": "JPY",
          "timestamp": "2021-09-07T07:02:02.190+00:00"
        },
        {
          "name": "EU A_CHF",
          "path": "eaera/europe/eu/eu-a_chf",
          "group": "real\\EAERA\\EU\\A_CHF",
          "enable": true,
          "parent": "/group_organization/eaera/europe/eu",
          "server": "live1",
          "currency": "CHF",
          "timestamp": "2021-09-07T07:02:04.692+00:00"
        },
        {
          "name": "EU A_P_CHF",
          "path": "eaera/europe/eu/eu-a_p_chf",
          "group": "real\\EAERA\\EU\\A_P_CHF",
          "enable": true,
          "parent": "/group_organization/eaera/europe/eu",
          "server": "live1",
          "currency": "CHF",
          "timestamp": "2021-09-07T07:02:07.781+00:00"
        },
        {
          "name": "SEA A_USD",
          "path": "eaera/asia/japan/sea-a_usd",
          "group": "real\\EAERA\\ASIA\\A_USD",
          "enable": true,
          "parent": "/group_organization/eaera/asia/japan",
          "server": "live1",
          "currency": "USD",
          "timestamp": "2021-09-07T07:02:11.533+00:00"
        },
        {
          "name": "SEA A_P_USD",
          "path": "eaera/asia/japan/sea-a_p_usd",
          "group": "real\\EAERA\\ASIA\\A_P_USD",
          "enable": true,
          "parent": "/group_organization/eaera/asia/japan",
          "server": "live1",
          "currency": "USD",
          "timestamp": "2021-09-07T07:02:14.865+00:00"
        },
        {
          "name": "SEA A_EUR",
          "path": "eaera/asia/japan/sea-a_eur",
          "group": "real\\EAERA\\ASIA\\A_EUR",
          "enable": true,
          "parent": "/group_organization/eaera/asia/japan",
          "server": "live1",
          "currency": "EUR",
          "timestamp": "2021-09-07T07:02:17.382+00:00"
        },
        {
          "name": "SEA A_P_EUR",
          "path": "eaera/asia/japan/sea-a_p_eur",
          "group": "real\\EAERA\\ASIA\\A_P_EUR",
          "enable": true,
          "parent": "/group_organization/eaera/asia/japan",
          "server": "live1",
          "currency": "EUR",
          "timestamp": "2021-09-07T07:02:19.932+00:00"
        },
        {
          "name": "SEA A_GBP",
          "path": "eaera/asia/japan/sea-a_gbp",
          "group": "real\\EAERA\\ASIA\\A_GBP",
          "enable": true,
          "parent": "/group_organization/eaera/asia/japan",
          "server": "live1",
          "currency": "GBP",
          "timestamp": "2021-09-07T07:02:24.312+00:00"
        },
        {
          "name": "SEA A_P_GBP",
          "path": "eaera/asia/japan/sea-a_p_gbp",
          "group": "real\\EAERA\\ASIA\\A_P_GBP",
          "enable": true,
          "parent": "/group_organization/eaera/asia/japan",
          "server": "live1",
          "currency": "GBP",
          "timestamp": "2021-09-07T07:02:27.568+00:00"
        },
        {
          "name": "SEA A_JPY",
          "path": "eaera/asia/japan/sea-a_jpy",
          "group": "real\\EAERA\\ASIA\\A_JPY",
          "enable": true,
          "parent": "/group_organization/eaera/asia/japan",
          "server": "live1",
          "currency": "JPY",
          "timestamp": "2021-09-07T07:02:30.933+00:00"
        },
        {
          "name": "SEA A_P_JPY",
          "path": "eaera/asia/japan/sea-a_p_jpy",
          "group": "real\\EAERA\\ASIA\\A_P_JPY",
          "enable": true,
          "parent": "/group_organization/eaera/asia/japan",
          "server": "live1",
          "currency": "JPY",
          "timestamp": "2021-09-07T07:02:33.872+00:00"
        },
        {
          "name": "SEA A_CHF",
          "path": "eaera/asia/japan/sea-a_chf",
          "group": "real\\EAERA\\ASIA\\A_CHF",
          "enable": true,
          "parent": "/group_organization/eaera/asia/japan",
          "server": "live1",
          "currency": "CHF",
          "timestamp": "2021-09-07T07:02:37.644+00:00"
        },
        {
          "name": "SEA A_P_CHF",
          "path": "eaera/asia/japan/sea-a_p_chf",
          "group": "real\\EAERA\\ASIA\\A_P_CHF",
          "enable": true,
          "parent": "/group_organization/eaera/asia/japan",
          "server": "live1",
          "currency": "CHF",
          "timestamp": "2021-09-07T07:02:40.419+00:00"
        },
        {
          "name": "JP A_USD",
          "path": "eaera/asia/japan/jp-a_usd",
          "group": "real\\EAERA\\JP\\A_USD",
          "enable": true,
          "parent": "/group_organization/eaera/asia/japan",
          "server": "live1",
          "currency": "USD",
          "timestamp": "2021-09-09T08:06:23.936+00:00"
        },
        {
          "name": "JP A_P_USD",
          "path": "eaera/asia/japan/jp-a_p_usd",
          "group": "real\\EAERA\\JP\\A_P_USD",
          "enable": true,
          "parent": "/group_organization/eaera/asia/japan",
          "server": "live1",
          "currency": "USD",
          "timestamp": "2021-09-07T07:02:46.624+00:00"
        },
        {
          "name": "JP A_EUR",
          "path": "eaera/asia/japan/jp-a_eur",
          "group": "real\\EAERA\\JP\\A_EUR",
          "enable": true,
          "parent": "/group_organization/eaera/asia/japan",
          "server": "live1",
          "currency": "EUR",
          "timestamp": "2021-09-07T07:02:49.696+00:00"
        },
        {
          "name": "JP A_P_EUR",
          "path": "eaera/asia/japan/jp-a_p_eur",
          "group": "real\\EAERA\\JP\\A_P_EUR",
          "enable": true,
          "parent": "/group_organization/eaera/asia/japan",
          "server": "live1",
          "currency": "EUR",
          "timestamp": "2021-09-07T07:02:53.062+00:00"
        },
        {
          "name": "JP A_GBP",
          "path": "eaera/asia/japan/jp-a_gbp",
          "group": "real\\EAERA\\JP\\A_GBP",
          "enable": true,
          "parent": "/group_organization/eaera/asia/japan",
          "server": "live1",
          "currency": "GBP",
          "timestamp": "2021-09-07T07:03:46.139+00:00"
        },
        {
          "name": "JP A_P_GBP",
          "path": "eaera/asia/japan/jp-a_p_gbp",
          "group": "real\\EAERA\\JP\\A_P_GBP",
          "enable": true,
          "parent": "/group_organization/eaera/asia/japan",
          "server": "live1",
          "currency": "GBP",
          "timestamp": "2021-09-07T07:03:48.917+00:00"
        },
        {
          "name": "JP A_JPY",
          "path": "eaera/asia/japan/jp-a_jpy",
          "group": "real\\EAERA\\JP\\A_JPY",
          "enable": true,
          "parent": "/group_organization/eaera/asia/japan",
          "server": "live1",
          "currency": "JPY",
          "timestamp": "2021-09-07T07:03:52.225+00:00"
        },
        {
          "name": "JP A_P_JPY",
          "path": "eaera/asia/japan/jp-a_p_jpy",
          "group": "real\\EAERA\\JP\\A_P_JPY",
          "enable": true,
          "parent": "/group_organization/eaera/asia/japan",
          "server": "live1",
          "currency": "JPY",
          "timestamp": "2021-09-07T07:03:55.811+00:00"
        },
        {
          "name": "JP A_CHF",
          "path": "eaera/asia/japan/jp-a_chf",
          "group": "real\\EAERA\\JP\\A_CHF",
          "enable": true,
          "parent": "/group_organization/eaera/asia/japan",
          "server": "live1",
          "currency": "CHF",
          "timestamp": "2021-09-07T07:03:58.951+00:00"
        },
        {
          "name": "JP A_P_CHF",
          "path": "eaera/asia/japan/jp-a_p_chf",
          "group": "real\\EAERA\\JP\\A_P_CHF",
          "enable": true,
          "parent": "/group_organization/eaera/asia/japan",
          "server": "live1",
          "currency": "CHF",
          "timestamp": "2021-09-07T07:04:02.498+00:00"
        },
        {
          "name": "SEA MilicaTestUSD",
          "path": "eaera/asia/japan/sea-milicatestusd",
          "group": "real\\EAERA\\ASIA\\MilicaTestUSD",
          "enable": true,
          "parent": "/group_organization/eaera/asia/japan",
          "server": "live1",
          "currency": "USD",
          "timestamp": "2021-10-04T12:36:26.284+00:00"
        },
        {
          "name": "SA ThinhTest",
          "path": null,
          "group": "real\\EAERA\\ASIA\\ThinhTestUSD",
          "enable": false,
          "parent": "/group_organization/eaera/asia/japan",
          "server": "live1",
          "currency": "USD",
          "timestamp": "2021-09-13T08:13:30.330+00:00"
        },
        {
          "name": "PhongTestUSD1",
          "path": "eaera/asia/japan/phongtestusd1",
          "group": "real\\EAERA\\ASIA\\PhongTestUSD",
          "enable": true,
          "parent": "/group_organization/eaera/asia/japan",
          "server": "live1",
          "currency": "USD",
          "timestamp": "2021-09-10T09:57:33.425+00:00"
        },
        {
          "name": "real\\EAERA\\ASIA\\PhongTestUSD1",
          "path": null,
          "group": "real\\EAERA\\ASIA\\PhongTestUSD1",
          "enable": false,
          "parent": "",
          "server": "live1",
          "currency": "USD",
          "timestamp": "2021-09-10T11:45:14.279+00:00"
        }
      ]
    },
    "ref_id": "6384c7dc-cfae-4074-8f45-0f226c07d09b",
    "ref_type": "SiteSetting",
    "children": {
      "eaera": {
        "short_name": "eaera",
        "schema": {
          "type": "object",
          "properties": {
            "name": {
              "type": "string"
            },
            "level": {
              "type": "number"
            }
          },
          "additionalProperties": true
        },
        "settings": {
          "name": "EAERA",
          "level": 1
        },
        "ref_id": "ea5f4ed5-9b82-4235-be29-ff7ea8d31c93",
        "ref_type": "SiteSetting",
        "children": {
          "asia": {
            "short_name": "asia",
            "schema": {
              "type": "object",
              "properties": {
                "name": {
                  "type": "string"
                },
                "level": {
                  "type": "number"
                }
              },
              "additionalProperties": true
            },
            "settings": {
              "name": "Asia",
              "level": 2
            },
            "ref_id": "d46260fe-af2a-4e32-9aa9-e1ab18b50eaf",
            "ref_type": "SiteSetting",
            "children": {
              "japan": {
                "short_name": "japan",
                "schema": {
                  "type": "object",
                  "properties": {
                    "name": {
                      "type": "string"
                    },
                    "level": {
                      "type": "number"
                    }
                  },
                  "additionalProperties": true
                },
                "settings": {
                  "name": "Japan",
                  "level": 3
                },
                "ref_id": "ddd3864c-bdb1-44ff-babc-be0dab2b31ea",
                "ref_type": "SiteSetting",
                "children": {
                  "sea-a_usd": {
                    "short_name": "sea-a_usd",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "SEA A_USD",
                      "path": "/group_organization/eaera/asia/japan",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "USD",
                      "short_path": "eaera/asia/japan",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\ASIA\\A_USD"
                    },
                    "ref_id": "451819a6-1ee9-4cfc-803c-5e4f930ac940",
                    "ref_type": "SiteSetting"
                  },
                  "sea-a_p_usd": {
                    "short_name": "sea-a_p_usd",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "SEA A_P_USD",
                      "path": "/group_organization/eaera/asia/japan",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "USD",
                      "short_path": "eaera/asia/japan",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\ASIA\\A_P_USD"
                    },
                    "ref_id": "8ee901e2-8f55-4a85-a68c-1837cf898f25",
                    "ref_type": "SiteSetting"
                  },
                  "sea-a_eur": {
                    "short_name": "sea-a_eur",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "SEA A_EUR",
                      "path": "/group_organization/eaera/asia/japan",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "EUR",
                      "short_path": "eaera/asia/japan",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\ASIA\\A_EUR"
                    },
                    "ref_id": "3b1cef29-fc6e-4ecb-9406-1e042d19235b",
                    "ref_type": "SiteSetting"
                  },
                  "sea-a_p_eur": {
                    "short_name": "sea-a_p_eur",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "SEA A_P_EUR",
                      "path": "/group_organization/eaera/asia/japan",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "EUR",
                      "short_path": "eaera/asia/japan",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\ASIA\\A_P_EUR"
                    },
                    "ref_id": "f4e3d08d-9c19-493c-b395-1c47b93705cc",
                    "ref_type": "SiteSetting"
                  },
                  "sea-a_gbp": {
                    "short_name": "sea-a_gbp",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "SEA A_GBP",
                      "path": "/group_organization/eaera/asia/japan",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "GBP",
                      "short_path": "eaera/asia/japan",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\ASIA\\A_GBP"
                    },
                    "ref_id": "158be3bf-bf5b-44e2-980b-1d26e4e2a9a6",
                    "ref_type": "SiteSetting"
                  },
                  "sea-a_p_gbp": {
                    "short_name": "sea-a_p_gbp",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "SEA A_P_GBP",
                      "path": "/group_organization/eaera/asia/japan",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "GBP",
                      "short_path": "eaera/asia/japan",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\ASIA\\A_P_GBP"
                    },
                    "ref_id": "85529390-db18-44d3-855b-4e931e2c8969",
                    "ref_type": "SiteSetting"
                  },
                  "sea-a_jpy": {
                    "short_name": "sea-a_jpy",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "SEA A_JPY",
                      "path": "/group_organization/eaera/asia/japan",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "JPY",
                      "short_path": "eaera/asia/japan",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\ASIA\\A_JPY"
                    },
                    "ref_id": "d14410b9-5d97-46c0-a274-35d2a2932430",
                    "ref_type": "SiteSetting"
                  },
                  "sea-a_p_jpy": {
                    "short_name": "sea-a_p_jpy",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "SEA A_P_JPY",
                      "path": "/group_organization/eaera/asia/japan",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "JPY",
                      "short_path": "eaera/asia/japan",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\ASIA\\A_P_JPY"
                    },
                    "ref_id": "6aa5e9e2-62eb-4272-8c95-d9f6ee69d02c",
                    "ref_type": "SiteSetting"
                  },
                  "sea-a_chf": {
                    "short_name": "sea-a_chf",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "SEA A_CHF",
                      "path": "/group_organization/eaera/asia/japan",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "CHF",
                      "short_path": "eaera/asia/japan",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\ASIA\\A_CHF"
                    },
                    "ref_id": "e9665d8d-2458-4aa1-9195-ed77c953f8e8",
                    "ref_type": "SiteSetting"
                  },
                  "sea-a_p_chf": {
                    "short_name": "sea-a_p_chf",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "SEA A_P_CHF",
                      "path": "/group_organization/eaera/asia/japan",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "CHF",
                      "short_path": "eaera/asia/japan",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\ASIA\\A_P_CHF"
                    },
                    "ref_id": "e43149b1-9d83-42ac-9f61-5bef9d799da2",
                    "ref_type": "SiteSetting"
                  },
                  "jp-a_p_usd": {
                    "short_name": "jp-a_p_usd",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "JP A_P_USD",
                      "path": "/group_organization/eaera/asia/japan",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "USD",
                      "short_path": "eaera/asia/japan",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\JP\\A_P_USD"
                    },
                    "ref_id": "088213a9-f9ff-4c9d-b062-cfceb504c799",
                    "ref_type": "SiteSetting"
                  },
                  "jp-a_eur": {
                    "short_name": "jp-a_eur",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "JP A_EUR",
                      "path": "/group_organization/eaera/asia/japan",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "EUR",
                      "short_path": "eaera/asia/japan",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\JP\\A_EUR"
                    },
                    "ref_id": "f71cab57-03f9-4c06-b8e4-542e1e8c59cf",
                    "ref_type": "SiteSetting"
                  },
                  "jp-a_usd": {
                    "short_name": "jp-a_usd",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "JP A_USD",
                      "path": "/group_organization/eaera/asia/japan",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "USD",
                      "short_path": "eaera/asia/japan",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\JP\\A_USD"
                    },
                    "ref_id": "36c73d06-3195-47f4-8e7f-eb5af0c2cd82",
                    "ref_type": "SiteSetting"
                  },
                  "jp-a_p_eur": {
                    "short_name": "jp-a_p_eur",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "JP A_P_EUR",
                      "path": "/group_organization/eaera/asia/japan",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "EUR",
                      "short_path": "eaera/asia/japan",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\JP\\A_P_EUR"
                    },
                    "ref_id": "27ef78f5-1c0b-46b3-a374-c860d0db4535",
                    "ref_type": "SiteSetting"
                  },
                  "jp-a_p_gbp": {
                    "short_name": "jp-a_p_gbp",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "JP A_P_GBP",
                      "path": "/group_organization/eaera/asia/japan",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "GBP",
                      "short_path": "eaera/asia/japan",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\JP\\A_P_GBP"
                    },
                    "ref_id": "096b986b-905b-49c3-9094-ea1b1eb83c68",
                    "ref_type": "SiteSetting"
                  },
                  "jp-a_gbp": {
                    "short_name": "jp-a_gbp",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "JP A_GBP",
                      "path": "/group_organization/eaera/asia/japan",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "GBP",
                      "short_path": "eaera/asia/japan",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\JP\\A_GBP"
                    },
                    "ref_id": "3034d4a5-0f8b-49b1-8f7a-581dc93bb57c",
                    "ref_type": "SiteSetting"
                  },
                  "jp-a_jpy": {
                    "short_name": "jp-a_jpy",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "JP A_JPY",
                      "path": "/group_organization/eaera/asia/japan",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "JPY",
                      "short_path": "eaera/asia/japan",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\JP\\A_JPY"
                    },
                    "ref_id": "d4ebe10a-7984-44f7-9b35-ed3da152e480",
                    "ref_type": "SiteSetting"
                  },
                  "jp-a_p_chf": {
                    "short_name": "jp-a_p_chf",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "JP A_P_CHF",
                      "path": "/group_organization/eaera/asia/japan",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "CHF",
                      "short_path": "eaera/asia/japan",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\JP\\A_P_CHF"
                    },
                    "ref_id": "8de5a9b8-7f0c-4566-b4ba-07adfefa3126",
                    "ref_type": "SiteSetting"
                  },
                  "jp-a_p_jpy": {
                    "short_name": "jp-a_p_jpy",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "JP A_P_JPY",
                      "path": "/group_organization/eaera/asia/japan",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "JPY",
                      "short_path": "eaera/asia/japan",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\JP\\A_P_JPY"
                    },
                    "ref_id": "37fe67ed-2441-4a62-8d71-5f3c45c864c4",
                    "ref_type": "SiteSetting"
                  },
                  "jp-a_chf": {
                    "short_name": "jp-a_chf",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "JP A_CHF",
                      "path": "/group_organization/eaera/asia/japan",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "CHF",
                      "short_path": "eaera/asia/japan",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\JP\\A_CHF"
                    },
                    "ref_id": "3ec5f452-abc8-489a-b691-e1222ec015fb",
                    "ref_type": "SiteSetting"
                  },
                  "phongtestusd1": {
                    "short_name": "phongtestusd1",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "PhongTestUSD1",
                      "path": "/group_organization/eaera/asia/japan",
                      "level": 4,
                      "created": "9/10/2021",
                      "currency": "USD",
                      "short_path": "eaera/asia/japan",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\ASIA\\PhongTestUSD"
                    },
                    "ref_id": "18aa2291-aeb4-478e-9a34-234743837bf9",
                    "ref_type": "SiteSetting"
                  },
                  "sea-milicatestusd": {
                    "short_name": "sea-milicatestusd",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "SEA MilicaTestUSD",
                      "path": "/group_organization/eaera/asia/japan",
                      "level": 4,
                      "created": "10/4/2021",
                      "currency": "USD",
                      "short_path": "eaera/asia/japan",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\ASIA\\MilicaTestUSD"
                    },
                    "ref_id": "3e671a03-b979-4022-ae4f-62a3b9209f6c",
                    "ref_type": "SiteSetting"
                  }
                }
              }
            }
          },
          "europe": {
            "short_name": "europe",
            "schema": {
              "type": "object",
              "properties": {
                "name": {
                  "type": "string"
                },
                "level": {
                  "type": "number"
                }
              },
              "additionalProperties": true
            },
            "settings": {
              "name": "Europe",
              "level": 2
            },
            "ref_id": "cd4241fa-bba5-4c86-946a-53064b9fc0ec",
            "ref_type": "SiteSetting",
            "children": {
              "eu": {
                "short_name": "eu",
                "schema": {
                  "type": "object",
                  "properties": {
                    "name": {
                      "type": "string"
                    },
                    "level": {
                      "type": "number"
                    }
                  },
                  "additionalProperties": true
                },
                "settings": {
                  "name": "EU",
                  "level": 3
                },
                "ref_id": "0e44664c-b97e-4af4-bcc9-5a75702e18ab",
                "ref_type": "SiteSetting",
                "children": {
                  "eu-a_p_usd": {
                    "short_name": "eu-a_p_usd",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "EU A_P_USD",
                      "path": "/group_organization/eaera/europe/eu",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "USD",
                      "short_path": "eaera/europe/eu",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\EU\\A_P_USD"
                    },
                    "ref_id": "aae9ce1a-3685-4414-a8fa-1bc8ba161e26",
                    "ref_type": "SiteSetting"
                  },
                  "eu-a_p_eur": {
                    "short_name": "eu-a_p_eur",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "EU A_P_EUR",
                      "path": "/group_organization/eaera/europe/eu",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "EUR",
                      "short_path": "eaera/europe/eu",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\EU\\A_P_EUR"
                    },
                    "ref_id": "7cf9c1cd-d634-4203-bbbe-5841df21439b",
                    "ref_type": "SiteSetting"
                  },
                  "eu-a_p_gbp": {
                    "short_name": "eu-a_p_gbp",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "EU A_P_GBP",
                      "path": "/group_organization/eaera/europe/eu",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "GBP",
                      "short_path": "eaera/europe/eu",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\EU\\A_P_GBP"
                    },
                    "ref_id": "d4922985-f0f9-4975-9465-63819f4a7539",
                    "ref_type": "SiteSetting"
                  },
                  "eu-a_jpy": {
                    "short_name": "eu-a_jpy",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "EU A_JPY",
                      "path": "/group_organization/eaera/europe/eu",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "JPY",
                      "short_path": "eaera/europe/eu",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\EU\\A_JPY"
                    },
                    "ref_id": "1c08d581-8dca-4c5d-be44-c1b5de88191f",
                    "ref_type": "SiteSetting"
                  },
                  "eu-a_p_jpy": {
                    "short_name": "eu-a_p_jpy",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "EU A_P_JPY",
                      "path": "/group_organization/eaera/europe/eu",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "JPY",
                      "short_path": "eaera/europe/eu",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\EU\\A_P_JPY"
                    },
                    "ref_id": "27718df8-b3a4-456b-9137-18c38a01311a",
                    "ref_type": "SiteSetting"
                  },
                  "eu-a_chf": {
                    "short_name": "eu-a_chf",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "EU A_CHF",
                      "path": "/group_organization/eaera/europe/eu",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "CHF",
                      "short_path": "eaera/europe/eu",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\EU\\A_CHF"
                    },
                    "ref_id": "1db885a7-a953-4852-bf2d-0054bab76ae4",
                    "ref_type": "SiteSetting"
                  },
                  "eu-a_p_chf": {
                    "short_name": "eu-a_p_chf",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "EU A_P_CHF",
                      "path": "/group_organization/eaera/europe/eu",
                      "level": 4,
                      "created": "9/7/2021",
                      "currency": "CHF",
                      "short_path": "eaera/europe/eu",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\EU\\A_P_CHF"
                    },
                    "ref_id": "b8dbb89e-ea8e-4a26-b1aa-5d56c436dd64",
                    "ref_type": "SiteSetting"
                  },
                  "eu-a_gbp": {
                    "short_name": "eu-a_gbp",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "EU A_GBP",
                      "path": "/group_organization/eaera/europe/eu",
                      "level": 4,
                      "created": "9/8/2021",
                      "currency": "GBP",
                      "short_path": "eaera/europe/eu",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\EU\\A_GBP"
                    },
                    "ref_id": "e9448168-f5e2-4cf0-abcf-eca46f18cf02",
                    "ref_type": "SiteSetting"
                  },
                  "eu-a_eur-1": {
                    "short_name": "eu-a_eur-1",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "EU A_EUR",
                      "path": "/group_organization/eaera/europe/eu",
                      "level": 4,
                      "created": "9/9/2021",
                      "currency": "EUR",
                      "short_path": "eaera/europe/eu",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\EU\\A_EUR"
                    },
                    "ref_id": "fc320d75-3761-4a91-a0d5-6a11770b744c",
                    "ref_type": "SiteSetting"
                  },
                  "eu-a_usd": {
                    "short_name": "eu-a_usd",
                    "schema": {
                      "type": "object",
                      "properties": {
                        "name": {
                          "type": "string"
                        },
                        "level": {
                          "type": "number"
                        }
                      },
                      "additionalProperties": true
                    },
                    "settings": {
                      "name": "EU A_USD",
                      "path": "/group_organization/eaera/europe/eu",
                      "level": 4,
                      "created": "9/13/2021",
                      "currency": "USD",
                      "short_path": "eaera/europe/eu",
                      "server_name": "live1",
                      "platform_group": "real\\EAERA\\EU\\A_USD"
                    },
                    "ref_id": "e2afb7c4-1b4e-429f-95ef-490ba5afa510",
                    "ref_type": "SiteSetting"
                  }
                }
              }
            }
          },
          "test": {
            "short_name": "test",
            "schema": {
              "type": "object",
              "properties": {
                "name": {
                  "type": "string"
                },
                "level": {
                  "type": "number"
                }
              },
              "additionalProperties": true
            },
            "settings": {
              "name": "test",
              "level": 2
            },
            "ref_id": "1c160f79-c5f3-457d-b6cf-6c117a985cb1",
            "ref_type": "SiteSetting",
            "children": {
              "test1": {
                "short_name": "test1",
                "schema": {
                  "type": "object",
                  "properties": {
                    "name": {
                      "type": "string"
                    },
                    "level": {
                      "type": "number"
                    }
                  },
                  "additionalProperties": true
                },
                "settings": {
                  "name": "test1",
                  "level": 3
                },
                "ref_id": "4250acff-6b1d-41c1-bf6e-1a1f95c8257d",
                "ref_type": "SiteSetting"
              }
            }
          }
        }
      }
    }
  }