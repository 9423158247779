import mock from "@/@fake-db/mock";
import { getRandomInt } from "@/@fake-db/utils";

const MtOpenTrade = {
    data: [
        {
            id: 1,
            server: "Mt5-",
            login: getRandomInt(1, 50000),
            symbol: "USDJPY",
            external_id: "position-" + getRandomInt(1, 50000),
            open_time: "2021-10-06T14:09:21.000Z",
            reason: getRandomInt(0, 2),
            volume: getRandomInt(1, 50000),
            profit: getRandomInt(1, 50000),
            storage: getRandomInt(1, 1000),
            action: getRandomInt(0, 7),
            currency: "USD",
            path: "XTX Markets/EU/London",
            mt_account: {
                name: "name" + getRandomInt(1, 50000),
                email: "",
                server: "Mt5",
                login: getRandomInt(1, 50000),
                group: "string",
            },
        },
        {
            id: 2,
            server: "Mt5-",
            login: getRandomInt(1, 50000),
            symbol: "USDJPY",
            external_id: "position-" + getRandomInt(1, 50000),
            open_time: "2021-10-06T14:09:21.000Z",
            reason: getRandomInt(0, 2),
            volume: getRandomInt(1, 50000),
            profit: getRandomInt(1, 50000),
            storage: getRandomInt(1, 1000),
            action: getRandomInt(0, 7),
            currency: "USD",
            path: "XTX Markets/EU/London",
            mt_account: {
                name: "name" + getRandomInt(1, 50000),
                email: "",
                server: "Mt5",
                login: getRandomInt(1, 50000),
                group: "string",
            },
        },
        {
            id: 3,
            server: "Mt5-",
            login: getRandomInt(1, 50000),
            symbol: "USDJPY",
            external_id: "position-" + getRandomInt(1, 50000),
            open_time: "2021-10-06T14:09:21.000Z",
            reason: getRandomInt(0, 2),
            volume: getRandomInt(1, 50000),
            profit: getRandomInt(1, 50000),
            storage: getRandomInt(1, 1000),
            action: getRandomInt(0, 7),
            currency: "USD",
            path: "XTX Markets/ASIA/Tokyo",
            mt_account: {
                name: "name" + getRandomInt(1, 50000),
                email: "",
                server: "Mt5",
                login: getRandomInt(1, 50000),
                group: "string",
            },
        },
        {
            id: 4,
            server: "Mt5-",
            login: getRandomInt(1, 50000),
            symbol: "USDJPY",
            external_id: "position-" + getRandomInt(1, 50000),
            open_time: "2021-10-06T14:09:21.000Z",
            reason: getRandomInt(0, 2),
            volume: getRandomInt(1, 50000),
            profit: getRandomInt(1, 50000),
            storage: getRandomInt(1, 1000),
            action: getRandomInt(0, 7),
            currency: "USD",
            path: "XTX Markets/ASIA/Tokyo",
            mt_account: {
                name: "name" + getRandomInt(1, 50000),
                email: "",
                server: "Mt5",
                login: getRandomInt(1, 50000),
                group: "string",
            },
        },

        {
            id: 5,
            server: "Mt5-",
            login: getRandomInt(1, 50000),
            symbol: "USDJPY",
            external_id: "position-" + getRandomInt(1, 50000),
            open_time: "2021-10-06T14:09:21.000Z",
            reason: getRandomInt(0, 2),
            volume: getRandomInt(1, 50000),
            profit: getRandomInt(1, 50000),
            storage: getRandomInt(1, 1000),
            action: getRandomInt(0, 7),
            currency: "USD",
            path: "XTX Markets/EU/London",
            mt_account: {
                name: "name" + getRandomInt(1, 50000),
                email: "",
                server: "Mt5",
                login: getRandomInt(1, 50000),
                group: "string",
            },
        },
        {
            id: 6,
            server: "Mt5-",
            login: getRandomInt(1, 50000),
            symbol: "USDJPY",
            external_id: "position-" + getRandomInt(1, 50000),
            open_time: "2021-10-06T14:09:21.000Z",
            reason: getRandomInt(0, 2),
            volume: getRandomInt(1, 50000),
            profit: getRandomInt(1, 50000),
            storage: getRandomInt(1, 1000),
            action: getRandomInt(0, 7),
            currency: "USD",
            path: "XTX Markets/EU/London",
            mt_account: {
                name: "name" + getRandomInt(1, 50000),
                email: "",
                server: "Mt5",
                login: getRandomInt(1, 50000),
                group: "string",
            },
        },
        {
            id: 7,
            server: "Mt5-",
            login: getRandomInt(1, 50000),
            symbol: "USDJPY",
            external_id: "position-" + getRandomInt(1, 50000),
            open_time: "2021-10-06T14:09:21.000Z",
            reason: getRandomInt(0, 2),
            volume: getRandomInt(1, 50000),
            profit: getRandomInt(1, 50000),
            storage: getRandomInt(1, 1000),
            action: getRandomInt(0, 7),
            currency: "USD",
            path: "XTX Markets/ASIA/Tokyo",
            mt_account: {
                name: "name" + getRandomInt(1, 50000),
                email: "",
                server: "Mt5",
                login: getRandomInt(1, 50000),
                group: "string",
            },
        },
        {
            id: 8,
            server: "Mt5-",
            login: getRandomInt(1, 50000),
            symbol: "USDJPY",
            external_id: "position-" + getRandomInt(1, 50000),
            open_time: "2021-10-06T14:09:21.000Z",
            reason: getRandomInt(0, 2),
            volume: getRandomInt(1, 50000),
            profit: getRandomInt(1, 50000),
            storage: getRandomInt(1, 1000),
            action: getRandomInt(0, 7),
            currency: "USD",
            path: "XTX Markets/ASIA/Tokyo",
            mt_account: {
                name: "name" + getRandomInt(1, 50000),
                email: "",
                server: "Mt5",
                login: getRandomInt(1, 50000),
                group: "string",
            },
        },
    ],
};

mock.onGet("/MtOpenTrade/data").reply(() => [200, MtOpenTrade.data]);
