import mock from "@/@fake-db/mock";

const RiskThreshold = {
    short_name: "risk_threshold",
    schema: { type: "object" },
    settings: {
        custom: {
            C: { high: 150000, medium: 100000 },
            F: { high: 150000, medium: 100000 },
            T: { high: 150000, medium: 100000 },
            V: { high: 150000, medium: 100000 },
            BA: { high: 150000, medium: 100000 },
            BK: { high: 150000, medium: 100000 },
            BP: { high: 150000, medium: 100000 },
            CL: { high: 150000, medium: 100000 },
            DD: { high: 150000, medium: 100000 },
            GD: { high: 150000, medium: 100000 },
            GE: { high: 150000, medium: 100000 },
            GM: { high: 150000, medium: 100000 },
            GS: { high: 150000, medium: 100000 },
            HD: { high: 150000, medium: 100000 },
            KO: { high: 150000, medium: 100000 },
            MA: { high: 150000, medium: 100000 },
            MO: { high: 150000, medium: 100000 },
            MS: { high: 150000, medium: 100000 },
            PG: { high: 150000, medium: 100000 },
            PM: { high: 150000, medium: 100000 },
            SO: { high: 150000, medium: 100000 },
            VZ: { high: 150000, medium: 100000 },
            ABT: { high: 150000, medium: 100000 },
            ACN: { high: 150000, medium: 100000 },
            AIG: { high: 150000, medium: 100000 },
            AMD: { high: 150000, medium: 100000 },
            AMT: { high: 150000, medium: 100000 },
            AXP: { high: 150000, medium: 100000 },
            BAC: { high: 150000, medium: 100000 },
            BLK: { high: 150000, medium: 100000 },
            BMW: { high: 150000, medium: 100000 },
            BMY: { high: 150000, medium: 100000 },
            CAP: { high: 150000, medium: 100000 },
            CAT: { high: 150000, medium: 100000 },
            COF: { high: 150000, medium: 100000 },
            COP: { high: 150000, medium: 100000 },
            CRM: { high: 150000, medium: 100000 },
            CVS: { high: 150000, medium: 100000 },
            CVX: { high: 150000, medium: 100000 },
            DHR: { high: 150000, medium: 100000 },
            DIS: { high: 150000, medium: 100000 },
            DOW: { high: 150000, medium: 100000 },
            DUK: { high: 150000, medium: 100000 },
            EDF: { high: 150000, medium: 100000 },
            EMR: { high: 150000, medium: 100000 },
            ENI: { high: 150000, medium: 100000 },
            EXC: { high: 150000, medium: 100000 },
            FDS: { high: 150000, medium: 100000 },
            FDX: { high: 150000, medium: 100000 },
            GSK: { high: 150000, medium: 100000 },
            HON: { high: 150000, medium: 100000 },
            IBM: { high: 150000, medium: 100000 },
            JNJ: { high: 150000, medium: 100000 },
            JPM: { high: 150000, medium: 100000 },
            KHC: { high: 150000, medium: 100000 },
            LIN: { high: 150000, medium: 100000 },
            LLY: { high: 150000, medium: 100000 },
            LMT: { high: 150000, medium: 100000 },
            LOW: { high: 150000, medium: 100000 },
            MCD: { high: 150000, medium: 100000 },
            MDT: { high: 150000, medium: 100000 },
            MET: { high: 150000, medium: 100000 },
            MMM: { high: 150000, medium: 100000 },
            MRK: { high: 150000, medium: 100000 },
            N25: { high: 150000, medium: 100000 },
            NEE: { high: 150000, medium: 100000 },
            NKE: { high: 150000, medium: 100000 },
            PEP: { high: 150000, medium: 100000 },
            PFE: { high: 150000, medium: 100000 },
            RTX: { high: 150000, medium: 100000 },
            RWE: { high: 150000, medium: 100000 },
            SAP: { high: 150000, medium: 100000 },
            SPG: { high: 150000, medium: 100000 },
            TGT: { high: 150000, medium: 100000 },
            TMO: { high: 150000, medium: 100000 },
            TXN: { high: 150000, medium: 100000 },
            UNH: { high: 150000, medium: 100000 },
            UNP: { high: 150000, medium: 100000 },
            UPS: { high: 150000, medium: 100000 },
            USB: { high: 150000, medium: 100000 },
            WBA: { high: 150000, medium: 100000 },
            WFC: { high: 150000, medium: 100000 },
            WMT: { high: 150000, medium: 100000 },
            WPP: { high: 150000, medium: 100000 },
            XOM: { high: 150000, medium: 100000 },
            AAPL: { high: 150000, medium: 100000 },
            ABBV: { high: 150000, medium: 100000 },
            ADBE: { high: 150000, medium: 100000 },
            AMGN: { high: 150000, medium: 100000 },
            AMZN: { high: 150000, medium: 100000 },
            AVGO: { high: 150000, medium: 100000 },
            BABA: { high: 150000, medium: 100000 },
            BASF: { high: 150000, medium: 100000 },
            BIDU: { high: 150000, medium: 100000 },
            BKNG: { high: 150000, medium: 100000 },
            CHTR: { high: 150000, medium: 100000 },
            COST: { high: 150000, medium: 100000 },
            CSCO: { high: 150000, medium: 100000 },
            Corn: { high: 150000, medium: 100000 },
            DE30: { high: 150000, medium: 100000 },
            ENEL: { high: 150000, medium: 100000 },
            EOAN: { high: 150000, medium: 100000 },
            EU50: { high: 150000, medium: 100000 },
            GILD: { high: 150000, medium: 100000 },
            GOLD: { high: 150000, medium: 100000 },
            GOOG: { high: 150000, medium: 100000 },
            HK50: { high: 150000, medium: 100000 },
            HSBC: { high: 150000, medium: 100000 },
            INTC: { high: 150000, medium: 100000 },
            LVMH: { high: 150000, medium: 100000 },
            MDLZ: { high: 150000, medium: 100000 },
            META: { high: 150000, medium: 100000 },
            MSFT: { high: 150000, medium: 100000 },
            NFLX: { high: 150000, medium: 100000 },
            NVDA: { high: 150000, medium: 100000 },
            ORCL: { high: 150000, medium: 100000 },
            PYPL: { high: 150000, medium: 100000 },
            QCOM: { high: 150000, medium: 100000 },
            Rice: { high: 150000, medium: 100000 },
            SBUX: { high: 150000, medium: 100000 },
            SCHW: { high: 150000, medium: 100000 },
            TMUS: { high: 150000, medium: 100000 },
            TSLA: { high: 150000, medium: 100000 },
            TWTR: { high: 150000, medium: 100000 },
            US30: { high: 150000, medium: 100000 },
            WIZZ: { high: 150000, medium: 100000 },
            Avast: { high: 150000, medium: 100000 },
            Aviva: { high: 150000, medium: 100000 },
            BAYER: { high: 150000, medium: 100000 },
            BNPEU: { high: 150000, medium: 100000 },
            "BRK.B": { high: 150000, medium: 100000 },
            CMCSA: { high: 150000, medium: 100000 },
            COCOA: { high: 150000, medium: 100000 },
            Duerr: { high: 150000, medium: 100000 },
            FRA40: { high: 150000, medium: 100000 },
            ILIAD: { high: 150000, medium: 100000 },
            JP225: { high: 150000, medium: 100000 },
            Linde: { high: 150000, medium: 100000 },
            METRO: { high: 150000, medium: 100000 },
            SPA35: { high: 150000, medium: 100000 },
            SWI20: { high: 150000, medium: 100000 },
            TESCO: { high: 150000, medium: 100000 },
            TOTAL: { high: 150000, medium: 100000 },
            UK100: { high: 150000, medium: 100000 },
            UKOil: { high: 150000, medium: 100000 },
            US100: { high: 150000, medium: 100000 },
            US500: { high: 150000, medium: 100000 },
            USOil: { high: 150000, medium: 100000 },
            VINCI: { high: 150000, medium: 100000 },
            Wheat: { high: 150000, medium: 100000 },
            ADIDAS: { high: 150000, medium: 100000 },
            AIRBUS: { high: 150000, medium: 100000 },
            AUDCAD: { high: 150000, medium: 100000 },
            AUDCHF: { high: 150000, medium: 100000 },
            AUDJPY: { high: 150000, medium: 100000 },
            AUDNZD: { high: 150000, medium: 100000 },
            AUDSGD: { high: 150000, medium: 100000 },
            AUDUSD: { high: 150000, medium: 100000 },
            AUS200: { high: 150000, medium: 100000 },
            BCHUSD: { high: 150000, medium: 100000 },
            BTCUSD: { high: 150000, medium: 100000 },
            CADCHF: { high: 150000, medium: 100000 },
            CADJPY: { high: 150000, medium: 100000 },
            CHFJPY: { high: 150000, medium: 100000 },
            CHFSGD: { high: 150000, medium: 100000 },
            COTTON: { high: 150000, medium: 100000 },
            DSHUSD: { high: 150000, medium: 100000 },
            Danone: { high: 150000, medium: 100000 },
            EOSUSD: { high: 150000, medium: 100000 },
            ETHUSD: { high: 150000, medium: 100000 },
            EURAUD: { high: 150000, medium: 100000 },
            EURCAD: { high: 150000, medium: 100000 },
            EURCHF: { high: 150000, medium: 100000 },
            EURDKK: { high: 150000, medium: 100000 },
            EURGBP: { high: 150000, medium: 100000 },
            EURHKD: { high: 150000, medium: 100000 },
            EURJPY: { high: 150000, medium: 100000 },
            EURNOK: { high: 150000, medium: 100000 },
            EURNZD: { high: 150000, medium: 100000 },
            EURPLN: { high: 150000, medium: 100000 },
            EURSEK: { high: 150000, medium: 100000 },
            EURSGD: { high: 150000, medium: 100000 },
            EURTRY: { high: 150000, medium: 100000 },
            EURUSD: { high: 150000, medium: 100000 },
            EURZAR: { high: 150000, medium: 100000 },
            GBPAUD: { high: 150000, medium: 100000 },
            GBPCAD: { high: 150000, medium: 100000 },
            GBPCHF: { high: 150000, medium: 100000 },
            GBPDKK: { high: 150000, medium: 100000 },
            GBPJPY: { high: 150000, medium: 100000 },
            GBPNOK: { high: 150000, medium: 100000 },
            GBPNZD: { high: 150000, medium: 100000 },
            GBPSEK: { high: 150000, medium: 100000 },
            GBPSGD: { high: 150000, medium: 100000 },
            GBPTRY: { high: 150000, medium: 100000 },
            GBPUSD: { high: 150000, medium: 100000 },
            Henkel: { high: 150000, medium: 100000 },
            Hermes: { high: 150000, medium: 100000 },
            LTCUSD: { high: 150000, medium: 100000 },
            Lloyds: { high: 150000, medium: 100000 },
            Loreal: { high: 150000, medium: 100000 },
            NZDCAD: { high: 150000, medium: 100000 },
            NZDCHF: { high: 150000, medium: 100000 },
            NZDJPY: { high: 150000, medium: 100000 },
            NZDUSD: { high: 150000, medium: 100000 },
            PNCUSD: { high: 150000, medium: 100000 },
            SANOFI: { high: 150000, medium: 100000 },
            SGDJPY: { high: 150000, medium: 100000 },
            SILVER: { high: 150000, medium: 100000 },
            USDCAD: { high: 150000, medium: 100000 },
            USDCHF: { high: 150000, medium: 100000 },
            USDCNH: { high: 150000, medium: 100000 },
            USDCZK: { high: 150000, medium: 100000 },
            USDDKK: { high: 150000, medium: 100000 },
            USDHKD: { high: 150000, medium: 100000 },
            USDHUF: { high: 150000, medium: 100000 },
            USDJPY: { high: 150000, medium: 100000 },
            USDMXN: { high: 150000, medium: 100000 },
            USDNOK: { high: 150000, medium: 100000 },
            USDPLN: { high: 150000, medium: 100000 },
            USDRUB: { high: 150000, medium: 100000 },
            USDSEK: { high: 150000, medium: 100000 },
            USDSGD: { high: 150000, medium: 100000 },
            USDTHB: { high: 150000, medium: 100000 },
            USDTRY: { high: 150000, medium: 100000 },
            USDUSX: { high: 150000, medium: 100000 },
            USDZAR: { high: 150000, medium: 100000 },
            XRPUSD: { high: 150000, medium: 100000 },
            Xiaomi: { high: 150000, medium: 100000 },
            ALLIANZ: { high: 150000, medium: 100000 },
            ARABICA: { high: 150000, medium: 100000 },
            CHINA50: { high: 150000, medium: 100000 },
            DAIMLER: { high: 150000, medium: 100000 },
            MerckDE: { high: 150000, medium: 100000 },
            PEUGEOT: { high: 150000, medium: 100000 },
            ROBUSTA: { high: 150000, medium: 100000 },
            Renault: { high: 150000, medium: 100000 },
            SIEMENS: { high: 150000, medium: 100000 },
            SOCIETE: { high: 150000, medium: 100000 },
            Tencent: { high: 150000, medium: 100000 },
            Vonovia: { high: 150000, medium: 100000 },
            easyJet: { high: 150000, medium: 100000 },
            AGRICOLE: { high: 150000, medium: 100000 },
            Barclays: { high: 150000, medium: 100000 },
            Burberry: { high: 150000, medium: 100000 },
            Covestro: { high: 150000, medium: 100000 },
            FAURECIA: { high: 150000, medium: 100000 },
            GLENCORE: { high: 150000, medium: 100000 },
            INFINEON: { high: 150000, medium: 100000 },
            "JD.comHK": { high: 150000, medium: 100000 },
            Juventus: { high: 150000, medium: 100000 },
            MICHELIN: { high: 150000, medium: 100000 },
            OrangeFR: { high: 150000, medium: 100000 },
            PLATINUM: { high: 150000, medium: 100000 },
            RioTinto: { high: 150000, medium: 100000 },
            Soybeans: { high: 150000, medium: 100000 },
            Unilever: { high: 150000, medium: 100000 },
            VODAFONE: { high: 150000, medium: 100000 },
            ALIBABAHK: { high: 150000, medium: 100000 },
            BATobacco: { high: 150000, medium: 100000 },
            BorussiaD: { high: 150000, medium: 100000 },
            CARREFOUR: { high: 150000, medium: 100000 },
            Deuroshop: { high: 150000, medium: 100000 },
            Fresenius: { high: 150000, medium: 100000 },
            LUFTHANSA: { high: 150000, medium: 100000 },
            PALLADIUM: { high: 150000, medium: 100000 },
            "SUGAR.RAW": { high: 150000, medium: 100000 },
            Beiersdorf: { high: 150000, medium: 100000 },
            NaturalGas: { high: 150000, medium: 100000 },
            ROLLSROYCE: { high: 150000, medium: 100000 },
            VolksWagen: { high: 150000, medium: 100000 },
            ASTRAZENECA: { high: 150000, medium: 100000 },
            BankOfChina: { high: 150000, medium: 100000 },
            COMMERZBANK: { high: 150000, medium: 100000 },
            Continental: { high: 150000, medium: 100000 },
            DeutschBank: { high: 150000, medium: 100000 },
            DeutscheTel: { high: 150000, medium: 100000 },
            DollarIndex: { high: 150000, medium: 100000 },
            "SUGAR.WHITE": { high: 150000, medium: 100000 },
            SaintGobain: { high: 150000, medium: 100000 },
            DeutscheBors: { high: 150000, medium: 100000 },
            DeutschePost: { high: 150000, medium: 100000 },
            FreseniusMed: { high: 150000, medium: 100000 },
            HeidelCement: { high: 150000, medium: 100000 },
            ThyssenKrupp: { high: 150000, medium: 100000 },
        },
        default: { high: 150000, medium: 100000 },
        default_high: 20000,
        default_medium: 100000,
    },
    created_at: "2021-08-31T08:36:56.334Z",
    ref_id: "6df94f73-f2ce-4664-a2f1-d3d8d99a0378",
    ref_type: "SiteSetting",
};

mock.onGet("/RiskThreshold/data").reply(() => [200, RiskThreshold]);
