import mock from "@/@fake-db/mock";

const MtAccount = [
    {
        id: 1491,
        server: "demo",
        timestamp: "2022-10-14T09:20:09.662Z",
        login: 300225,
        group: "demo\\EAERA\\A_USD",
        enable: true,
        read_only: false,
        name: "John Doe",
        country: "DE",
        city: "Hanoi",
        state: "",
        zipcode: "10000",
        address: "Hanoi",
        lead_source: "",
        phone: "+4912345",
        email: "eaeratest+full_action@coczrnix.mailosaur.net",
        identifier: null,
        comment: "",
        status: "",
        register_time: "2022-06-16T11:47:05.000Z",
        last_login: "2022-06-16T11:47:05.000Z",
        last_ip: null,
        leverage: 100,
        agent_account: 0,
        balance: 500.0,
        credit: 300.0,
        prev_balance: 500.0,
        prevmonth_balance: 500.0,
        prev_equity: 800.0,
        prevmonth_equity: 800.0,
        currency: "USD",
        lock: false,
    },
    {
        id: 1752,
        server: "demo",
        timestamp: "2022-10-14T09:20:13.148Z",
        login: 300312,
        group: "demo\\EAERA\\A_P_EUR",
        enable: true,
        read_only: false,
        name: "Phong Le",
        country: "VN",
        city: "HN",
        state: "",
        zipcode: "11311",
        address: "DV",
        lead_source: "",
        phone: "+841312312312",
        email: "phong.le+700@eaera.com",
        identifier: null,
        comment: "",
        status: "",
        register_time: "2022-09-24T10:16:28.000Z",
        last_login: "2022-09-24T10:16:28.000Z",
        last_ip: null,
        leverage: 10,
        agent_account: 0,
        balance: 1500.0,
        credit: 515.82,
        prev_balance: 1500.0,
        prevmonth_balance: 1500.0,
        prev_equity: 2015.82,
        prevmonth_equity: 2015.82,
        currency: "EUR",
        lock: false,
    },
    {
        id: 1373,
        server: "demo",
        timestamp: "2022-10-14T09:20:05.858Z",
        login: 300079,
        group: "demo\\EAERA\\A_USD",
        enable: true,
        read_only: false,
        name: "FirstNameTest1654015239227 LastNameTest1654015239227",
        country: "CN",
        city: "",
        state: "",
        zipcode: "",
        address: "",
        lead_source: "",
        phone: "",
        email: "eaeratest+1654015239227@gmail.com",
        identifier: null,
        comment: "",
        status: "",
        register_time: "2022-05-31T15:41:26.000Z",
        last_login: "2022-05-31T15:41:26.000Z",
        last_ip: null,
        leverage: 100,
        agent_account: 0,
        balance: 0.0,
        credit: 0.0,
        prev_balance: 0.0,
        prevmonth_balance: 0.0,
        prev_equity: 0.0,
        prevmonth_equity: 0.0,
        currency: "USD",
        lock: false,
    },
    {
        id: 1376,
        server: "demo",
        timestamp: "2022-10-14T09:20:05.966Z",
        login: 300082,
        group: "demo\\EAERA\\A_USD",
        enable: true,
        read_only: false,
        name: "John Doe",
        country: "DE",
        city: "Hanoi",
        state: "",
        zipcode: "10000",
        address: "hanoi",
        lead_source: "",
        phone: "+841234567",
        email: "eaeratest+4@gmail.com",
        identifier: null,
        comment: "",
        status: "",
        register_time: "2022-06-01T01:11:29.000Z",
        last_login: "2022-06-01T01:11:29.000Z",
        last_ip: null,
        leverage: 100,
        agent_account: 0,
        balance: 506.82,
        credit: 300.0,
        prev_balance: 506.82,
        prevmonth_balance: 506.82,
        prev_equity: 1023.45,
        prevmonth_equity: 1008.23,
        currency: "USD",
        lock: false,
    },
    {
        id: 1394,
        server: "demo",
        timestamp: "2022-10-14T09:20:06.574Z",
        login: 300100,
        group: "demo\\EAERA\\A_USD",
        enable: true,
        read_only: false,
        name: "FirstNameTest1654156590400 LastNameTest1654156590400",
        country: "CN",
        city: "",
        state: "",
        zipcode: "",
        address: "",
        lead_source: "",
        phone: "",
        email: "eaeratest+1654156590400@gmail.com",
        identifier: null,
        comment: "",
        status: "",
        register_time: "2022-06-02T06:57:16.000Z",
        last_login: "2022-06-02T06:57:16.000Z",
        last_ip: null,
        leverage: 100,
        agent_account: 0,
        balance: 500.0,
        credit: 300.0,
        prev_balance: 500.0,
        prevmonth_balance: 500.0,
        prev_equity: 865.53,
        prevmonth_equity: 876.56,
        currency: "USD",
        lock: false,
    },
    {
        id: 1395,
        server: "demo",
        timestamp: "2022-10-14T09:20:06.609Z",
        login: 300101,
        group: "demo\\EAERA\\A_USD",
        enable: true,
        read_only: false,
        name: "John Doe",
        country: "DE",
        city: "Hanoi",
        state: "",
        zipcode: "10000",
        address: "hanoi",
        lead_source: "",
        phone: "+841234567",
        email: "eaeratest+4@gmail.com",
        identifier: null,
        comment: "",
        status: "",
        register_time: "2022-06-02T06:57:40.000Z",
        last_login: "2022-06-02T06:57:40.000Z",
        last_ip: null,
        leverage: 100,
        agent_account: 0,
        balance: 0.0,
        credit: 0.0,
        prev_balance: 0.0,
        prevmonth_balance: 0.0,
        prev_equity: 0.0,
        prevmonth_equity: 0.0,
        currency: "USD",
        lock: false,
    },
];

mock.onGet("/MtAccount/data").reply(() => [200, MtAccount]);
